import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ImDataMappingItemServiceGENERATED} from '../_generated/im-data-mapping-item-generated.service';
import {Observable} from 'rxjs';
import * as CircularJSON from 'circular-json';
import {IImDataMappingItem} from "../_generated/im-data-mapping-item.interface";


@Injectable({
  providedIn: 'root'
})
export class ImDataMappingItemService extends ImDataMappingItemServiceGENERATED {

  public updateByMappingId(mappingId: number, attributesList: any[]): Observable<any> {
    return this.httpClient.put<any>(this.getApiServiceRootUrl() + `/updatebymapping/` + mappingId, CircularJSON.stringify(attributesList), {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }

  override insert(aImDataMappingItem: any): Observable<IImDataMappingItem> {
    return this.httpClient.post<IImDataMappingItem>(this.getApiServiceRootUrl()  , aImDataMappingItem.toDto(), {headers: this.httpOptions});
  }
// Update object
  override update(aImDataMappingItem: any): Observable<IImDataMappingItem> {
    return this.httpClient.put<IImDataMappingItem>(this.getApiServiceRootUrl() + `/`+ aImDataMappingItem.id, aImDataMappingItem.toDto(), {headers: this.httpOptions});
  }
}
