import {Injectable, InjectionToken, Injector} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CurrencyPipe, Location} from '@angular/common';
import {McEnvironment} from '../extra/mc-environment.model';
import {McEnvironmentService} from '../_core/mc-environment.service';
import {McUtilityService} from '../services/mc-utility.service';
import {McGodGENERATED} from '../_generated/mc-god-generated.service';
import {TranslateService} from '@ngx-translate/core';
import {McTrigger} from '../_core/mc-trigger';

@Injectable({
  providedIn: 'root'
})
export class McGod extends McGodGENERATED {
  static instance: McGod;
  static BROADCAST_TRIGGER_CART_CHANGED = 'CART_CHANGED';
  static BROADCAST_TRIGGER_LOGIN_CHANGED = 'LOGIN_CHANGED'; // User changed or Entity Changed

  env!: McEnvironment;
  private broadcastTriggers = new Map<string, McTrigger>();
  private readyFlg = false;

  static getLoggedEntityIdFromToken(): number {
    return McGod.getService(McUtilityService).getPropertyFromToken('entity_id');
  }

  static getLoggedUserIdFromToken(): number {
    return McGod.getService(McUtilityService).getPropertyFromToken('user_id');
  }

  static isEntityFactoringEnabledFlgFromToken(): boolean {
    return McGod.getService(McUtilityService).getPropertyFromToken('entity_factoring_enabled_flg');
  }

  static getLoggedUserPermissionsFromToken(): string[] {
    let result = McGod.getService(McUtilityService).getAuthoritiesFromStorage();

    if (!result) {
      result = [];
    }
    return result;
  }

  static userHasPermissionsAll(permissions: string[]): boolean {
    return permissions
      .every(permission => McGod.getLoggedUserPermissionsFromToken().indexOf(permission) > -1);
  }

  static userHasPermissions(permissions: string[] | '' | any): boolean {
    let result = true;
    if (permissions) {
      result = permissions
        .some((permission: any) => this.userHasPermission(permission));
    }
    return result;
  }

  static userHasPermission(permission: string): boolean {
    return McGod.getInstance().userHasPermission(permission);
  }

  static matchActiveRoute(aRouteName: string): boolean {
    return McGod.getService(McUtilityService).isActivRoute(aRouteName);
  }

  public static t(aStringOrCd: string, args: string[] | null = null) {
    return McGod.getInstance().t(aStringOrCd, args);
  }
  public static getService(serviceClass: typeof McUtilityService | any) {
    return McGod.getInstance().getServiceInternal(serviceClass);
  }

  public static getEnv(): McEnvironment {
    return McGod.getInstance().env;
  }

  public static isProduction(): any {
    return McGod.getInstance().env.production;
  }

  public static getHttpClient(): HttpClient {
    return McGod.getInstance().httpClient;
  }

  public static getHttpOptions(token?: string) {
    return { };
  }

  static formatNumberToMoney(aAmount: number): string {
    return <string>McGod.getInstance().formatNumberToMoney(aAmount);
  }


  static redirectBack(): void {
    McGod.getInstance().redirectBack();
  }
  static redirect(aRoute: string): void {
    McGod.getInstance().redirect(aRoute);
  }


  public static getInstance(): McGod {
    return McGod.instance;
  }

  constructor(private injector: Injector, public httpClient: HttpClient, private location: Location,  // @Inject('environment') environment,
              private mcEnvironmentService: McEnvironmentService, private translateService: TranslateService) {
    super();
    McGod.instance = this;
  }

  public load(onSuccessCallback: () => void, onErrorCallback: () => void) {
    this.mcEnvironmentService.getMcEnvironment().subscribe((mcEnvironmentObj) => {
        this.env = mcEnvironmentObj;
        this.readyFlg = true;
        onSuccessCallback();
      }, (error) => {
        console.log('Environment FAILED TO LOAD: ', this.env);
        onErrorCallback();
      }
    );
  }

  public appInit() {

    return new Promise<void>((resolve, reject) => {
      console.log('AppInitService.init() called');
      this.load(() => {
        resolve();
      }, () => {

      });
    });
  }

  public getServiceInternal(service:any) {
    const MY_SERVICE_TOKEN = new InjectionToken(`Manually constructed ${service}`, {
      providedIn: 'root',
      factory: () => {
        return new service(this.httpClient, this.env);
      }
      ,
    });
    return this.injector.get(MY_SERVICE_TOKEN);
  }

  public userHasPermission(permission: string): boolean {
    // @ts-ignore
    //const mcUser = JSON.parse(localStorage.getItem('mc-user'));
    const authorities = JSON.parse(localStorage.getItem('authorities'))

    return !!authorities.some((item: string) => item === permission);

   /* const result = McGod.getLoggedUserPermissionsFromToken().indexOf(permission) > -1;
    return result;*/
  }


  public isReady(): boolean {
    return this.readyFlg;
  }

  public redirectBack(): void {
    return this.getLocationService().back();
  }
  public redirect(aRoute: string): void {
    this.getLocationService().go(aRoute);
  }
  getLocationService(): Location {
    return this.location;
  }


  t(aStringOrCd: string, args: string[] | null = null) {
    const result = this.translateService.instant(aStringOrCd);
    return result;
  }

  formatNumberToMoney(aAmount: number) {
    const crp = new CurrencyPipe('de-DE'); // Hardcoded for now.
    return crp.transform(aAmount, 'EUR');
  }

  getBroadcastTrigger(aBroadcastCd: string) {
    if (!this.broadcastTriggers.has(aBroadcastCd)) {
      this.broadcastTriggers.set(aBroadcastCd, new McTrigger());
    }
    return this.broadcastTriggers.get(aBroadcastCd);
  }


  getBroadcastTriggerLoginChanged() {
    return this.getBroadcastTrigger(McGod.BROADCAST_TRIGGER_LOGIN_CHANGED);
  }

  fireBroadcastTriggerLoginChanged(): void {
    if (this.broadcastTriggers.has(McGod.BROADCAST_TRIGGER_LOGIN_CHANGED)) {
      this.getBroadcastTrigger(McGod.BROADCAST_TRIGGER_LOGIN_CHANGED)?.fire();
    }
  }

}
