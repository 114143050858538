import {Injectable} from '@angular/core';
import {McBillingStatementServiceGENERATED} from '../_generated/mc-billing-statement-generated.service';
import {McBillingStatementList} from '../models/mc-billing-statement-list.model';
import {SortCriteriaList} from '../_core/sort-criteria-list.model';
import {Observable} from "rxjs";
import {HttpHeaders} from "@angular/common/http";





@Injectable({
  providedIn: 'root'
})
export class McBillingStatementService extends McBillingStatementServiceGENERATED {

  public finalizeStatement(ids: number[]) {
    return this.httpClient.post(`${this.getApiServiceRootUrl()}/finalize/${ids}`, {});
  }
  public cancelStatement(ids: number[]) {
    // @ts-ignore
    return this.httpClient.put(`${this.getApiServiceRootUrl()}/cancel`, ids);
  }

  public createStatements(mcBillingStatements: McBillingStatementList, autoImportFlg: boolean) {
    const body = {
      statements: mcBillingStatements.toDto(),
      autoImportFlg
    };
    return this.httpClient.post(`${this.getApiServiceRootUrl()}/all`, body);
  }

  public getFilteredListOfEntitiesForCreateStatement(aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(
      `${this.getApiServiceRootUrl()}/getFilteredListOfEntitiesForCreateStatementScreen?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }

  createStatementJob(data: any): Observable<any> {
    return this.httpClient.post(this.getApiServiceRootUrl() + `/scheduleCreateStatements`, data, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }
}
