import {Inject, Injectable} from '@angular/core';
import {McPriceList2EntityServiceGENERATED} from '../_generated/mc-price-list-2-entity-generated.service';
import {Observable} from "rxjs";
import {IMcPriceList2Entity} from "../_generated/mc-price-list-2-entity.interface";



@Injectable({
  providedIn: 'root'
})
export class McPriceList2EntityService extends McPriceList2EntityServiceGENERATED {

  createNewMcPriceList2Entity(aMcPriceList2Entity: any): Observable<IMcPriceList2Entity> {
    return this.httpClient.post<IMcPriceList2Entity>(this.getApiServiceRootUrl()  , aMcPriceList2Entity);
  }
}
