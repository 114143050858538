import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {
  NgbCalendar,
  NgbDate,
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDateParserFormatter, NgbDatepickerI18n,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import {McDateUtils, McForm, McGod} from '@miticon-ui/mc-core';
import {Subject, Subscription} from 'rxjs';
import {MccFiDatePickerEventAfterValidation} from './mcc-fi-date-picker-event-after-validation';
import {takeUntil} from 'rxjs/operators';

import { LOCALE_ID } from '@angular/core';

import {registerLocaleData, TranslationWidth} from '@angular/common';
import localeEn from '@angular/common/locales/ro';
import {TranslateService} from '@ngx-translate/core';
registerLocaleData(localeEn, 'ro');

const I18N_VALUES = {
  'en': {
    weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  },
  'de': {
    weekdays: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dez'],
  }
  // other languages you would support
};

export enum I18n {
  english = 'en',
  german = 'de'
}

@Component({
  selector: 'mcc-fi-date-picker',
  templateUrl: './mcc-fi-date-picker.component.html',
  styleUrls: ['./mcc-fi-date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
    { provide: LOCALE_ID, useValue: 'en-EN'},
    {provide: NgbDatepickerI18n, useClass: MccFiDatePickerComponent}
  ]
})



export class MccFiDatePickerComponent extends NgbDatepickerI18n implements OnInit, OnDestroy, OnChanges {

  disabled = true;
  multipleMonthsNum = 1;
  clientSideId!: string;
  inputId!: string;
  model: any;
  hoveredDate!: NgbDate;
  fromDate!: any;
  toDate!: any;
  mcGod = McGod.getInstance();
  // datePickerForm: FormGroup;
  requiredFlg!: Validators;
  validatorList: any[] = [];
  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;
  value!: Date;
  formValidationTriggerSubscription!: Subscription;
  readonly delimiter = '.';
  ngbDatePickerShow = false;

  destroy$ = new Subject<boolean>();

  // moment = _moment;

  @Input() name!: string;
  @Input() markDisabledDays: any;
  @Input() label!: string;
  @Input() clearDateRange = new Subject<boolean>();
  @Input() datePickerViewType: 'POPUP' | 'BASIC' | 'RANGE_SELECTION' = 'POPUP';
  // @Input() frmGroup: FormGroup;
  @Input() displayDaysOutOfCurrentMonth: 'visible' | 'hidden' = 'hidden';
  @Input() allowManualEntryFlg = false;
  @Input() minDateInput!: number;
  @Input() maxDateInput!: number;
  @Input() valueInput!: number;
  @Input() extraCssClasses = '';
  @Input() validRequiredFlg = false;
  // @Input() customValidator;
  // @Input() validationSetupObject: McValidation;
  @Input() validationTrigger = false;
  @Input() mcForm!: McForm;
  @Input() removeControlFromMcFormOnDestroy = true;
  @Input() monthsDisplayed!: number;
  @Input() isBasicDatepickerInFilter!: boolean;
  @Input() newStyleDate = false;
  @Output() eventValueChanged = new EventEmitter();
  @Output() eventAfterValidation = new EventEmitter();
  @Output() maxDateSmallerThanMinDateValidation = new EventEmitter();
  @Output() eventFromDate = new EventEmitter();
  @Output() eventToDate = new EventEmitter();


  constructor(private calendar: NgbCalendar, private formatter: NgbDateParserFormatter, private translateService: TranslateService,) {
    super();
    }

  getDatePickerForm(): FormGroup {
    return this.mcForm.getControl(this.name) as FormGroup;
  }

  getWeekdayShortName(weekday: number): string {
    if (this.translateService.currentLang === 'en') {
      return I18N_VALUES[I18n.english].weekdays[weekday - 1];
    }
    return I18N_VALUES[I18n.german].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    if (this.translateService.currentLang === 'en') {
      return I18N_VALUES[I18n.english].months[month - 1];
    }
    return I18N_VALUES[I18n.german].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }


  setCurrentValue() {
    if (!this.getDatePickerForm()) {
      const datePickerForm = this.mcForm.formBuilder.group({
        value: [this.value, this.validatorList],
        label: [this.label],
        errorFlg: [false],
        errorMsg: ['']
      });

      if (this.mcForm) {
        this.mcForm.addControl(this.name, datePickerForm);
      }
    } else {
      this.getDatePickerForm().controls['value'].setValue(this.value);
      this.getDatePickerForm().controls['errorFlg'].setValue(false);
      this.getDatePickerForm().controls['errorMsg'].setValue('');
    }
  }

  ngOnInit() {
    this.dateFormat();
    this.validationSetup();
    this.clientSideId = 'mcc-fi-' + this.name;
    this.inputId = 'mcc-fi-input-' + this.name;
    if (this.valueInput) {
      this.value = new Date(this.valueInput);
    }

    if (this.mcForm) {
      this.formValidationTriggerSubscription = this.mcForm.getValidationTriggerObservable().subscribe(() => {
        this.validateDatePicker();
      });
    }

    this.clearDateRange.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (value) {
        this.resetDatePicker();
      }
    });

    this.setCurrentValue();

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes['maxDateInput']) {
        if (changes['maxDateInput'].currentValue !== undefined) {
          this.maxDate = McDateUtils.formatTimestampToNgbDateStruct(this.maxDateInput);
        }
      }
      if (changes['minDateInput']) {
        if (changes['minDateInput'].currentValue !== undefined) {
          this.minDate = McDateUtils.formatTimestampToNgbDateStruct(this.minDateInput);
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.formValidationTriggerSubscription) {
      this.formValidationTriggerSubscription.unsubscribe();
    }

    if (this.removeControlFromMcFormOnDestroy) {
      this.mcForm?.formGroup?.removeControl(this.name);
    }
    this.destroy$.unsubscribe();
  }


  dateFormat() {
    if (this.maxDateInput) {
      this.maxDate = McDateUtils.formatTimestampToNgbDateStruct(this.maxDateInput);
    }
    if (this.minDateInput) {
      this.minDate = McDateUtils.formatTimestampToNgbDateStruct(this.minDateInput);
    }
    if (this.valueInput) {
      this.value = McDateUtils.formatTimestampDateObject(this.valueInput);
    }
  }

  validationSetup() {
    this.validatorList = [];

    if (this.validRequiredFlg) {
      this.requiredFlg = Validators.required;
      this.validatorList.push(this.requiredFlg);
    }

  }


  validateDatePicker() {
    this.getDatePickerForm().get('errorFlg')?.setValue(false);
    this.getDatePickerForm().get('errorMsg')?.setValue('');
    this.validationSetup();
    this.getDatePickerForm().controls['value'].setValidators(this.validatorList);
    const dateValue = this.getDatePickerForm().get('value')?.value;
    this.getDatePickerForm().get('value')?.updateValueAndValidity();
    if (dateValue) {
      if (this.minDate && !this.maxDate && Number(this.minDate) > this.valueInput && McDateUtils.formatNgbDateStructToTimestamp(this.minDate) > McDateUtils.formatDateToTimestamp(McDateUtils.formatDateToString(dateValue))) {
        this.getDatePickerForm().get('errorFlg')?.setValue(true);
        this.getDatePickerForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.min-date-is')} ${McDateUtils.formatNgbDateStructToString(this.minDate)}`);
      } else if (this.maxDate && !this.minDate && McDateUtils.formatNgbDateStructToTimestamp(this.maxDate) < McDateUtils.formatDateToTimestamp(McDateUtils.formatDateToString(dateValue))) {
        this.getDatePickerForm().get('errorFlg')?.setValue(true);
        this.getDatePickerForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.max-date-is')} ${McDateUtils.formatNgbDateStructToString(this.maxDate)}`);
      } else if (this.maxDate && this.minDate && (McDateUtils.formatNgbDateStructToTimestamp(this.maxDate) < McDateUtils.formatDateToTimestamp(McDateUtils.formatDateToString(dateValue)) || McDateUtils.formatNgbDateStructToTimestamp(this.minDate) > McDateUtils.formatDateToTimestamp(McDateUtils.formatDateToString(dateValue)))) {
        this.getDatePickerForm().get('errorFlg')?.setValue(true);
        this.getDatePickerForm().get('errorMsg')?.setValue(`${this.mcGod.t('cc.common.date-must-be-between')} ${McDateUtils.formatNgbDateStructToString(this.minDate)} - ${McDateUtils.formatNgbDateStructToString(this.maxDate)}`);
      }
    } else if (this.getDatePickerForm().get('value')?.errors) {
      // @ts-ignore
      const validators = Object.keys(this.getDatePickerForm().get('value')?.errors);
      if (validators) {
        this.getDatePickerForm().get('errorFlg')?.setValue(true);
        validators.forEach(item => {
          if (item === 'required') {
            this.getDatePickerForm().get('errorMsg')?.setValue(this.mcGod.t('cc.common.this-field-is-required'));
          }
        });
      }
    }
    this.fireEventAfterValidation();
  }


  fireEventAfterValidation() {
    if (this.eventAfterValidation) {
      const eventObj = new MccFiDatePickerEventAfterValidation();
      eventObj.validFlg = !this.getDatePickerForm().get('value')?.errors;

      this.eventAfterValidation.emit(eventObj);
    }
  }

  clearErrors() {
    this.getDatePickerForm().get('errorFlg')?.setValue(false);
    this.getDatePickerForm().get('errorMsg')?.setValue('');
  }

  onDatePickerChanged(value: any) {
    if (value) {
      if(!this.newStyleDate) {
        value.setHours(3);
      } else {
        const threeHoursLater = new Date(value);
        threeHoursLater.setHours(threeHoursLater.getHours() + 3);
        value = threeHoursLater;
      }
    }
    this.clearErrors();
    if (this.eventValueChanged) {
      this.eventValueChanged.emit(value);
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.eventFromDate.emit(McDateUtils.formatNgbDateStructToDateAndAddOneDay(this.fromDate));
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      this.eventToDate.emit(McDateUtils.formatNgbDateStructToDateAndAddOneDay(this.toDate));
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.eventFromDate.emit(McDateUtils.formatNgbDateStructToDateAndAddOneDay(this.fromDate));
      this.eventToDate.emit(McDateUtils.formatNgbDateStructToDateAndAddOneDay(this.toDate));
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  resetDatePicker() {
    if (this.mcForm.getControl(this.name)?.get('value')) {
      this.mcForm.getControl(this.name)?.get('value')?.setValue(null);
    }
    this.eventFromDate.emit(null);
    this.eventToDate.emit(null);
    this.fromDate = null;
    this.toDate = null;
  }

  getSelectedDatesString(): any {
    if (this.fromDate && !this.toDate) {
      return `Selected dates: ${McDateUtils.formatNgbDateStructToString(this.fromDate)} - `;
    }
    if (this.fromDate && this.toDate) {
      return `Selected dates: ${McDateUtils.formatNgbDateStructToString(this.fromDate)} - ${McDateUtils.formatNgbDateStructToString(this.toDate)}`;
    }
    if (!this.fromDate && !this.toDate) {
      return '';
    }
  }

  private formatDateDaysMonthsIfLessThan10(dateNumber: any) {
    return dateNumber < 10  ? '0' + dateNumber : dateNumber;
  }

  getValueForRange() {
    if (this.fromDate) {
      const fromDateDay = this.formatDateDaysMonthsIfLessThan10(this.fromDate.day);
      const fromDateMonth = this.formatDateDaysMonthsIfLessThan10(this.fromDate.month);
      const toDateDay = this.toDate ? this.formatDateDaysMonthsIfLessThan10(this.toDate.day) : '';
      const toDateMonth = this.toDate ? this.formatDateDaysMonthsIfLessThan10(this.toDate.month) : '';
      const fromDateString = `${fromDateDay}.${fromDateMonth}.${this.fromDate.year}`;
      const toDateString = this.toDate ? `${toDateDay}.${toDateMonth}.${this.toDate.year}` : '';
      if (fromDateString && !toDateString) {
        return `${fromDateString} -`;
      }
      if (fromDateString && toDateString) {
        return `${fromDateString} - ${toDateString}`;
      }
    }
    return ``;

  }


  onKeyPress() {
    return false;
  }

  getWeekdayLabel(weekday: any, width?: any): string {
    return '';
  }
}
