import { Injectable } from "@angular/core";
import { EbFactoringServiceGENERATED } from "../_generated/eb-factoring-generated.service";
import { SortCriteriaList } from "../_core/sort-criteria-list.model";

import moment from 'moment';
import { IEbFactoring } from "../_generated/eb-factoring.interface";
import { EbFactoring } from "../models/eb-factoring.model";

@Injectable({
  providedIn: "root",
})
export class EbFactoringService extends EbFactoringServiceGENERATED {
  activeChildFactoring!: IEbFactoring;
  childFactorings!: IEbFactoring[];
  parentFactorings: IEbFactoring[] = [];

  private entityId!: number;

  private static isFactoringForDate(
    date: any,
    factoring: IEbFactoring
  ): boolean {
    const formattedDate = moment(date).startOf("day");
    return (
      moment(factoring.contractStartDate)
        .startOf("day")
        .isSameOrBefore(formattedDate) &&
      (factoring.contractEndDate === null ||
        factoring.contractEndDate === undefined ||
        moment(factoring.contractEndDate)
          .startOf("day")
          .isSameOrAfter(formattedDate))
    );
  }

  // this should be called on every entity change
  public changeEntity(entityId: number) {
    this.entityId = entityId;
    this.getByParentEntityId(
      entityId,
      0,
      1000,
      new SortCriteriaList()
    ).subscribe((response) => {
      this.parentFactorings = response.content;
    });

    this.getByChildEntityId(
      entityId,
      0,
      1000,
      new SortCriteriaList()
    ).subscribe((response) => {
      this.childFactorings = response.content;
      this.activeChildFactoring = response.content.find(
        (factoring: EbFactoring) => (factoring.statusCd = "ACTIVE")
      );
    });
  }

  public getAllFactorings(): IEbFactoring[] {
    return this.parentFactorings.concat(this.childFactorings);
  }

  public getChildFactoringForDate(date: any): IEbFactoring | undefined {
    const factoring = this.childFactorings.find((fac) =>
      EbFactoringService.isFactoringForDate(date, fac)
    );

    if (!factoring) {
      console.log(
        `There is no factoring for childEntityId=${this.entityId} and date='${date}'`
      );
    }

    return factoring;
  }

  public getParentFactoringForDateAndChildEntityId(
    date: any,
    childEntityId: number
  ): IEbFactoring | undefined {
    const factoring = this.parentFactorings
      .filter((fac) => fac.idChildMcEntity === childEntityId)
      .find((fac) => EbFactoringService.isFactoringForDate(date, fac));

    if (!factoring) {
      console.log(
        `There is no factoring for parentEntityId=${this.entityId} and date='${date}'`
      );
    }

    return factoring;
  }
}
