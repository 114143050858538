<h3 class="page-title">{{ 'cc.incoming-records' | translate }}</h3>

<lib-mc-loader [showLoader]="ebPaymentReportList.apiLoadingFlg"></lib-mc-loader>

<div *ngIf="ebPaymentReportList.apiErrorFlg">Error: {{ ebPaymentReportList.apiErrorMessage }}</div>

<div class="actions-container" *ngIf="ebPaymentReportList.getCount() > 0">
  <span>{{ 'cc.incoming-payments.incoming-records-list' | translate }}</span>
  <div class="actions-wrapper">
    <button class="system-action-btn" (click)="openExportAllDialog()"><i class="fas fa-file-export"></i>{{ 'cc.out-factoring.transactions.export-all' | translate }}</button>
  </div>
</div>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="ebPaymentReportList.items"
  [config]="tableConfig"
  [filterConfig]="filterConfig"
  [pageNumber]="pageNumber"
  [pageSize]="pageSize"
  [filtersFilterForm]="filtersForm"
  [searchForm]="searchForm"
  [isIncomingRecords]="true"
  [totalItemsCount]="ebPaymentReportList.totalItemsCount"
  [itemsPerPage]="ebPaymentReportList.pageItemsPerPageCount"
  (filterValuesChanged)="onFilterValuesChanged($event)"
  (filterChanged)="onFilterChanged($event)"
  (actionMkMatMenuItems)="onMkMatMenuItemsAction($event)"
  [mkMatMenuActionItems]="mkMatMenuActionItems"
  [searchTooltip]="searchTooltip"
  (selectedItems)="onItemsSelected($event)"
>
</mk-mat-table>

