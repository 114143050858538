import {Component, OnInit} from '@angular/core';
import { McValueLabelList, CsrMappingService, SortCriteriaList, CsrMapping, SortCriteriaItem, McUserService, McUser} from "@miticon-ui/mc-core";
import {HttpParams} from "@angular/common/http";
import {MkFilterConfig, MkFilterItemType, MkFilterOutput, MkFilterValue, MkTableConfig} from "@miticon-ui/mc-components";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'mc-consumer-consumer-import-mappings-list-page',
  templateUrl: './mc-consumer-consumer-import-mappings-list-page.component.html',
})
export class McConsumerConsumerImportMappingsListPageComponent implements OnInit {
  totalItemsCount!: number;
  pageSize = 5;
  pageNumber= 0;
  isLoading = false;
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  items: CsrMapping[] = [];
  searchTooltip = this.tS.instant('cc.mapping-imports.search-tooltip');

  constructor(private tS: TranslateService,
              private mcUserService: McUserService,
              private csrMappingService: CsrMappingService) {}

  ngOnInit() {
    this.initTableConfig();
    this.initFilterConfig();
    this.actLoad(this.pageNumber, this.pageSize, this.getDefaultParams());
  }

  getDefaultParams() {
    return new HttpParams().set('page', this.pageNumber).set('size', this.pageSize);
  }

  initTableConfig() {
    this.tableConfig.addColumnStandard(this.tS.instant('cc.invoices.view.creation-date'), 'getCreationDate()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.billing.entity-id'), 'idMcEntity');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.users.view.user') + ' ID', 'idMcSystemUser', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.web-file.id'), 'idWebFile', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.consumers.consumers-overview.mapping-name'), 'mappingName', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.mapping.shared-flg'), 'sharedFlg', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.mapping.sharing-date'), 'getSharingDate()', 250);
  }

  initFilterConfig() {
    const usersValueLabelList = new McValueLabelList();
    this.mcUserService.getAllUsersWithCreatedMapping().subscribe((users: any) => {
      if(users) {
        users.content.forEach((user: McUser) => {
          usersValueLabelList.add(user.id, user.firstname + ' ' + user.lastname);
        })
      }
      this.filterConfig.addItem(MkFilterItemType.SELECT, this.tS.instant('cc.users.view.user'), usersValueLabelList.items.map((item) => new MkFilterValue(item.label, item.value)));
    })
  }

  onFilterChanged(filterOutput: MkFilterOutput) {
    const params = new HttpParams()
      .set('page', filterOutput.pageEvent.pageIndex)
      .set('size', filterOutput.pageEvent.pageSize)
      .set('term', filterOutput.search ? filterOutput.search : '')
      .set('userId', filterOutput.selections[this.tS.instant('cc.users.view.user')] ? filterOutput.selections[this.tS.instant('cc.users.view.user')] : '');
    this.actLoad(filterOutput.pageEvent.pageIndex, filterOutput.pageEvent.pageSize, params);
  }

  actLoad(pageNumber: number, pageSize: number, params: HttpParams): void {
    this.isLoading = true;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    const sort = new SortCriteriaItem('id', 'DESC');
    const sortCriteriaList = new SortCriteriaList();
    sortCriteriaList.addSort(sort.propertyName, sort.direction);
    this.csrMappingService.get(params, pageNumber, pageSize, sortCriteriaList).subscribe((res)=> {
      if (res) {
        this.isLoading = false;
        this.totalItemsCount = res.totalElements;
        this.items = res.content.map((object: any) => object = CsrMapping.createFromJson(object));
      }
    }, () => {
      this.isLoading = false;
    });
  }

}
