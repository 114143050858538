import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { McTableComponent } from "./mc-table.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModalModule, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {McStatusModule} from "../mc-status/mc-status.module";
import {McCoreModule} from "@miticon-ui/mc-core";
import {RouterModule} from "@angular/router";
import {McJobActionModule} from "../mc-job-action/mc-job-action.module";
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TranslateModule} from '@ngx-translate/core';
import {McPriceAdjustmentModule} from "../mc-price-adjustment/mc-price-adjustment.module";
import {McPriceAdjustmentComponent} from "../mc-price-adjustment/mc-price-adjustment.component";
import { McProductTotalPriceComponent } from './components/mc-invoice-total-price/mc-product-total-price.component';
import {McApplyOptionsModule} from "../mc-apply-options/mc-apply-options.module";
import { McAddProductToInvoiceComponent } from './components/mc-add-product-to-invoice/mc-add-product-to-invoice.component';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  declarations: [McTableComponent, McProductTotalPriceComponent, McAddProductToInvoiceComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    McCoreModule,
    McStatusModule,
    RouterModule,
    McJobActionModule,
    DragDropModule,
    TranslateModule,
    McPriceAdjustmentModule,
    McApplyOptionsModule,
    MatPaginatorModule
  ],
  exports: [McTableComponent, McProductTotalPriceComponent]
})
export class McTableModule { }
