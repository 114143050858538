<h3 class="page-title">{{"cc.common.mapping-imports" | translate}}</h3>

<div class="actions-container">
  <span>{{"cc.common.mapping-imports-list" | translate}}</span>
</div>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="items"
  [config]="tableConfig"
  [filterConfig]="filterConfig"
  [totalItemsCount]="totalItemsCount"
  [itemsPerPage]="pageSize"
  (filterChanged)="onFilterChanged($event)"
  [searchTooltip]="searchTooltip"
  [showCheckbox]="false"
>
</mk-mat-table>

<router-outlet></router-outlet>
