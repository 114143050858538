/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {WebFile} from '../models/web-file.model';
import {WebFileService} from '../services/web-file.service';
import {IWebFile} from '../_generated/web-file.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class WebFileGENERATED extends BaseObject {


    public static readonly STATUS_CD_ACTIVE: string = 'ACTIVE';
    public static readonly STATUS_CD_INACTIVE: string = 'INACTIVE';
    public static readonly STATUS_CD_PENDING: string = 'PENDING';
  public static readonly STATUS_CD_LIST__ALL = [
    WebFileGENERATED.STATUS_CD_ACTIVE,
    WebFileGENERATED.STATUS_CD_INACTIVE,
    WebFileGENERATED.STATUS_CD_PENDING
];

    public static readonly TYPE_CD_MC_BILLING_STATEMENT_PAYOUT_REPORT_PDF: string = 'MC_BILLING_STATEMENT_PAYOUT_REPORT_PDF';
    public static readonly TYPE_CD_MC_BILLING_STATEMENT_CONSUMERS_REPORT_CSV: string = 'MC_BILLING_STATEMENT_CONSUMERS_REPORT_CSV';
    public static readonly TYPE_CD_MC_BILLING_PAYOUT_EXOPORT: string = 'MC_BILLING_PAYOUT_EXOPORT';
    public static readonly TYPE_CD_CSR_CONSUMER_ATTACHMENT: string = 'CSR_PROCCESS_CONSUMER_ATTACHMENT';
    public static readonly TYPE_CD_CSR_CONSUMER_IMPORT: string = 'CSR_CONSUMER_IMPORT';
    public static readonly TYPE_CD_EB_BANK_SEPA_IMPORT: string = 'EB_BANK_SEPA_IMPORT';
    public static readonly TYPE_CD_EB_BANK_SEPA_EXPORT: string = 'EB_BANK_SEPA_EXPORT';
    public static readonly TYPE_CD_EB_BANK_SEPA_STATEMENT_REPORT: string = 'EB_BANK_SEPA_STATEMENT_REPORT';
    public static readonly TYPE_CD_EB_BANK_EBICS_DOWNLOAD: string = 'EB_BANK_EBICS_DOWNLOAD';
    public static readonly TYPE_CD_EB_BANK_PAYMENT_REPORT_EXPORT: string = 'EB_BANK_PAYMENT_REPORT_EXPORT';
    public static readonly TYPE_CD_IN_INKASSO_EXPORT: string = 'IN_INKASSO_EXPORT';
    public static readonly TYPE_CD_PP_INVOICE: string = 'PP_INVOICE';
    public static readonly TYPE_CD_DIRECT_DEBIT_APPROVAL: string = 'Direct_debit_approval';
    public static readonly TYPE_CD_PM_TRANSACTION_CSV_EXPORT: string = 'PM_TRANSACTION_CSV_EXPORT';
    public static readonly TYPE_CD_CSR_CONTRACT: string = 'CSR_CONTRACT';
    public static readonly TYPE_CD_MC_ENTITY_HEADER_IMAGE: string = 'MC_ENTITY_HEADER_IMAGE';
    public static readonly TYPE_CD_MC_ENTITY_FOOTER_IMAGE: string = 'MC_ENTITY_FOOTER_IMAGE';
    public static readonly TYPE_CD_UNKNOWN: string = 'UNKNOWN';
    public static readonly TYPE_CD_BILLING_NOTIFICATION: string = 'BILLING_NOTIFICATION';
    public static readonly TYPE_CD_BK_BOOKKEEPING_FINANCE_REPORT: string = 'BK_BOOKKEEPING_FINANCE_REPORT';
    public static readonly TYPE_CD_TERMS_AND_CONDITIONS: string = 'TERMS_AND_CONDITIONS';
    public static readonly TYPE_CD_PAYER_REFUNDS: string = 'PAYER_REFUNDS';
  public static readonly TYPE_CD_LIST__ALL = [
    WebFileGENERATED.TYPE_CD_MC_BILLING_STATEMENT_PAYOUT_REPORT_PDF,
    WebFileGENERATED.TYPE_CD_MC_BILLING_STATEMENT_CONSUMERS_REPORT_CSV,
    WebFileGENERATED.TYPE_CD_MC_BILLING_PAYOUT_EXOPORT,
    WebFileGENERATED.TYPE_CD_CSR_CONSUMER_ATTACHMENT,
    WebFileGENERATED.TYPE_CD_CSR_CONSUMER_IMPORT,
    WebFileGENERATED.TYPE_CD_EB_BANK_SEPA_IMPORT,
    WebFileGENERATED.TYPE_CD_EB_BANK_SEPA_EXPORT,
    WebFileGENERATED.TYPE_CD_EB_BANK_SEPA_STATEMENT_REPORT,
    WebFileGENERATED.TYPE_CD_EB_BANK_EBICS_DOWNLOAD,
    WebFileGENERATED.TYPE_CD_EB_BANK_PAYMENT_REPORT_EXPORT,
    WebFileGENERATED.TYPE_CD_IN_INKASSO_EXPORT,
    WebFileGENERATED.TYPE_CD_PP_INVOICE,
    WebFileGENERATED.TYPE_CD_DIRECT_DEBIT_APPROVAL,
    WebFileGENERATED.TYPE_CD_PM_TRANSACTION_CSV_EXPORT,
    WebFileGENERATED.TYPE_CD_CSR_CONTRACT,
    WebFileGENERATED.TYPE_CD_MC_ENTITY_HEADER_IMAGE,
    WebFileGENERATED.TYPE_CD_MC_ENTITY_FOOTER_IMAGE,
    WebFileGENERATED.TYPE_CD_UNKNOWN,
    WebFileGENERATED.TYPE_CD_BILLING_NOTIFICATION,
    WebFileGENERATED.TYPE_CD_BK_BOOKKEEPING_FINANCE_REPORT,
    WebFileGENERATED.TYPE_CD_TERMS_AND_CONDITIONS,
    WebFileGENERATED.TYPE_CD_PAYER_REFUNDS
];

  public apiService: WebFileService;
  public _rawJson: IWebFile;
    id: number = 0;
  delimiter = '';
  ext = '';
  filename = '';
  idMcSystemUser: number;
  originalFilename = '';
  path = '';
  statusCd: string;
  typeCd: string;
  uploadDatetime: number;


  public properties: string[] = ['id', 'delimiter', 'ext', 'filename', 'idMcSystemUser', 'originalFilename', 'path', 'statusCd', 'typeCd', 'uploadDatetime'];
  public propertiesRegular: string[] = ['id', 'delimiter', 'ext', 'filename', 'idMcSystemUser', 'originalFilename', 'path', 'statusCd', 'typeCd', 'uploadDatetime'];
  public propertiesSpecial: string[] = [];



  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(WebFileGENERATED.STATUS_CD_ACTIVE, McGod.t('Active'));
    list.add(WebFileGENERATED.STATUS_CD_INACTIVE, McGod.t('Inactive'));
    list.add(WebFileGENERATED.STATUS_CD_PENDING, McGod.t('Pending'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = WebFileGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(WebFileGENERATED.TYPE_CD_MC_BILLING_STATEMENT_PAYOUT_REPORT_PDF, McGod.t('Payout report from billing'));
    list.add(WebFileGENERATED.TYPE_CD_MC_BILLING_STATEMENT_CONSUMERS_REPORT_CSV, McGod.t('Consumer report from billing'));
    list.add(WebFileGENERATED.TYPE_CD_MC_BILLING_PAYOUT_EXOPORT, McGod.t('Payout file'));
    list.add(WebFileGENERATED.TYPE_CD_CSR_CONSUMER_ATTACHMENT, McGod.t('Consumer attachments'));
    list.add(WebFileGENERATED.TYPE_CD_CSR_CONSUMER_IMPORT, McGod.t('Consumer import'));
    list.add(WebFileGENERATED.TYPE_CD_EB_BANK_SEPA_IMPORT, McGod.t('Sepa import'));
    list.add(WebFileGENERATED.TYPE_CD_EB_BANK_SEPA_EXPORT, McGod.t('Sepa export'));
    list.add(WebFileGENERATED.TYPE_CD_EB_BANK_SEPA_STATEMENT_REPORT, McGod.t('Sepa statement report'));
    list.add(WebFileGENERATED.TYPE_CD_EB_BANK_EBICS_DOWNLOAD, McGod.t('Ebics report'));
    list.add(WebFileGENERATED.TYPE_CD_EB_BANK_PAYMENT_REPORT_EXPORT, McGod.t('Payment report export'));
    list.add(WebFileGENERATED.TYPE_CD_IN_INKASSO_EXPORT, McGod.t('Inkasso export'));
    list.add(WebFileGENERATED.TYPE_CD_PP_INVOICE, McGod.t('Invoice'));
    list.add(WebFileGENERATED.TYPE_CD_DIRECT_DEBIT_APPROVAL, McGod.t('Direct_debit_approval'));
    list.add(WebFileGENERATED.TYPE_CD_PM_TRANSACTION_CSV_EXPORT, McGod.t('Transaction CSV export'));
    list.add(WebFileGENERATED.TYPE_CD_CSR_CONTRACT, McGod.t('Membership contract'));
    list.add(WebFileGENERATED.TYPE_CD_MC_ENTITY_HEADER_IMAGE, McGod.t('Entity header image'));
    list.add(WebFileGENERATED.TYPE_CD_MC_ENTITY_FOOTER_IMAGE, McGod.t('Entity footer image'));
    list.add(WebFileGENERATED.TYPE_CD_UNKNOWN, McGod.t('Unknown'));
    list.add(WebFileGENERATED.TYPE_CD_BILLING_NOTIFICATION, McGod.t('Billing notification'));
    list.add(WebFileGENERATED.TYPE_CD_BK_BOOKKEEPING_FINANCE_REPORT, McGod.t('Bookkeeping finance report'));
    list.add(WebFileGENERATED.TYPE_CD_TERMS_AND_CONDITIONS, McGod.t('Terms and conditions'));
    list.add(WebFileGENERATED.TYPE_CD_PAYER_REFUNDS, McGod.t('Payer Refunds'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = WebFileGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): WebFile {
    console.error('MTCN-ERROR: Not Implemented: WebFile::createNewInstance(). Add this method to final class and return new WebFile();');
    throw new Error('Cannot WebFile::createNewInstance(). Add this method to final class and return new WebFile();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IWebFile): WebFile {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(WebFileService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE WebFile AABB()');
    
  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getStatusCdLabel(): string {
    return WebFileGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdACTIVE(): boolean {
    const result = (this.statusCd === WebFileGENERATED.STATUS_CD_ACTIVE);

    return result;
  }

 public  isStatusCdINACTIVE(): boolean {
    const result = (this.statusCd === WebFileGENERATED.STATUS_CD_INACTIVE);

    return result;
  }

 public  isStatusCdPENDING(): boolean {
    const result = (this.statusCd === WebFileGENERATED.STATUS_CD_PENDING);

    return result;
  }


  public getTypeCdLabel(): string {
    return WebFileGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdMC_BILLING_STATEMENT_PAYOUT_REPORT_PDF(): boolean {
    const result = (this.typeCd === WebFileGENERATED.TYPE_CD_MC_BILLING_STATEMENT_PAYOUT_REPORT_PDF);

    return result;
  }

 public  isTypeCdMC_BILLING_STATEMENT_CONSUMERS_REPORT_CSV(): boolean {
    const result = (this.typeCd === WebFileGENERATED.TYPE_CD_MC_BILLING_STATEMENT_CONSUMERS_REPORT_CSV);

    return result;
  }

 public  isTypeCdMC_BILLING_PAYOUT_EXOPORT(): boolean {
    const result = (this.typeCd === WebFileGENERATED.TYPE_CD_MC_BILLING_PAYOUT_EXOPORT);

    return result;
  }

 public  isTypeCdCSR_CONSUMER_ATTACHMENT(): boolean {
    const result = (this.typeCd === WebFileGENERATED.TYPE_CD_CSR_CONSUMER_ATTACHMENT);

    return result;
  }

 public  isTypeCdCSR_CONSUMER_IMPORT(): boolean {
    const result = (this.typeCd === WebFileGENERATED.TYPE_CD_CSR_CONSUMER_IMPORT);

    return result;
  }

 public  isTypeCdEB_BANK_SEPA_IMPORT(): boolean {
    const result = (this.typeCd === WebFileGENERATED.TYPE_CD_EB_BANK_SEPA_IMPORT);

    return result;
  }

 public  isTypeCdEB_BANK_SEPA_EXPORT(): boolean {
    const result = (this.typeCd === WebFileGENERATED.TYPE_CD_EB_BANK_SEPA_EXPORT);

    return result;
  }

 public  isTypeCdEB_BANK_SEPA_STATEMENT_REPORT(): boolean {
    const result = (this.typeCd === WebFileGENERATED.TYPE_CD_EB_BANK_SEPA_STATEMENT_REPORT);

    return result;
  }

 public  isTypeCdEB_BANK_EBICS_DOWNLOAD(): boolean {
    const result = (this.typeCd === WebFileGENERATED.TYPE_CD_EB_BANK_EBICS_DOWNLOAD);

    return result;
  }

 public  isTypeCdEB_BANK_PAYMENT_REPORT_EXPORT(): boolean {
    const result = (this.typeCd === WebFileGENERATED.TYPE_CD_EB_BANK_PAYMENT_REPORT_EXPORT);

    return result;
  }

 public  isTypeCdIN_INKASSO_EXPORT(): boolean {
    const result = (this.typeCd === WebFileGENERATED.TYPE_CD_IN_INKASSO_EXPORT);

    return result;
  }

 public  isTypeCdPP_INVOICE(): boolean {
    const result = (this.typeCd === WebFileGENERATED.TYPE_CD_PP_INVOICE);

    return result;
  }

 public  isTypeCdDIRECT_DEBIT_APPROVAL(): boolean {
    const result = (this.typeCd === WebFileGENERATED.TYPE_CD_DIRECT_DEBIT_APPROVAL);

    return result;
  }

 public  isTypeCdPM_TRANSACTION_CSV_EXPORT(): boolean {
    const result = (this.typeCd === WebFileGENERATED.TYPE_CD_PM_TRANSACTION_CSV_EXPORT);

    return result;
  }

 public  isTypeCdCSR_CONTRACT(): boolean {
    const result = (this.typeCd === WebFileGENERATED.TYPE_CD_CSR_CONTRACT);

    return result;
  }

 public  isTypeCdMC_ENTITY_HEADER_IMAGE(): boolean {
    const result = (this.typeCd === WebFileGENERATED.TYPE_CD_MC_ENTITY_HEADER_IMAGE);

    return result;
  }

 public  isTypeCdMC_ENTITY_FOOTER_IMAGE(): boolean {
    const result = (this.typeCd === WebFileGENERATED.TYPE_CD_MC_ENTITY_FOOTER_IMAGE);

    return result;
  }

 public  isTypeCdUNKNOWN(): boolean {
    const result = (this.typeCd === WebFileGENERATED.TYPE_CD_UNKNOWN);

    return result;
  }

 public  isTypeCdBILLING_NOTIFICATION(): boolean {
    const result = (this.typeCd === WebFileGENERATED.TYPE_CD_BILLING_NOTIFICATION);

    return result;
  }

 public  isTypeCdBK_BOOKKEEPING_FINANCE_REPORT(): boolean {
    const result = (this.typeCd === WebFileGENERATED.TYPE_CD_BK_BOOKKEEPING_FINANCE_REPORT);

    return result;
  }

 public  isTypeCdTERMS_AND_CONDITIONS(): boolean {
    const result = (this.typeCd === WebFileGENERATED.TYPE_CD_TERMS_AND_CONDITIONS);

    return result;
  }

 public  isTypeCdPAYER_REFUNDS(): boolean {
    const result = (this.typeCd === WebFileGENERATED.TYPE_CD_PAYER_REFUNDS);

    return result;
  }


  
}
