<div class="content-wrapper">
  <lib-mc-loader [showLoader]="mcBillingPayoutFileFeCtd.apiLoadingFlg || loadingFlg"></lib-mc-loader>

  <div class="row mx-0">
    <div class="mc-go-back-to-all" (click)="goToPayoutFileListPage()" *ngIf="mcGod.userHasMcBillingOutPayoutFilesViewPermission()">
      <
      {{'cc.payout-file.back-to-payout-files' | translate}}
    </div>
  </div>

  <!--THIS CONTAINER IS MANDATORY-->
  <div class="content-header d-flex">
    <h3>{{mcBillingPayoutFileFeCtd.fileName}}</h3>

    <div ngbDropdown class="d-inline-block ml-auto">
      <button class="btn-outline-new" id="dropdownBasic1" ngbDropdownToggle>Actions</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem [ngClass]="{'mc-disable' : mcBillingPayoutFileFeCtd.isStatusCdFAILED()}" (click)="onActionsDropdownClick(globalActions.Download)" *ngIf="mcGod.userHasMcBillingOutPayoutFilesDownloadPermission()">
          <i class="fas fa-download"></i> {{'cc.common.download-file' | translate}}
        </button>
        <button ngbDropdownItem (click)="onActionsDropdownClick(globalActions.Delete)" *ngIf="mcGod.userHasMcBillingOutPayoutFilesDeletePermission()">
          <i class="far fa-trash-alt"></i> {{'cc.common.delete-file' | translate}}
        </button>
      </div>
    </div>

  </div>


  <div class="content-body">
    <div *ngIf="!loadingFlg" class="row">
      <div class="col-md-12">
        <div class="content-section">

          <div class="row info-container2 p-3">
            <div class="col-md-6">
              <mcc-row-title-description title="Created date and time:" [description]="createdDAteTimeString"></mcc-row-title-description>
              <mcc-row-title-description title="{{'cc.consumers.imports.created-by' | translate}}:" [description]="mcBillingPayoutFileFeCtd.createdBy"></mcc-row-title-description>
              <mcc-row-title-description title="{{'cc.statement.number-of-statements' | translate}}:" [description]="mcBillingPayoutFileFeCtd.numberOfStatements"></mcc-row-title-description>
            </div>
            <div class="col-md-6">
              <mcc-row-title-description title="{{'cc.common.payout-date' | translate}}:" [description]="payoutDateString"></mcc-row-title-description>
              <mcc-row-title-description title="{{'cc.common.total-file-amount' | translate}}:" [description]="mcBillingPayoutFileFeCtd.fldTotalFileAmount()"></mcc-row-title-description>
              <mcc-row-title-description title="{{'cc.common.view.status' | translate}}:" [description]="mcBillingPayoutFileFeCtd.getStatusCdLabel()"></mcc-row-title-description>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <mcc-fi-table-3 [objectList]="mcBillingPayoutFileItemFeCtdList"
                        [table3Config]="mcBillingPayoutFileItemTable3Config"></mcc-fi-table-3>
      </div>
    </div>

  </div>

</div>
<router-outlet></router-outlet>
