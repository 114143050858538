
import {Table3Config} from '../extra/table-3-config';

// import {McBillingPayoutFileFeCtdGENERATED} from '../_generated/mc-billing-payout-file-fe-ctd-generated.model';

export class McBillingPayoutFileFeCtdTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colCreationTypeCd', 'Creation type cd', 'creationTypeCd', 'creationTypeCd');
    this.addColumn('colFileName', 'File Name', 'fileName', 'fileName');
    this.addColumn('colIdMcBankAccount', 'Bank account ID', 'idMcBankAccount', 'idMcBankAccount');
    this.addColumn('colNumberOfStatements', 'Number Of Statements', 'numberOfStatements', 'numberOfStatements');
    this.addColumn('colPayoutDate', 'Payout Date', 'payoutDate', 'payoutDate');
    this.addColumn('colPayoutType', 'Payout type', 'payoutType', 'payoutType');
    this.addColumn('colStatusCd', 'Status cd', 'statusCd', 'statusCd');
    this.addColumn('colStatusDetails', 'Status Details', 'statusDetails', 'statusDetails');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colTotalFileAmount', 'Total File Amount', 'totalFileAmount', 'totalFileAmount');
    this.addColumn('colTypeCd', 'type cd', 'typeCd', 'typeCd');
    this.addColumn('colXlsWebfileId', 'XLS Webfile ID', 'xlsWebfileId', 'xlsWebfileId');

*/
  }
}
