import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, McPermissionGuard } from '@miticon-ui/mc-core';
import { McManagementComponent } from './mc-management.component';
import { McInvoiceCreationComponent } from './mc-invoice/mc-invoice-creation/mc-invoice-creation.component';
import { McInvoiceEditComponent } from './mc-invoice/mc-invoice-edit/mc-invoice-edit.component';
import { McConsumersArchiveComponent } from './mc-consumers/mc-consumers-archive/mc-consumers-archive.component';
import { McConsumersImportComponent } from './mc-consumers/mc-consumers-import/mc-consumers-import.component';
import { McEditUserWrapperComponent } from './mc-users/components/mc-edit-user-wrapper/mc-edit-user-wrapper.component';
import { McRoleEditWrapperComponent } from './mc-role/mc-role-edit-wrapper/mc-role-edit-wrapper.component';
import { McInvoiceOverviewComponent } from "./mc-invoice/mc-invoice-overview/mc-invoice-overview.component";

const routes: Routes = [
  {
    path: 'user/edit/:id',
    component: McEditUserWrapperComponent
  },
  {
    path: 'role/edit/:id',
    component: McRoleEditWrapperComponent
  },
  {
    path: ':module',
    component: McManagementComponent,
    canActivate: [AuthGuard, McPermissionGuard],
  },
  {
    path: ':module/edit/:id/invoice',
    component: McInvoiceCreationComponent,
    canActivate: [AuthGuard, McPermissionGuard],
  },
  {
    path: ':module/edit/:id/invoice/:area/:orderId',
    component: McInvoiceEditComponent,
    canActivate: [AuthGuard, McPermissionGuard],
  },
  {
    path: ':module/edit/:id/invoice/:area/:orderId',
    component: McInvoiceEditComponent,
    canActivate: [AuthGuard, McPermissionGuard],
  },
  {
    path: ':module/archive',
    component: McConsumersArchiveComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':module/import',
    component: McConsumersImportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'invoices/:receiverType/:id',
    component: McInvoiceOverviewComponent
  },
  {
    path: 'invoices/consumers/create/:id',
    component: McInvoiceCreationComponent
  },
  {
    path: 'invoices/consumers/edit/:orderId',
    component: McInvoiceEditComponent
  },
  {
    path: 'invoices/overview',
    component: McInvoiceOverviewComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class McManagementRoutingModule {}
