import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {McEntity2ServiceGENERATED} from '../_generated/mc-entity2-generated.service';
import {Observable} from 'rxjs';
import {McGod} from '../extra/mc-god.service';
import {McEntity2} from '../models/mc-entity2.model';


@Injectable({
  providedIn: 'root'
})
export class McEntity2Service extends McEntity2ServiceGENERATED {

  constructor() {
    super();
  }

  getCurrencyByEntityId(): Observable<any> {
    return this.httpClient.get(this.getApiServiceRootUrl() + `/currency/${McGod.getLoggedEntityIdFromToken()}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        }
      )
    });
  }

  getNumberOfEntitiesByCountryIds(): Observable<any> {
    return this.httpClient.get(this.apiUrl + `/mcentity/v3/mcentity` + `/country-entities`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }

  getAncestors(id: number): Observable<McEntity2[]> {
    return this.httpClient.get<McEntity2[]>(this.getApiServiceRootUrl() + `/ancestors/${id}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }

  public getValidEntitiesForStatements(): Observable<any> {
    // @ts-ignore
    return this.httpClient.get(this.getApiServiceRootUrl() + `/all/statement-valid`);
  }

  generateApiKey(): Observable<{ api_key: string }> {
    return this.httpClient.post<{ api_key: string}>(`${this.apiUrl}/mcentity/v3/mcentity/api-key`, {});
  }

  getApiKey(): Observable<{ api_key: string }> {
    return this.httpClient.get<{ api_key: string }>(`${this.apiUrl}/mcentity/v3/mcentity/api-key`);
  }
}
