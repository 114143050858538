import {FormGroup} from '@angular/forms';

export class FiTextareaConfig {

  label!: string;
  labelPosition!: string;
  value!: string;
  name!: string;
  placeholder!: string;
  required!: string;
  readOnly!: boolean;
  requiredFlg!: boolean;
  readOnlyFlg!: boolean;
  frmGroup!: FormGroup;
  errorFlg = false;
  errorMsg!: string;
  extraCssClass!: string;

  // keyup: any;

  constructor(configObject: any) {
    this.update(configObject);
  }

  update(objectUpdate: { [x: string]: any; }) {
    // @ts-ignore
    Object.keys(objectUpdate).forEach(key => this[key] = objectUpdate[key]);
  }

}
