import {Component, OnInit} from '@angular/core';
import {
  McForm,
  McGod,
  McBoolean,
  McValueLabelList,
  McBillingPayoutFileFeCtdFilter,
  McUser,
  WebFile,
  GlobalActionEnums, McBillingPayoutFileFeCtd,
  McBillingPayoutFileFeCtdService, EbSepaExport, McBankAccountService, BaseDirective, SortCriteriaList,
} from '@miticon-ui/mc-core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import * as fileSaver from 'file-saver';
import {take, takeUntil} from 'rxjs/operators';
import {ToastrService} from "ngx-toastr";
import { MkFilterConfig, MkFilterItemType, MkFilterOutput, MkFilterValue, MkMatMenuItem, MkMatTableMenuAction, MkTableConfig } from '@miticon-ui/mc-components';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mc-out-billing-payout-file-list-page',
  templateUrl: './mc-out-billing-payout-file-list-page.component.html',
  styleUrls: ['./mc-out-billing-payout-file-list-page.component.scss']
})
export class McOutBillingPayoutFileListPageComponent extends BaseDirective implements OnInit {

  pageNumber = 0;
  pageSize = 5;
  isLoading = false;
  totalItemsCount!: number;
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  items: McBillingPayoutFileFeCtd[] = [];
  mcBillingPayoutFileFilter = new McBillingPayoutFileFeCtdFilter();
  payoutFile = new McBillingPayoutFileFeCtd();
  mcForm = new McForm();
  mcGod = McGod.getInstance();
  showAddEditPayoutFileMcb = new McBoolean();
  typeCdVll: McValueLabelList = EbSepaExport.getTypeCdVll();
  showDeleteMcb = new McBoolean();
  dialogDescription!: string;
  errorMsg = '';
  successMsg = '';
  clearDateRangeSubject = new Subject<boolean>();
  clearedAllMultipleFilters = new Subject<boolean>();
  createdBy: any[] = [];
  user = new McUser();
  webFile = new WebFile();
  globalActions = GlobalActionEnums;
  interval: any;
  typeCds: any[] = [];
  bankVll: McValueLabelList = new McValueLabelList();
  showUploadFileMcb = new McBoolean();
  payoutFileId = 0;

  tableActionItems: MkMatMenuItem[] = [
    {
      title: this.tS.instant('cc.common.view-file'),
      actionCd: McBillingPayoutFileFeCtd.ACTION_VIEW,
      matIcon: 'remove_red_eye',
      permission: McGod.PERM_MC_BILLING_OUT_PAYOUT_FILES_VIEW
    },
    {
      title: this.tS.instant('cc.common.download-file'),
      actionCd: McBillingPayoutFileFeCtd.ACTION_DOWNLOAD_FILE,
      matIcon: 'download',
      permission: McGod.PERM_MC_BILLING_OUT_PAYOUT_FILES_DOWNLOAD
    },
    {
      title: this.tS.instant('cc.common.upload-file'),
      actionCd: McBillingPayoutFileFeCtd.ACTION_UPLOAD_FILE,
      matIcon: 'upload',
      permission: McGod.PERM_MC_BILLING_UPLOAD_PAYOUT_FILE
    },
    {
      title: this.tS.instant('cc.ebics.send-via-ebics'),
      actionCd: McBillingPayoutFileFeCtd.ACTION_SEND_VIA_EBICS,
      matIcon: 'send',
      permission: McGod.PERM_MC_BILLING_OUT_PAYOUT_FILES_VIEW
    },
    // {
    //   title: this.tS.instant('cc.common.delete-file'),
    //   actionCd: McBillingPayoutFileFeCtd.ACTION_DELETE,
    //   matIcon: 'delete',
    //   permission: McGod.PERM_MC_BILLING_OUT_PAYOUT_FILES_DELETE
    // }
  ]

  constructor(private router: Router,
              private mcBillingPayoutFileFeCtdService: McBillingPayoutFileFeCtdService,
              private mcBankAccountService: McBankAccountService,
              private toastr: ToastrService,
              private tS: TranslateService) {
    super();
    if (history.state.payoutFileDeletedMessage) {
      this.successMsg = history.state.payoutFileDeletedMessage;
    }
  }

  ngOnInit() {
    this.mcBankAccountService.bankAccounts.map(item => {
      this.bankVll.add(item.id, item.bankName);
    });

    this.actLoad(this.pageNumber, this.pageSize, this.mcBillingPayoutFileFilter);
    this.initTableConfig();
    this.initFilterConfig();
  }

  public actLoad(pageNumber: number, pageSize: number, mcBillingPayoutFilter: McBillingPayoutFileFeCtdFilter){
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.isLoading = true;
    const sortCriteriaList= new SortCriteriaList();
    this.mcBillingPayoutFileFeCtdService.getByFilter(mcBillingPayoutFilter, this.pageNumber, this.pageSize, sortCriteriaList).subscribe((res)=>{
        if(res){
          this.isLoading = false;
          this.totalItemsCount = res.totalElements;
          this.items = res.content.map((object: any) => object = McBillingPayoutFileFeCtd.createFromJson(object));
        }
      });
  }

  initTableConfig(){
    this.tableConfig.addColumnSvgIconAction(this.tS.instant('cc.common.filename'), 'getFilenameAndLink()', 'View');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.common.payout-type'), 'getPayoutTypeLabel()', 250, '');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.common.bank-name'), 'bankName', 250, '');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.dashboard.number-of-transactions'), 'numberOfStatements', 250, '');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.common.total-file-amount'), 'fldTotalFileAmount()', 250, '');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.common.payout-date'), 'fldPayoutDate()', 250, '');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.common.created-date-and-time'), 'fldCreatedDateTime()', 250, '');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.consumers.imports.created-by'), 'createdBy', 250, '');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.common.view.status'), 'getStatusCdLabel()', 250, '');
  }

  initFilterConfig(){
    const createdByVll = new McValueLabelList();
    this.mcBillingPayoutFileFeCtdService.getByFilter(new McBillingPayoutFileFeCtdFilter(), 0, 100, new SortCriteriaList()).subscribe((res)=> {
      if(res){
        res.content.forEach((item: McBillingPayoutFileFeCtd) => {
          if(!createdByVll.hasValue(item.sysCreatedUserId)){
            createdByVll.add(item.sysCreatedUserId, item.createdBy);
          }
        });
        this.filterConfig.addItem(MkFilterItemType.MULTISELECT, this.tS.instant('cc.consumers.imports.created-by'), createdByVll.items.map((item) => new MkFilterValue(item.label, item.value)));
      }
    });
    this.filterConfig.addItem(MkFilterItemType.MULTISELECT, this.tS.instant('cc.common.payout-type'), this.typeCdVll.items.map((item) => new MkFilterValue(item.label, item.value)));
    this.filterConfig.addItem(MkFilterItemType.MULTISELECT, this.tS.instant('cc.common.bank-name'), this.bankVll.items.map((item) => new MkFilterValue(item.label, item.value)));

    this.filterConfig.addItem(MkFilterItemType.RANGE_DATEPICKER, this.tS.instant('cc.common.payout-date'));
    this.filterConfig.addItem(MkFilterItemType.RANGE_DATEPICKER, this.tS.instant('cc.common.created-date'));
  }

  onFilterChanged(filterOutput: MkFilterOutput): void {
    this.mcBillingPayoutFileFilter.searchTerm = filterOutput.search ? filterOutput.search : '';
    this.mcBillingPayoutFileFilter.payoutDateFrom = filterOutput.selections[this.tS.instant('cc.common.payout-date')]?.startDate ?  new Date(new Date(filterOutput.selections[this.tS.instant('cc.common.payout-date')]?.startDate).setHours(0,0,0,0)).toISOString() : '';
    this.mcBillingPayoutFileFilter.payoutDateTo= filterOutput.selections[this.tS.instant('cc.common.payout-date')]?.endDate ? new Date(new Date(filterOutput.selections[this.tS.instant('cc.common.payout-date')]?.endDate).setHours(23,59,59,999)).toISOString() : '';
    this.mcBillingPayoutFileFilter.createdDateFrom = filterOutput.selections[this.tS.instant('cc.common.created-date')]?.startDate ?  new Date(new Date(filterOutput.selections[this.tS.instant('cc.common.created-date')]?.startDate).setHours(0,0,0,0)).toISOString() : '';
    this.mcBillingPayoutFileFilter.createdDateTo= filterOutput.selections[this.tS.instant('cc.common.created-date')]?.endDate ? new Date(new Date(filterOutput.selections[this.tS.instant('cc.common.created-date')]?.endDate).setHours(23,59,59,999)).toISOString() : '';

    this.mcBillingPayoutFileFilter.bankAccountIds = filterOutput.selections[this.tS.instant('cc.common.bank-name')] ? MkFilterOutput.convertFilterSelectionToArray(filterOutput.selections[this.tS.instant('cc.common.bank-name')]) : [];
    this.mcBillingPayoutFileFilter.createdBy = filterOutput.selections[this.tS.instant('cc.consumers.imports.created-by')] ? MkFilterOutput.convertFilterSelectionToArray(filterOutput.selections[this.tS.instant('cc.consumers.imports.created-by')]) : [];
    this.mcBillingPayoutFileFilter.typeCds = filterOutput.selections[this.tS.instant('cc.common.payout-type')] ? MkFilterOutput.convertFilterSelectionToArray(filterOutput.selections[this.tS.instant('cc.common.payout-type')]) : [];
    this.actLoad(filterOutput.pageEvent.pageIndex, filterOutput.pageEvent.pageSize, this.mcBillingPayoutFileFilter);
  }

  onCreatePayoutFile() {
    this.showAddEditPayoutFileMcb.setTrue();
  }

  onAddEditPayoutFileCancelEmit() {
    this.showAddEditPayoutFileMcb.setFalse();
  }

  onTableAction($event: MkMatTableMenuAction){
    this.payoutFile = $event.item;
    switch($event.action.actionCd){
      case McBillingPayoutFileFeCtd.ACTION_VIEW:
        this.router.navigateByUrl(`entity/${McGod.getLoggedEntityIdFromToken()}/out-billing/payout/${$event.item.id}`);
        break;
      case McBillingPayoutFileFeCtd.ACTION_SEND_VIA_EBICS:
        this.mcBillingPayoutFileFeCtdService.sendViaEbics($event.item.id).pipe(take(1)).subscribe(res => {
          this.actLoad(this.pageNumber, this.pageSize, this.mcBillingPayoutFileFilter);
        });
        break;
      case McBillingPayoutFileFeCtd.ACTION_DOWNLOAD_FILE:
        if (this.payoutFile.statusCd !== McBillingPayoutFileFeCtd.STATUS_CD_FAILED) {
          const webFileType =
            this.payoutFile.payoutType === McBillingPayoutFileFeCtd.PAYOUT_TYPE_REFUND_PAYMENT
              ? WebFile.TYPE_CD_PAYER_REFUNDS
              : WebFile.TYPE_CD_MC_BILLING_PAYOUT_EXOPORT;
          this.webFile.getFileByIdAndType(this.payoutFile.xlsWebfileId, webFileType, () => {
            this.webFile.downloadFile().subscribe(response => {
              fileSaver.saveAs(response, this.webFile.filename);
            });
          }, () => {
            this.toastr.error(this.webFile.apiErrorMessage);
          });
        }
        break;
      case McBillingPayoutFileFeCtd.ACTION_DELETE:
        this.dialogDescription = this.tS.instant('cc.common.are-you-sure-you-want-to-delete')+` <span class="font-weight-bold">${this.payoutFile.fileName} ?</span> <br> `+ this.tS.instant('cc.common.this-action-cannot-be-undone');
        this.showDeleteMcb.setTrue();
        break;
      case McBillingPayoutFileFeCtd.ACTION_UPLOAD_FILE:
        this.showUploadFileMcb.setTrue();
        this.payoutFileId = $event.item.id;
        break;
    }
  }

  onColumnButtonAction(event: any){
    switch (event.actionCd){
      case 'View' :
        const fileId = event.element.id;
        this.router.navigateByUrl(`entity/${McGod.getLoggedEntityIdFromToken()}/out-billing/payout/${fileId}`);
    }
  }

  onDeletePayoutFileActionButtonClicked(value: string) {
    if (value === this.globalActions.Delete) {
      this.payoutFile.delete(() => {
        this.toastr.success(`${this.payoutFile.fileName} ${this.mcGod.t('cc.common.deleted-successfully')}.`);
        this.showDeleteMcb.setFalse();
        this.actLoad(this.pageNumber, this.pageSize, this.mcBillingPayoutFileFilter);

      }, () => {
        this.toastr.error(this.payoutFile.apiErrorMessage);
      });
    } else {
      this.showDeleteMcb.setFalse();
    }
  }

  onCreatePayoutFileSaveEmit() {
    this.showAddEditPayoutFileMcb.setFalse();
    this.toastr.success(this.tS.instant('cc.payout.payout-files-are-scheduled-for-creation'));
    this.actLoad(this.pageNumber, this.pageSize, this.mcBillingPayoutFileFilter);
  }

  onUploadFile($event: any) {
    this.mcBillingPayoutFileFeCtdService.uploadFile(this.payoutFileId, $event.file)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.showUploadFileMcb.setFalse();
        this.actLoad(this.pageNumber, this.pageSize, this.mcBillingPayoutFileFilter);
        this.toastr.success(this.tS.instant('mem.import.uploaded-file'));
      }, (err) => {
        this.toastr.error(err.error.message);
      });
  }
}
