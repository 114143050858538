import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {ICON_CLOSE} from "../../../../assets/media/svg_icons/icon-close";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
  McBankAccountService,
  McUtilityService,
  SortCriteriaList,
  EbSepaExportService,
  McEntityService,
  McBillingStatementService,
  McGod,
  McBillingStatement,
  McProcessService, EbPaymentReportService
} from '@miticon-ui/mc-core';
import {TranslateService} from "@ngx-translate/core";
import {MatOption} from "@angular/material/core";
import {MatSelect} from "@angular/material/select";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'mc-job-create',
  templateUrl: './mc-job-create.component.html',
  styleUrls: ['./mc-job-create.component.scss']
})
export class McJobCreateComponent implements OnInit {
  @ViewChild('multiselect') multiselect: MatSelect;
  form!: FormGroup;
  iconClose = ICON_CLOSE;
  bankAccounts = [];
  monthlyDays: {label: string, value: number}[] = [];
  statementMonthlyDays: {label: string, value: number}[] = [];
  secondStatement: boolean = false;
  filterObject = {
    fetchWithRoles: true,
  };
  pagination = {};
  jobType: string;
  entities!: any;
  allSelected = false;

  jobTypes = [
    {
      label: this.tS.instant("cc.jobs.sepa-export-automation"),
      value: "SEPA export automation"
    },
    {
      label: this.tS.instant("cc.jobs.create-statements-automation"),
      value: "Create statements automation"
    },
    {
      label: this.tS.instant("cc.jobs.send-reminders-automation"),
      value: "Reminder Automation Job"
    },
    {
      label: this.tS.instant("cc.refund-payments"),
      value: "Refund Payment"
    }
  ];

  scheduleTypes = [{
    label: this.tS.instant("cc.consumer.weekly"),
    value: "WEEKLY"
  },{
    label: this.tS.instant("cc.consumer.monthly"),
    value: "MONTHLY"
  },{
    label: this.tS.instant("cc.consumer.daily"),
    value: "DAILY"
  }];

  weeklyDays = [{
    label: this.tS.instant("cc.common.every-monday"),
    value: 1
  },{
    label: this.tS.instant("cc.common.every-tuesday"),
    value: 2
  },{
    label: this.tS.instant("cc.common.every-wednesday"),
    value: 3
  },{
    label: this.tS.instant("cc.common.every-thursday"),
    value: 4
  },{
    label: this.tS.instant("cc.common.every-friday"),
    value: 5
  }];

  exportFileTypes = [{
    label: this.tS.instant("cc.ebics.ebics"),
    value: "EBICS"
  },{
    label: this.tS.instant("cc.banking-module.star-money"),
    value: "STAR_MONEY"
  }];

  transactionStatuses = [{
    label: this.tS.instant("cc.eb-factoring.accepted"),
    value: "ACCEPTED"
  },{
    label: this.tS.instant("cc.factoring.transactions.returned"),
    value: "RETURNED"
  }];

  statementDate = [{
    label: this.tS.instant( "cc.invoices.view.creation-date"),
    value: "Creation date"
  },{
    label: this.tS.instant("cc.jobs.creation-date-days"),
    value: "Creation date + X days"
  }];

  hours = [
    { label: "00:00", value: 0 },
    { label: "01:00", value: 1 },
    { label: "02:00", value: 2 },
    { label: "03:00", value: 3 },
    { label: "04:00", value: 4 },
    { label: "05:00", value: 5 },
    { label: "06:00", value: 6 },
    { label: "07:00", value: 7 },
    { label: "08:00", value: 8 },
    { label: "09:00", value: 9 },
    { label: "10:00", value: 10 },
    { label: "11:00", value: 11 },
    { label: "12:00", value: 12 },
    { label: "13:00", value: 13 },
    { label: "14:00", value: 14 },
    { label: "15:00", value: 15 },
    { label: "16:00", value: 16 },
    { label: "17:00", value: 17 },
    { label: "18:00", value: 18 },
    { label: "19:00", value: 19 },
    { label: "20:00", value: 20 },
    { label: "21:00", value: 21 },
    { label: "22:00", value: 22 },
    { label: "23:00", value: 23 }
  ];

  remindersHours = [
    { label: "08:00", value: 8 },
    { label: "09:00", value: 9 },
    { label: "10:00", value: 10 },
    { label: "11:00", value: 11 },
    { label: "12:00", value: 12 },
    { label: "13:00", value: 13 },
    { label: "14:00", value: 14 },
    { label: "15:00", value: 15 },
    { label: "16:00", value: 16 },
    { label: "17:00", value: 17 },
    { label: "18:00", value: 18 }
  ];

  constructor(public dialogRef: MatDialogRef<McJobCreateComponent>,
              private mcBankAccountService: McBankAccountService,
              private mcBillingStatementService: McBillingStatementService,
              private ebSepaExportService: EbSepaExportService,
              private utilityService: McUtilityService,
              private mcEntityService: McEntityService,
              private tS: TranslateService,
              private fb: FormBuilder,
              private mcProcessService: McProcessService,
              private ebPaymentReportService: EbPaymentReportService,
              private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.createForm();
    this.setMonthlyDays();
    this.setBankAccounts();
  }

  createForm() {
    this.form = this.fb.group({
      type: ['', Validators.required],
      name: [''],
      description: [''],
      jobType: [''],
      day: [''],
      hour: [''],
      secondStatement: [''],
      idMcBankAccount: [''],
      typeCd: [''],
      statusCds: [''],
      childEntityIds: [''],
      statementDate: [''],
      statementDateDays: [''],
      autoImportFlg: [''],
      finalizeFlg: [''],
      daysOld:['']
    });
  }

  setMonthlyDays() {
    this.statementMonthlyDays.push({
      value: -1,
      label: this.tS.instant("cc.jobs.every-first-day")
    })
    Array.from({ length: 31 }, (_, index) => ({
      value: index + 1,
      label: `${index + 1}.`
    })).forEach(day => {
      this.monthlyDays.push(day);
      this.statementMonthlyDays.push(day);
    });
  }

  setBankAccounts() {
    const entityId = this.utilityService.getPropertyFromToken("entity_id");
    this.mcBankAccountService.getByEntityId(entityId, 0, 100, new SortCriteriaList())
      .subscribe((data) => {
        this.bankAccounts = data.content;
      });
  }

  setEntities() {
    let entities = null;
    if(this.jobType === "Create statements automation") {
      entities = this.mcBillingStatementService.getFilteredListOfEntitiesForCreateStatement(0, 10000, new SortCriteriaList());
    }
    if(this.jobType === "Reminder Automation Job") {
      entities = this.mcProcessService.getValidEntitiesObjectsForRemindersAutomation();
    }
    if(this.jobType === "SEPA export automation") {
      entities = this.mcEntityService.getEntitiesFromDB();
    }
    if(entities) {
      entities.subscribe((data) => {
        this.entities = (this.jobType === "Create statements automation") ? data.content : data;
        const entityId = this.utilityService.getPropertyFromToken("entity_id");
        this.entities = this.entities.filter((entity: any) => entity.id !== entityId)
          .sort((a: any, b: any) => a.name.localeCompare(b.name));
      });
    }
  }

  toggleSelectAllEntities() {
    if (this.allSelected) {
      this.form.controls['childEntityIds'].setValue(this.entities.map((entity: any) => entity.id));
    } else {
      this.form.controls['childEntityIds'].setValue([]);
    }
  }

  optionClick() {
    let newStatus = true;
    this.multiselect.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  createJob() {
    if(this.jobType === "SEPA export automation") {
      this.createSepaExportAutomation();
    }
    if(this.jobType === "Create statements automation") {
      this.createStatementAutomation();
    }
    if(this.jobType === "Reminder Automation Job") {
      this.createReminderAutomationJob();
    }
    if(this.jobType === "Refund Payment") {
      this.createRefundPayment();
    }
  }

  createSepaExportAutomation() {
    const data = {
      ebSepaExport: {
        idMcEntity: this.utilityService.getPropertyFromToken("entity_id"),
        idSystemUser: this.utilityService.getPropertyFromToken("user_id"),
        typeCd: this.form.controls['typeCd'].value,
        idMcBankAccount: this.form.controls['idMcBankAccount'].value
      },
      pmTransactionFilter: {
        statusCds: [
          this.form.controls['statusCds'].value
        ],
        childEntityIds: this.form.controls['childEntityIds'].value,
        outTransactionFlg: true
      },
      recurringJobDto: {
        jobType: this.form.controls['jobType'].value,
        day: this.form.controls['day'].value,
        hour: this.form.controls['hour'].value,
        name: this.form.controls['name'].value,
        description: this.form.controls['description'].value,
        timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      requestCollectionDatePlusDays: this.form.controls['statementDateDays']?.value ? Number(this.form.controls['statementDateDays'].value) : 0,
      useSeparateSepaExportFilesPerEntity: true
    }
    this.ebSepaExportService.createSepaExportJob(data).subscribe((data) => {
      this.dialogRef.close(true);
    })
  }

  createStatementAutomation() {
    const statements: any[] = [];
    this.form.controls['childEntityIds'].value.forEach((childEntity: number) => {
      statements.push({
        parentEntityId: McGod.getLoggedEntityIdFromToken(),
        childEntityId: childEntity,
        statusCd: McBillingStatement.STATUS_CD_NEW,
        flgHidden: false
      });
    });

    const data = {
      statements: statements,
      recurringJobDto: {
        jobType: this.form.controls['jobType'].value,
        name: this.form.controls['name'].value,
        description: this.form.controls['description'].value,
        lastWorkingDayOfMonth: this.form.controls['day'].value === -1,
        day: this.form.controls['day'].value,
        hour: this.form.controls['hour'].value,
        numberOfWorkingDaysForSecondStatement: Number(this.form.controls['secondStatement'].value),
        timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      autoImportFlg: this.form.controls['autoImportFlg'].value,
      autoFinalizeFlg: this.form.controls['finalizeFlg'].value,
      statementDatePlusDays: this.form.controls['statementDateDays']?.value ? Number(this.form.controls['statementDateDays'].value) : 0
    }
    this.mcBillingStatementService.createStatementJob(data).subscribe((data) => {
      this.dialogRef.close(true);
    })
  }

  createReminderAutomationJob() {
    const data = {
      entityIdsFilter: this.form.controls['childEntityIds'].value,
      recurringJobDto: {
        jobType: this.form.controls['jobType'].value,
        day: this.form.controls['day'].value,
        hour: this.form.controls['hour'].value,
        name: this.form.controls['name'].value,
        description: this.form.controls['description'].value,
        timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    };
    this.mcProcessService.scheduleReminderJob(data).subscribe((data) => {
      this.dialogRef.close(true);
    })
  }

  createRefundPayment(){
    const payoutTypeCd = 'EBICS'; //for now only EBICS is implemented
    const data = {
      daysOld: this.form.controls['daysOld'].value,
      payoutTypeCd: payoutTypeCd,
      recurringJobDto: {
        jobType: this.form.controls['jobType'].value,
        day: this.form.controls['day'].value,
        hour: this.form.controls['hour'].value,
        name: this.form.controls['name'].value,
        description: this.form.controls['description'].value,
      }
    }
    this.ebPaymentReportService.scheduleRefundPayment(data).subscribe((data) => {
      if(data){
        this.dialogRef.close(true);
      }
    }, (error) => {
      this.toastr.error(error.error.message);
    });
  }

  isSepaExport(): boolean {
    return this.form.controls['type']?.value === "SEPA export automation";
  }

  isStatementsAutomation(): boolean {
    return this.form.controls['type']?.value === "Create statements automation";
  }

  isSendRemindersAutomation(): boolean {
    return this.form.controls['type']?.value === "Reminder Automation Job";
  }

  isRefundPayment(): boolean {
    return this.form.controls['type']?.value === "Refund Payment";
  }

  isNumberOfDays(): boolean {
    return this.form.controls['statementDate'].value &&
      this.form.controls['statementDate'].value === "Creation date + X days";
  }

  changeJobType(type: string) {
    this.jobType = type;
    this.form.reset();
    this.form.patchValue({ type: this.jobType });
    if(this.jobType === "Create statements automation") {
      this.form.controls['autoImportFlg'].setValue(true);
      this.form.controls['finalizeFlg'].setValue(true);
    }
    this.setEntities();
  }

  changeMonthDay(day: number) {
    this.secondStatement = this.isStatementsAutomation();
  }

  checkValidation(): boolean {
    const commonControls = ['name', 'jobType', 'hour'];
    const sepaExportControls = ['idMcBankAccount', 'typeCd', 'statusCds', 'childEntityIds'];
    const createStatementsControls = ['autoImportFlg', 'finalizeFlg'];
    const dayValue = this.form.get('day')?.value;

    let controlsToCheck = [...commonControls];

    const jobTypeControlsMap: Record<string, string[]> = {
      'Reminder Automation Job': ['childEntityIds'],
      'SEPA export automation': sepaExportControls,
      'Create statements automation': createStatementsControls,
      'Refund Payment': ['daysOld']
    };

    const additionalControls = jobTypeControlsMap[this.jobType] || [];
    controlsToCheck.push(...additionalControls);

    if (['SEPA export automation', 'Create statements automation'].includes(this.jobType)) {
      this.setStatementDateControls(controlsToCheck);
    }

    if (dayValue && dayValue !== 'DAILY') {
      controlsToCheck.push('day');
    }
    return controlsToCheck.every(controlName => !!this.form.get(controlName)?.value?.toString());
  }

  setStatementDateControls(controlsToCheck: string[]): void {
    const statementDateValue = this.form.get('statementDate')?.value;
    controlsToCheck.push('statementDate');
    if (statementDateValue === "Creation date + X days") {
      controlsToCheck.push('statementDateDays');
    }
  }

  resetDayAndHour() {
    this.form.controls['day'].setValue(null);
    this.form.controls['hour'].setValue(null);
  }

  areAllFieldsFilled() {
    return this.jobType ? this.checkValidation() : false;
  }

  onlyNumbersCheck(event: KeyboardEvent) {
    const onlyNumbersRegex = /[0-9\b]/
    const backspace = 'Backspace'
    if (!event.key.match(onlyNumbersRegex) && event.key != backspace) {
      event.preventDefault();
    }
  }
}
