/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
export abstract class McGodGENERATED {

      public static PERM_API_CONSUMER_EDIT = 'API_CONSUMER_EDIT';

      public static PERM_API_EXPERIMENTAL = 'API_EXPERIMENTAL';

      public static PERM_API_TRANSACTION_EDIT = 'API_TRANSACTION_EDIT';

      public static PERM_API_CONSUMER_CREATE = 'API_CONSUMER_CREATE';

      public static PERM_API_CONSUMER_VIEW = 'API_CONSUMER_VIEW';

      public static PERM_API_TRANSACTION_CREATE = 'API_TRANSACTION_CREATE';

      public static PERM_API_TRANSACTION_VIEW = 'API_TRANSACTION_VIEW';

      public static PERM_MC_PROCESS_RUN_ADD = 'MC_PROCESS_RUN_ADD';

      public static PERM_MC_PROCESS_FLOW_EDIT = 'MC_PROCESS_FLOW_EDIT';

      public static PERM_MC_PROCESS_FLOW_LEVEL_EDIT = 'MC_PROCESS_FLOW_LEVEL_EDIT';

      public static PERM_MC_PROCESS_RUN_MANAGE = 'MC_PROCESS_RUN_MANAGE';

      public static PERM_MC_PROCESS_RUN_VIEW = 'MC_PROCESS_RUN_VIEW';

      public static PERM_MC_PROCESS_RUN_EDIT = 'MC_PROCESS_RUN_EDIT';

      public static PERM_MC_PROCESS_PDF_TEMPLATE_EDIT = 'MC_PROCESS_PDF_TEMPLATE_EDIT';

      public static PERM_MC_PROCESS_ITEM_VIEW = 'MC_PROCESS_ITEM_VIEW';

      public static PERM_MC_PROCESS_FLOW_LEVEL_VIEW = 'MC_PROCESS_FLOW_LEVEL_VIEW';

      public static PERM_MC_PROCESS_VIEW = 'MC_PROCESS_VIEW';

      public static PERM_MC_PROCESS_ITEM_CHANGE_NEXT_ACTION_DATE = 'MC_PROCESS_ITEM_CHANGE_NEXT_ACTION_DATE';

      public static PERM_MC_PROCESS_BBM_TEST = 'MC_PROCESS_BBM_TEST';

      public static PERM_MC_PROCESS_PDF_TEMPLATE_VIEW = 'MC_PROCESS_PDF_TEMPLATE_VIEW';

      public static PERM_MC_PROCESS_FLOW_ADD = 'MC_PROCESS_FLOW_ADD';

      public static PERM_MC_PROCESS_EDIT = 'MC_PROCESS_EDIT';

      public static PERM_MC_PROCESS_FLOW_VIEW = 'MC_PROCESS_FLOW_VIEW';

      public static PERM_MC_PROCESS_FLOW_LEVEL_ADD = 'MC_PROCESS_FLOW_LEVEL_ADD';

      public static PERM_MC_PROCESS_ADD = 'MC_PROCESS_ADD';

      public static PERM_MC_PROCESS_PDF_TEMPLATE_ADD = 'MC_PROCESS_PDF_TEMPLATE_ADD';

      public static PERM_DASHBOARD_ENTITY_PARENT_VIEW = 'DASHBOARD_ENTITY_PARENT_VIEW';

      public static PERM_DASHBOARD_ENTITY_CHILD_VIEW = 'DASHBOARD_ENTITY_CHILD_VIEW';

      public static PERM_MC_BANK_ACCOUNT_DELETE = 'MC_BANK_ACCOUNT_DELETE';

      public static PERM_MC_ENTITY_ADD_EDIT_TRANSACTION_DESCRIPTION = 'MC_ENTITY_ADD_EDIT_TRANSACTION_DESCRIPTION';

      public static PERM_MC_ENTITY_UPDATE = 'MC_ENTITY_UPDATE';

      public static PERM_MC_ENTITY_VIEW = 'MC_ENTITY_VIEW';

      public static PERM_MC_ENTITY_SETTINGS_EDIT = 'MC_ENTITY_SETTINGS_EDIT';

      public static PERM_MC_ENTITY_DASHBOARD_VIEW = 'MC_ENTITY_DASHBOARD_VIEW';

      public static PERM_MC_ENTITY_API_KEY_EDIT = 'MC_ENTITY_API_KEY_EDIT';

      public static PERM_MC_ENTITY_API_KEY_VIEW = 'MC_ENTITY_API_KEY_VIEW';

      public static PERM_MC_BANK_ACCOUNT_EDIT = 'MC_BANK_ACCOUNT_EDIT';

      public static PERM_MC_ENTITY_PROFILE_VIEW = 'MC_ENTITY_PROFILE_VIEW';

      public static PERM_MC_PASSWORD_POLICY_UPDATE = 'MC_PASSWORD_POLICY_UPDATE';

      public static PERM_MC_ENTITY_SETTINGS_VIEW = 'MC_ENTITY_SETTINGS_VIEW';

      public static PERM_MC_TEMP_INVOICES_VIEW = 'MC_TEMP_INVOICES_VIEW';

      public static PERM_MC_ENTITY_EDIT_MAX_TRANSACTION_PAUSE_DAYS = 'MC_ENTITY_EDIT_MAX_TRANSACTION_PAUSE_DAYS';

      public static PERM_MC_ENTITY_CAN_CREATE = 'MC_ENTITY_CAN_CREATE';

      public static PERM_MC_TEMP_JOB_VIEW = 'MC_TEMP_JOB_VIEW';

      public static PERM_MC_ENTITY_CAN_READ = 'MC_ENTITY_CAN_READ';

      public static PERM_MC_ENTITY_CAN_UPDATE = 'MC_ENTITY_CAN_UPDATE';

      public static PERM_MC_BANK_ACCOUNT_CREATE = 'MC_BANK_ACCOUNT_CREATE';

      public static PERM_MC_CONSUMER_ACTION_HISTORY_VIEW = 'MC_CONSUMER_ACTION_HISTORY_VIEW';

      public static PERM_MC_CONSUMER_INVOICES_VIEW = 'MC_CONSUMER_INVOICES_VIEW';

      public static PERM_MC_CONSUMER_TRANSACTION_ADD = 'MC_CONSUMER_TRANSACTION_ADD';

      public static PERM_MC_CONSUMER_ADD = 'MC_CONSUMER_ADD';

      public static PERM_MC_CONSUMER_IMPORT_MAPPING_VIEW = 'MC_CONSUMER_IMPORT_MAPPING_VIEW';

      public static PERM_MC_CONSUMER_IMPORT_MAPPING_EDIT = 'MC_CONSUMER_IMPORT_MAPPING_EDIT';

      public static PERM_MC_CONSUMER_IMPORT_MAPPING_SHARE = 'MC_CONSUMER_IMPORT_MAPPING_SHARE';

      public static PERM_MC_CONSUMER_HISTORY_VIEW = 'MC_CONSUMER_HISTORY_VIEW';

      public static PERM_MC_CONSUMER_IMPORT_VIEW = 'MC_CONSUMER_IMPORT_VIEW';

      public static PERM_MC_CONSUMER_IMPORT_PERFORM = 'MC_CONSUMER_IMPORT_PERFORM';

      public static PERM_MC_CONSUMER_VIEW = 'MC_CONSUMER_VIEW';

      public static PERM_MC_CONSUMER_INVOICES_ADD = 'MC_CONSUMER_INVOICES_ADD';

      public static PERM_MC_CONSUMER_EDIT = 'MC_CONSUMER_EDIT';

      public static PERM_MC_CONSUMER_DELETE = 'MC_CONSUMER_DELETE';

      public static PERM_MC_CONSUMER_TRANSACTION_VIEW = 'MC_CONSUMER_TRANSACTION_VIEW';

      public static PERM_MC_CONSUMER_CONTRACT_CREATE = 'MC_CONSUMER_CONTRACT_CREATE';

      public static PERM_MC_COUNTRY_EDIT = 'MC_COUNTRY_EDIT';

      public static PERM_MC_COUNTRY_ADD = 'MC_COUNTRY_ADD';

      public static PERM_MC_COUNTRY_VIEW = 'MC_COUNTRY_VIEW';

      public static PERM_MC_BILLING_OUT_PAYOUT_FILES_VIEW = 'MC_BILLING_OUT_PAYOUT_FILES_VIEW';

      public static PERM_MC_BILLING_OUT_STATEMENT_DOWNLOAD_PDF = 'MC_BILLING_OUT_STATEMENT_DOWNLOAD_PDF';

      public static PERM_MC_BILLING_OUT_PAYOUT_FILES_CREATE = 'MC_BILLING_OUT_PAYOUT_FILES_CREATE';

      public static PERM_MC_BILLING_MY_STATEMENT_PREVIEW_XLS = 'MC_BILLING_MY_STATEMENT_PREVIEW_XLS';

      public static PERM_MC_BILLING_PRICELIST_VIEW = 'MC_BILLING_PRICELIST_VIEW';

      public static PERM_MC_BILLING_OUT_STATEMENT_ITEM_AGGREGATE = 'MC_BILLING_OUT_STATEMENT_ITEM_AGGREGATE';

      public static PERM_MC_BILLING_OUT_PAYOUT_FILES_DELETE = 'MC_BILLING_OUT_PAYOUT_FILES_DELETE';

      public static PERM_MC_BILLING_OUT_STATEMENT_EDIT = 'MC_BILLING_OUT_STATEMENT_EDIT';

      public static PERM_MC_BILLING_MY_STATEMENT_DOWNLOAD_XLS = 'MC_BILLING_MY_STATEMENT_DOWNLOAD_XLS';

      public static PERM_MC_BILLING_OUT_STATEMENT_DELETE_CORRECTION = 'MC_BILLING_OUT_STATEMENT_DELETE_CORRECTION';

      public static PERM_MC_BILLING_UPLOAD_PAYOUT_FILE = 'MC_BILLING_UPLOAD_PAYOUT_FILE';

      public static PERM_MC_BILLING_PRICELIST_SERVICE_EDIT = 'MC_BILLING_PRICELIST_SERVICE_EDIT';

      public static PERM_MC_BILLING_OUT_STATEMENT_ADD_CORRECTION = 'MC_BILLING_OUT_STATEMENT_ADD_CORRECTION';

      public static PERM_MC_BILLING_OUT_STATEMENT_PREVIEW_PDF = 'MC_BILLING_OUT_STATEMENT_PREVIEW_PDF';

      public static PERM_MC_BILLING_TEMPLATE_PRICELIST_ADD = 'MC_BILLING_TEMPLATE_PRICELIST_ADD';

      public static PERM_MC_BILLING_OUT_STATEMENT_AUTO_IMPORT_BILLING_ITEMS = 'MC_BILLING_OUT_STATEMENT_AUTO_IMPORT_BILLING_ITEMS';

      public static PERM_MC_BILLING_OUT_STATEMENT_DOWNLOAD_XLS = 'MC_BILLING_OUT_STATEMENT_DOWNLOAD_XLS';

      public static PERM_MC_BILLING_OUT_PAYOUT_FILES_DOWNLOAD = 'MC_BILLING_OUT_PAYOUT_FILES_DOWNLOAD';

      public static PERM_MC_BILLING_MY_STATEMENT_VIEW = 'MC_BILLING_MY_STATEMENT_VIEW';

      public static PERM_MC_BILLING_OUT_BILLING_ITEM_ASSIGN_TO_STATEMENT = 'MC_BILLING_OUT_BILLING_ITEM_ASSIGN_TO_STATEMENT';

      public static PERM_MC_BILLING_OUT_STATEMENT_EDIT_CORRECTION = 'MC_BILLING_OUT_STATEMENT_EDIT_CORRECTION';

      public static PERM_MC_BILLING_OUT_STATEMENT_CHANGE_STATUS = 'MC_BILLING_OUT_STATEMENT_CHANGE_STATUS';

      public static PERM_MC_BILLING_TEMPLATE_PRICELIST_VIEW = 'MC_BILLING_TEMPLATE_PRICELIST_VIEW';

      public static PERM_MC_BILLING_OUT_STATEMENT_RECALCULATE = 'MC_BILLING_OUT_STATEMENT_RECALCULATE';

      public static PERM_MC_BILLING_MY_STATEMENT_DOWNLOAD_PDF = 'MC_BILLING_MY_STATEMENT_DOWNLOAD_PDF';

      public static PERM_MC_BILLING_OUT_STATEMENT_ITEM_DEAGGREGATE = 'MC_BILLING_OUT_STATEMENT_ITEM_DEAGGREGATE';

      public static PERM_MC_BILLING_PRICELIST_ADD = 'MC_BILLING_PRICELIST_ADD';

      public static PERM_MC_BILLING_OUT_STATEMENT_ADD = 'MC_BILLING_OUT_STATEMENT_ADD';

      public static PERM_MC_BILLING_OUT_BILLING_ITEM_VIEW = 'MC_BILLING_OUT_BILLING_ITEM_VIEW';

      public static PERM_MC_BILLING_TEMPLATE_PRICELIST_SERVICE_EDIT = 'MC_BILLING_TEMPLATE_PRICELIST_SERVICE_EDIT';

      public static PERM_MC_BILLING_OUT_STATEMENT_ITEM_REMOVE_FROM_STATEMENT = 'MC_BILLING_OUT_STATEMENT_ITEM_REMOVE_FROM_STATEMENT';

      public static PERM_MC_BILLING_OUT_BILLING_HIDE_UNHIDE_STATEMENT = 'MC_BILLING_OUT_BILLING_HIDE_UNHIDE_STATEMENT';

      public static PERM_MC_BILLING_MY_STATEMENT_PREVIEW_PDF = 'MC_BILLING_MY_STATEMENT_PREVIEW_PDF';

      public static PERM_MC_BILLING_OUT_STATEMENT_FINALIZE = 'MC_BILLING_OUT_STATEMENT_FINALIZE';

      public static PERM_MC_BILLING_OUT_STATEMENT_VIEW = 'MC_BILLING_OUT_STATEMENT_VIEW';

      public static PERM_MC_BILLING_OUT_STATEMENT_ADD_ITEMS = 'MC_BILLING_OUT_STATEMENT_ADD_ITEMS';

      public static PERM_MC_BILLING_OUT_STATEMENT_PREVIEW_XLS = 'MC_BILLING_OUT_STATEMENT_PREVIEW_XLS';

      public static PERM_MC_ROLE_UPDATE = 'MC_ROLE_UPDATE';

      public static PERM_MC_ROLE_TEMPLATE_CREATE_EDIT = 'MC_ROLE_TEMPLATE_CREATE_EDIT';

      public static PERM_MC_LOGIN_CAN_READ = 'MC_LOGIN_CAN_READ';

      public static PERM_MC_SYSTEM_USER_CAN_READ = 'MC_SYSTEM_USER_CAN_READ';

      public static PERM_MC_SYSTEM_USER_CAN_CREATE = 'MC_SYSTEM_USER_CAN_CREATE';

      public static PERM_MC_USER_VIEW = 'MC_USER_VIEW';

      public static PERM_MC_ROLE_ADD = 'MC_ROLE_ADD';

      public static PERM_MC_USER_ADD = 'MC_USER_ADD';

      public static PERM_MC_USER_UPDATE = 'MC_USER_UPDATE';

      public static PERM_MC_SYSTEM_USER_CAN_UPDATE = 'MC_SYSTEM_USER_CAN_UPDATE';

      public static PERM_MC_DISABLE_ENABLE_2FA = 'MC_DISABLE_ENABLE_2FA';

      public static PERM_MC_RESEND_USER_INVITATION_EMAIL = 'MC_RESEND_USER_INVITATION_EMAIL';

      public static PERM_MC_PASSWORD_RESET = 'MC_PASSWORD_RESET';

      public static PERM_MC_ROLE_VIEW = 'MC_ROLE_VIEW';

      public static PERM_EB_FACTORING_OUT_TRANSACTION_PAYMENT_EDIT = 'EB_FACTORING_OUT_TRANSACTION_PAYMENT_EDIT';

      public static PERM_EB_FACTORING_MY_TRANSACTION_EDIT = 'EB_FACTORING_MY_TRANSACTION_EDIT';

      public static PERM_EB_FACTORING_OUT_TRANSACTION_VIEW = 'EB_FACTORING_OUT_TRANSACTION_VIEW';

      public static PERM_EB_FACTORING_OUT_TRANSACTION_ADJUSTMENT_DELETE = 'EB_FACTORING_OUT_TRANSACTION_ADJUSTMENT_DELETE';

      public static PERM_EB_FACTORING_OUT_SEPA_IN_VIEW = 'EB_FACTORING_OUT_SEPA_IN_VIEW';

      public static PERM_EB_FACTORING_EBICS_SEPA_OUT = 'EB_FACTORING_EBICS_SEPA_OUT';

      public static PERM_EB_FACTORING_OUT_TRANSACTION_BILLING_ITEMS_VIEW = 'EB_FACTORING_OUT_TRANSACTION_BILLING_ITEMS_VIEW';

      public static PERM_EB_FACTORING_MY_SEPA_REMOVE = 'EB_FACTORING_MY_SEPA_REMOVE';

      public static PERM_EB_FACTORING_STARMONEY_VIEW = 'EB_FACTORING_STARMONEY_VIEW';

      public static PERM_EB_FACTORING_EBICS_PROPERTIES = 'EB_FACTORING_EBICS_PROPERTIES';

      public static PERM_EB_FACTORING_OUT_VIEW = 'EB_FACTORING_OUT_VIEW';

      public static PERM_EB_FACTORING_OUT_TRANSACTION_EDIT = 'EB_FACTORING_OUT_TRANSACTION_EDIT';

      public static PERM_EB_FACTORING_MY_SEPA_VIEW = 'EB_FACTORING_MY_SEPA_VIEW';

      public static PERM_EB_FACTORING_MY_TRANSACTION_PAUSE_TRANSACTION = 'EB_FACTORING_MY_TRANSACTION_PAUSE_TRANSACTION';

      public static PERM_EB_FACTORING_OUT_TRANSACTION_MARK_AS_SENT_TO_INKASSO = 'EB_FACTORING_OUT_TRANSACTION_MARK_AS_SENT_TO_INKASSO';

      public static PERM_EB_FACTORING_MY_TRANSACTION_VIEW = 'EB_FACTORING_MY_TRANSACTION_VIEW';

      public static PERM_EB_FACTORING_OUT_TRANSACTION_PAYOUT_ITEMS_VIEW = 'EB_FACTORING_OUT_TRANSACTION_PAYOUT_ITEMS_VIEW';

      public static PERM_EB_FACTORING_MY_TRANSACTION_BILLING_ITEMS_VIEW = 'EB_FACTORING_MY_TRANSACTION_BILLING_ITEMS_VIEW';

      public static PERM_EB_BANK_PSD2_REPORT_VIEW = 'EB_BANK_PSD2_REPORT_VIEW';

      public static PERM_EB_FACTORING_OUT_TRANSACTION_ADJUSTMENT_EDIT = 'EB_FACTORING_OUT_TRANSACTION_ADJUSTMENT_EDIT';

      public static PERM_EB_FACTORING_IN_INKASSO_EXPORT = 'EB_FACTORING_IN_INKASSO_EXPORT';

      public static PERM_EB_FACTORING_MY_TRANSACTION_ADJUSTMENT_EDIT = 'EB_FACTORING_MY_TRANSACTION_ADJUSTMENT_EDIT';

      public static PERM_EB_FACTORING_MY_TRANSACTION_UNPAUSE_TRANSACTION = 'EB_FACTORING_MY_TRANSACTION_UNPAUSE_TRANSACTION';

      public static PERM_EB_FACTORING_OUT_TRANSACTION_PAYMENT_DELETE = 'EB_FACTORING_OUT_TRANSACTION_PAYMENT_DELETE';

      public static PERM_EB_FACTORING_MY_TRANSACTION_PAYMENT_ADD = 'EB_FACTORING_MY_TRANSACTION_PAYMENT_ADD';

      public static PERM_EB_FACTORING_BALANCE_SETTLE_WITH_BALANCE = 'EB_FACTORING_BALANCE_SETTLE_WITH_BALANCE';

      public static PERM_EB_FACTORING_MY_TRANSACTION_ADJUSTMENT_ADD = 'EB_FACTORING_MY_TRANSACTION_ADJUSTMENT_ADD';

      public static PERM_EB_FACTORING_MY_TRANSACTION_RECORD_PAYMENT = 'EB_FACTORING_MY_TRANSACTION_RECORD_PAYMENT';

      public static PERM_EB_BANK_UPLOAD_SEPA_EXPORT_FILE = 'EB_BANK_UPLOAD_SEPA_EXPORT_FILE';

      public static PERM_EB_FACTORING_OUT_TRANSACTION_ADJUSTMENT_ADD = 'EB_FACTORING_OUT_TRANSACTION_ADJUSTMENT_ADD';

      public static PERM_EB_FACTORING_OUT_TRANSACTION_PAYMENT_ADD = 'EB_FACTORING_OUT_TRANSACTION_PAYMENT_ADD';

      public static PERM_EB_FACTORING_EBICS_REPORT_VIEW = 'EB_FACTORING_EBICS_REPORT_VIEW';

      public static PERM_EB_FACTORING_OUT_INKASSO_EXPORT = 'EB_FACTORING_OUT_INKASSO_EXPORT';

      public static PERM_EB_FACTORING_OUT_SEPA_OUT_VIEW = 'EB_FACTORING_OUT_SEPA_OUT_VIEW';

      public static PERM_EB_FACTORING_MY_TRANSACTION_ADJUSTMENT_DELETE = 'EB_FACTORING_MY_TRANSACTION_ADJUSTMENT_DELETE';

      public static PERM_EB_FACTORING_EBICS_PAYOUT_FILES = 'EB_FACTORING_EBICS_PAYOUT_FILES';

      public static PERM_EB_FACTORING_OUT_CONTRACT_VIEW = 'EB_FACTORING_OUT_CONTRACT_VIEW';

      public static PERM_EB_FACTORING_MY_VIEW = 'EB_FACTORING_MY_VIEW';

      public static PERM_EB_FACTORING_MY_CONTRACTS_VIEW = 'EB_FACTORING_MY_CONTRACTS_VIEW';

      public static PERM_EB_FACTORING_OUT_SEPA_EXPORT = 'EB_FACTORING_OUT_SEPA_EXPORT';

      public static PERM_EB_FACTORING_MY_TRANSACTION_ADD = 'EB_FACTORING_MY_TRANSACTION_ADD';

      public static PERM_EB_FACTORING_MY_TRANSACTION_PAYMENT_DELETE = 'EB_FACTORING_MY_TRANSACTION_PAYMENT_DELETE';

      public static PERM_EB_FACTORING_OUT_BLACKLIST_VIEW = 'EB_FACTORING_OUT_BLACKLIST_VIEW';

      public static PERM_EB_FACTORING_MY_TRANSACTION_PAYMENT_EDIT = 'EB_FACTORING_MY_TRANSACTION_PAYMENT_EDIT';

      public static PERM_EB_FACTORING_MY_TRANSACTION_PAYOUT_ITEMS_VIEW = 'EB_FACTORING_MY_TRANSACTION_PAYOUT_ITEMS_VIEW';

      public static PERM_EB_FACTORING_OUT_TRANSACTION_ADD = 'EB_FACTORING_OUT_TRANSACTION_ADD';

      public static PERM_EB_FACTORING_MY_TRANSACTION_DELETE = 'EB_FACTORING_MY_TRANSACTION_DELETE';

      public static PERM_PM_TRANSACTION_STORNO_AT_ADMIN_REQUEST = 'PM_TRANSACTION_STORNO_AT_ADMIN_REQUEST';

      public static PERM_CSR_CONSUMER_IMPORT_PERFORM = 'CSR_CONSUMER_IMPORT_PERFORM';

      public static PERM_CSR_CONTRACT_VISIBILITY_EDIT = 'CSR_CONTRACT_VISIBILITY_EDIT';

      public static PERM_CSR_CONTRACT_ADD = 'CSR_CONTRACT_ADD';

      public static PERM_CSR_CONSUMER_IMPORT_VIEW = 'CSR_CONSUMER_IMPORT_VIEW';

      public static PERM_CSR_CONSUMER_TRANSACTION_ADD = 'CSR_CONSUMER_TRANSACTION_ADD';

      public static PERM_CSR_CONSUMER_TRANSACTION_VIEW = 'CSR_CONSUMER_TRANSACTION_VIEW';

      public static PERM_CSR_CONSUMER_360 = 'CSR_CONSUMER_360';

      public static PERM_CSR_CONSUMER_EDIT = 'CSR_CONSUMER_EDIT';

      public static PERM_CSR_CONSUMER_INVOICES_ADD = 'CSR_CONSUMER_INVOICES_ADD';

      public static PERM_CSR_CONSUMER_ADD = 'CSR_CONSUMER_ADD';

      public static PERM_CSR_CONSUMER_IMPORT_MAPPING_VIEW = 'CSR_CONSUMER_IMPORT_MAPPING_VIEW';

      public static PERM_CSR_CONSUMER_VIEW = 'CSR_CONSUMER_VIEW';

      public static PERM_CSR_CONSUMER_ACTION_HISTORY_VIEW = 'CSR_CONSUMER_ACTION_HISTORY_VIEW';

      public static PERM_CSR_CONSUMER_INVOICES_VIEW = 'CSR_CONSUMER_INVOICES_VIEW';

      public static PERM_CSR_CONTRACT_VIEW = 'CSR_CONTRACT_VIEW';

      public static PERM_CSR_CONSUMER_DELETE = 'CSR_CONSUMER_DELETE';

      public static PERM_CSR_CONSUMER_IMPORT_MAPPING_EDIT = 'CSR_CONSUMER_IMPORT_MAPPING_EDIT';

      public static PERM_CSR_CONSUMER_IMPORT_MAPPING_SHARE = 'CSR_CONSUMER_IMPORT_MAPPING_SHARE';

      public static PERM_CSR_CONSUMER_CONTRACT_CREATE = 'CSR_CONSUMER_CONTRACT_CREATE';

      public static PERM_CSR_CONSUMER_HISTORY_VIEW = 'CSR_CONSUMER_HISTORY_VIEW';

      public static PERM_PP_PRODUCT_UPDATE = 'PP_PRODUCT_UPDATE';

      public static PERM_PP_PRODUCT_ADD = 'PP_PRODUCT_ADD';

      public static PERM_PP_CATEGORY_ADD = 'PP_CATEGORY_ADD';

      public static PERM_PP_PRODUCT_VIEW = 'PP_PRODUCT_VIEW';

      public static PERM_IM_DATA_EXPORT_VIEW = 'IM_DATA_EXPORT_VIEW';


  public abstract userHasPermission(aPermissionCd: string): boolean;


    public userHasApiConsumerEditPermission(): boolean {
        return this.userHasPermission('API_CONSUMER_EDIT');
    }

    public userHasApiExperimentalPermission(): boolean {
        return this.userHasPermission('API_EXPERIMENTAL');
    }

    public userHasApiTransactionEditPermission(): boolean {
        return this.userHasPermission('API_TRANSACTION_EDIT');
    }

    public userHasApiConsumerCreatePermission(): boolean {
        return this.userHasPermission('API_CONSUMER_CREATE');
    }

    public userHasApiConsumerViewPermission(): boolean {
        return this.userHasPermission('API_CONSUMER_VIEW');
    }

    public userHasApiTransactionCreatePermission(): boolean {
        return this.userHasPermission('API_TRANSACTION_CREATE');
    }

    public userHasApiTransactionViewPermission(): boolean {
        return this.userHasPermission('API_TRANSACTION_VIEW');
    }

    public userHasMcProcessRunAddPermission(): boolean {
        return this.userHasPermission('MC_PROCESS_RUN_ADD');
    }

    public userHasMcProcessFlowEditPermission(): boolean {
        return this.userHasPermission('MC_PROCESS_FLOW_EDIT');
    }

    public userHasMcProcessFlowLevelEditPermission(): boolean {
        return this.userHasPermission('MC_PROCESS_FLOW_LEVEL_EDIT');
    }

    public userHasMcProcessRunManagePermission(): boolean {
        return this.userHasPermission('MC_PROCESS_RUN_MANAGE');
    }

    public userHasMcProcessRunViewPermission(): boolean {
        return this.userHasPermission('MC_PROCESS_RUN_VIEW');
    }

    public userHasMcProcessRunEditPermission(): boolean {
        return this.userHasPermission('MC_PROCESS_RUN_EDIT');
    }

    public userHasMcProcessPdfTemplateEditPermission(): boolean {
        return this.userHasPermission('MC_PROCESS_PDF_TEMPLATE_EDIT');
    }

    public userHasMcProcessItemViewPermission(): boolean {
        return this.userHasPermission('MC_PROCESS_ITEM_VIEW');
    }

    public userHasMcProcessFlowLevelViewPermission(): boolean {
        return this.userHasPermission('MC_PROCESS_FLOW_LEVEL_VIEW');
    }

    public userHasMcProcessViewPermission(): boolean {
        return this.userHasPermission('MC_PROCESS_VIEW');
    }

    public userHasMcProcessItemChangeNextActionDatePermission(): boolean {
        return this.userHasPermission('MC_PROCESS_ITEM_CHANGE_NEXT_ACTION_DATE');
    }

    public userHasMcProcessBbmTestPermission(): boolean {
        return this.userHasPermission('MC_PROCESS_BBM_TEST');
    }

    public userHasMcProcessPdfTemplateViewPermission(): boolean {
        return this.userHasPermission('MC_PROCESS_PDF_TEMPLATE_VIEW');
    }

    public userHasMcProcessFlowAddPermission(): boolean {
        return this.userHasPermission('MC_PROCESS_FLOW_ADD');
    }

    public userHasMcProcessEditPermission(): boolean {
        return this.userHasPermission('MC_PROCESS_EDIT');
    }

    public userHasMcProcessFlowViewPermission(): boolean {
        return this.userHasPermission('MC_PROCESS_FLOW_VIEW');
    }

    public userHasMcProcessFlowLevelAddPermission(): boolean {
        return this.userHasPermission('MC_PROCESS_FLOW_LEVEL_ADD');
    }

    public userHasMcProcessAddPermission(): boolean {
        return this.userHasPermission('MC_PROCESS_ADD');
    }

    public userHasMcProcessPdfTemplateAddPermission(): boolean {
        return this.userHasPermission('MC_PROCESS_PDF_TEMPLATE_ADD');
    }

    public userHasDashboardEntityParentViewPermission(): boolean {
        return this.userHasPermission('DASHBOARD_ENTITY_PARENT_VIEW');
    }

    public userHasDashboardEntityChildViewPermission(): boolean {
        return this.userHasPermission('DASHBOARD_ENTITY_CHILD_VIEW');
    }

    public userHasMcBankAccountDeletePermission(): boolean {
        return this.userHasPermission('MC_BANK_ACCOUNT_DELETE');
    }

    public userHasMcEntityAddEditTransactionDescriptionPermission(): boolean {
        return this.userHasPermission('MC_ENTITY_ADD_EDIT_TRANSACTION_DESCRIPTION');
    }

    public userHasMcEntityUpdatePermission(): boolean {
        return this.userHasPermission('MC_ENTITY_UPDATE');
    }

    public userHasMcEntityViewPermission(): boolean {
        return this.userHasPermission('MC_ENTITY_VIEW');
    }

    public userHasMcEntitySettingsEditPermission(): boolean {
        return this.userHasPermission('MC_ENTITY_SETTINGS_EDIT');
    }

    public userHasMcEntityDashboardViewPermission(): boolean {
        return this.userHasPermission('MC_ENTITY_DASHBOARD_VIEW');
    }

    public userHasMcEntityApiKeyEditPermission(): boolean {
        return this.userHasPermission('MC_ENTITY_API_KEY_EDIT');
    }

    public userHasMcEntityApiKeyViewPermission(): boolean {
        return this.userHasPermission('MC_ENTITY_API_KEY_VIEW');
    }

    public userHasMcBankAccountEditPermission(): boolean {
        return this.userHasPermission('MC_BANK_ACCOUNT_EDIT');
    }

    public userHasMcEntityProfileViewPermission(): boolean {
        return this.userHasPermission('MC_ENTITY_PROFILE_VIEW');
    }

    public userHasMcPasswordPolicyUpdatePermission(): boolean {
        return this.userHasPermission('MC_PASSWORD_POLICY_UPDATE');
    }

    public userHasMcEntitySettingsViewPermission(): boolean {
        return this.userHasPermission('MC_ENTITY_SETTINGS_VIEW');
    }

    public userHasMcTempInvoicesViewPermission(): boolean {
        return this.userHasPermission('MC_TEMP_INVOICES_VIEW');
    }

    public userHasMcEntityEditMaxTransactionPauseDaysPermission(): boolean {
        return this.userHasPermission('MC_ENTITY_EDIT_MAX_TRANSACTION_PAUSE_DAYS');
    }

    public userHasMcEntityCanCreatePermission(): boolean {
        return this.userHasPermission('MC_ENTITY_CAN_CREATE');
    }

    public userHasMcTempJobViewPermission(): boolean {
        return this.userHasPermission('MC_TEMP_JOB_VIEW');
    }

    public userHasMcEntityCanReadPermission(): boolean {
        return this.userHasPermission('MC_ENTITY_CAN_READ');
    }

    public userHasMcEntityCanUpdatePermission(): boolean {
        return this.userHasPermission('MC_ENTITY_CAN_UPDATE');
    }

    public userHasMcBankAccountCreatePermission(): boolean {
        return this.userHasPermission('MC_BANK_ACCOUNT_CREATE');
    }

    public userHasMcConsumerActionHistoryViewPermission(): boolean {
        return this.userHasPermission('MC_CONSUMER_ACTION_HISTORY_VIEW');
    }

    public userHasMcConsumerInvoicesViewPermission(): boolean {
        return this.userHasPermission('MC_CONSUMER_INVOICES_VIEW');
    }

    public userHasMcConsumerTransactionAddPermission(): boolean {
        return this.userHasPermission('MC_CONSUMER_TRANSACTION_ADD');
    }

    public userHasMcConsumerAddPermission(): boolean {
        return this.userHasPermission('MC_CONSUMER_ADD');
    }

    public userHasMcConsumerImportMappingViewPermission(): boolean {
        return this.userHasPermission('MC_CONSUMER_IMPORT_MAPPING_VIEW');
    }

    public userHasMcConsumerImportMappingEditPermission(): boolean {
        return this.userHasPermission('MC_CONSUMER_IMPORT_MAPPING_EDIT');
    }

    public userHasMcConsumerImportMappingSharePermission(): boolean {
        return this.userHasPermission('MC_CONSUMER_IMPORT_MAPPING_SHARE');
    }

    public userHasMcConsumerHistoryViewPermission(): boolean {
        return this.userHasPermission('MC_CONSUMER_HISTORY_VIEW');
    }

    public userHasMcConsumerImportViewPermission(): boolean {
        return this.userHasPermission('MC_CONSUMER_IMPORT_VIEW');
    }

    public userHasMcConsumerImportPerformPermission(): boolean {
        return this.userHasPermission('MC_CONSUMER_IMPORT_PERFORM');
    }

    public userHasMcConsumerViewPermission(): boolean {
        return this.userHasPermission('MC_CONSUMER_VIEW');
    }

    public userHasMcConsumerInvoicesAddPermission(): boolean {
        return this.userHasPermission('MC_CONSUMER_INVOICES_ADD');
    }

    public userHasMcConsumerEditPermission(): boolean {
        return this.userHasPermission('MC_CONSUMER_EDIT');
    }

    public userHasMcConsumerDeletePermission(): boolean {
        return this.userHasPermission('MC_CONSUMER_DELETE');
    }

    public userHasMcConsumerTransactionViewPermission(): boolean {
        return this.userHasPermission('MC_CONSUMER_TRANSACTION_VIEW');
    }

    public userHasMcConsumerContractCreatePermission(): boolean {
        return this.userHasPermission('MC_CONSUMER_CONTRACT_CREATE');
    }

    public userHasMcCountryEditPermission(): boolean {
        return this.userHasPermission('MC_COUNTRY_EDIT');
    }

    public userHasMcCountryAddPermission(): boolean {
        return this.userHasPermission('MC_COUNTRY_ADD');
    }

    public userHasMcCountryViewPermission(): boolean {
        return this.userHasPermission('MC_COUNTRY_VIEW');
    }

    public userHasMcBillingOutPayoutFilesViewPermission(): boolean {
        return this.userHasPermission('MC_BILLING_OUT_PAYOUT_FILES_VIEW');
    }

    public userHasMcBillingOutStatementDownloadPdfPermission(): boolean {
        return this.userHasPermission('MC_BILLING_OUT_STATEMENT_DOWNLOAD_PDF');
    }

    public userHasMcBillingOutPayoutFilesCreatePermission(): boolean {
        return this.userHasPermission('MC_BILLING_OUT_PAYOUT_FILES_CREATE');
    }

    public userHasMcBillingMyStatementPreviewXlsPermission(): boolean {
        return this.userHasPermission('MC_BILLING_MY_STATEMENT_PREVIEW_XLS');
    }

    public userHasMcBillingPricelistViewPermission(): boolean {
        return this.userHasPermission('MC_BILLING_PRICELIST_VIEW');
    }

    public userHasMcBillingOutStatementItemAggregatePermission(): boolean {
        return this.userHasPermission('MC_BILLING_OUT_STATEMENT_ITEM_AGGREGATE');
    }

    public userHasMcBillingOutPayoutFilesDeletePermission(): boolean {
        return this.userHasPermission('MC_BILLING_OUT_PAYOUT_FILES_DELETE');
    }

    public userHasMcBillingOutStatementEditPermission(): boolean {
        return this.userHasPermission('MC_BILLING_OUT_STATEMENT_EDIT');
    }

    public userHasMcBillingMyStatementDownloadXlsPermission(): boolean {
        return this.userHasPermission('MC_BILLING_MY_STATEMENT_DOWNLOAD_XLS');
    }

    public userHasMcBillingOutStatementDeleteCorrectionPermission(): boolean {
        return this.userHasPermission('MC_BILLING_OUT_STATEMENT_DELETE_CORRECTION');
    }

    public userHasMcBillingUploadPayoutFilePermission(): boolean {
        return this.userHasPermission('MC_BILLING_UPLOAD_PAYOUT_FILE');
    }

    public userHasMcBillingPricelistServiceEditPermission(): boolean {
        return this.userHasPermission('MC_BILLING_PRICELIST_SERVICE_EDIT');
    }

    public userHasMcBillingOutStatementAddCorrectionPermission(): boolean {
        return this.userHasPermission('MC_BILLING_OUT_STATEMENT_ADD_CORRECTION');
    }

    public userHasMcBillingOutStatementPreviewPdfPermission(): boolean {
        return this.userHasPermission('MC_BILLING_OUT_STATEMENT_PREVIEW_PDF');
    }

    public userHasMcBillingTemplatePricelistAddPermission(): boolean {
        return this.userHasPermission('MC_BILLING_TEMPLATE_PRICELIST_ADD');
    }

    public userHasMcBillingOutStatementAutoImportBillingItemsPermission(): boolean {
        return this.userHasPermission('MC_BILLING_OUT_STATEMENT_AUTO_IMPORT_BILLING_ITEMS');
    }

    public userHasMcBillingOutStatementDownloadXlsPermission(): boolean {
        return this.userHasPermission('MC_BILLING_OUT_STATEMENT_DOWNLOAD_XLS');
    }

    public userHasMcBillingOutPayoutFilesDownloadPermission(): boolean {
        return this.userHasPermission('MC_BILLING_OUT_PAYOUT_FILES_DOWNLOAD');
    }

    public userHasMcBillingMyStatementViewPermission(): boolean {
        return this.userHasPermission('MC_BILLING_MY_STATEMENT_VIEW');
    }

    public userHasMcBillingOutBillingItemAssignToStatementPermission(): boolean {
        return this.userHasPermission('MC_BILLING_OUT_BILLING_ITEM_ASSIGN_TO_STATEMENT');
    }

    public userHasMcBillingOutStatementEditCorrectionPermission(): boolean {
        return this.userHasPermission('MC_BILLING_OUT_STATEMENT_EDIT_CORRECTION');
    }

    public userHasMcBillingOutStatementChangeStatusPermission(): boolean {
        return this.userHasPermission('MC_BILLING_OUT_STATEMENT_CHANGE_STATUS');
    }

    public userHasMcBillingTemplatePricelistViewPermission(): boolean {
        return this.userHasPermission('MC_BILLING_TEMPLATE_PRICELIST_VIEW');
    }

    public userHasMcBillingOutStatementRecalculatePermission(): boolean {
        return this.userHasPermission('MC_BILLING_OUT_STATEMENT_RECALCULATE');
    }

    public userHasMcBillingMyStatementDownloadPdfPermission(): boolean {
        return this.userHasPermission('MC_BILLING_MY_STATEMENT_DOWNLOAD_PDF');
    }

    public userHasMcBillingOutStatementItemDeaggregatePermission(): boolean {
        return this.userHasPermission('MC_BILLING_OUT_STATEMENT_ITEM_DEAGGREGATE');
    }

    public userHasMcBillingPricelistAddPermission(): boolean {
        return this.userHasPermission('MC_BILLING_PRICELIST_ADD');
    }

    public userHasMcBillingOutStatementAddPermission(): boolean {
        return this.userHasPermission('MC_BILLING_OUT_STATEMENT_ADD');
    }

    public userHasMcBillingOutBillingItemViewPermission(): boolean {
        return this.userHasPermission('MC_BILLING_OUT_BILLING_ITEM_VIEW');
    }

    public userHasMcBillingTemplatePricelistServiceEditPermission(): boolean {
        return this.userHasPermission('MC_BILLING_TEMPLATE_PRICELIST_SERVICE_EDIT');
    }

    public userHasMcBillingOutStatementItemRemoveFromStatementPermission(): boolean {
        return this.userHasPermission('MC_BILLING_OUT_STATEMENT_ITEM_REMOVE_FROM_STATEMENT');
    }

    public userHasMcBillingOutBillingHideUnhideStatementPermission(): boolean {
        return this.userHasPermission('MC_BILLING_OUT_BILLING_HIDE_UNHIDE_STATEMENT');
    }

    public userHasMcBillingMyStatementPreviewPdfPermission(): boolean {
        return this.userHasPermission('MC_BILLING_MY_STATEMENT_PREVIEW_PDF');
    }

    public userHasMcBillingOutStatementFinalizePermission(): boolean {
        return this.userHasPermission('MC_BILLING_OUT_STATEMENT_FINALIZE');
    }

    public userHasMcBillingOutStatementViewPermission(): boolean {
        return this.userHasPermission('MC_BILLING_OUT_STATEMENT_VIEW');
    }

    public userHasMcBillingOutStatementAddItemsPermission(): boolean {
        return this.userHasPermission('MC_BILLING_OUT_STATEMENT_ADD_ITEMS');
    }

    public userHasMcBillingOutStatementPreviewXlsPermission(): boolean {
        return this.userHasPermission('MC_BILLING_OUT_STATEMENT_PREVIEW_XLS');
    }

    public userHasMcRoleUpdatePermission(): boolean {
        return this.userHasPermission('MC_ROLE_UPDATE');
    }

    public userHasMcRoleTemplateCreateEditPermission(): boolean {
        return this.userHasPermission('MC_ROLE_TEMPLATE_CREATE_EDIT');
    }

    public userHasMcLoginCanReadPermission(): boolean {
        return this.userHasPermission('MC_LOGIN_CAN_READ');
    }

    public userHasMcSystemUserCanReadPermission(): boolean {
        return this.userHasPermission('MC_SYSTEM_USER_CAN_READ');
    }

    public userHasMcSystemUserCanCreatePermission(): boolean {
        return this.userHasPermission('MC_SYSTEM_USER_CAN_CREATE');
    }

    public userHasMcUserViewPermission(): boolean {
        return this.userHasPermission('MC_USER_VIEW');
    }

    public userHasMcRoleAddPermission(): boolean {
        return this.userHasPermission('MC_ROLE_ADD');
    }

    public userHasMcUserAddPermission(): boolean {
        return this.userHasPermission('MC_USER_ADD');
    }

    public userHasMcUserUpdatePermission(): boolean {
        return this.userHasPermission('MC_USER_UPDATE');
    }

    public userHasMcSystemUserCanUpdatePermission(): boolean {
        return this.userHasPermission('MC_SYSTEM_USER_CAN_UPDATE');
    }

    public userHasMcDisableEnable2faPermission(): boolean {
        return this.userHasPermission('MC_DISABLE_ENABLE_2FA');
    }

    public userHasMcResendUserInvitationEmailPermission(): boolean {
        return this.userHasPermission('MC_RESEND_USER_INVITATION_EMAIL');
    }

    public userHasMcPasswordResetPermission(): boolean {
        return this.userHasPermission('MC_PASSWORD_RESET');
    }

    public userHasMcRoleViewPermission(): boolean {
        return this.userHasPermission('MC_ROLE_VIEW');
    }

    public userHasEbFactoringOutTransactionPaymentEditPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_OUT_TRANSACTION_PAYMENT_EDIT');
    }

    public userHasEbFactoringMyTransactionEditPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_MY_TRANSACTION_EDIT');
    }

    public userHasEbFactoringOutTransactionViewPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_OUT_TRANSACTION_VIEW');
    }

    public userHasEbFactoringOutTransactionAdjustmentDeletePermission(): boolean {
        return this.userHasPermission('EB_FACTORING_OUT_TRANSACTION_ADJUSTMENT_DELETE');
    }

    public userHasEbFactoringOutSepaInViewPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_OUT_SEPA_IN_VIEW');
    }

    public userHasEbFactoringEbicsSepaOutPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_EBICS_SEPA_OUT');
    }

    public userHasEbFactoringOutTransactionBillingItemsViewPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_OUT_TRANSACTION_BILLING_ITEMS_VIEW');
    }

    public userHasEbFactoringMySepaRemovePermission(): boolean {
        return this.userHasPermission('EB_FACTORING_MY_SEPA_REMOVE');
    }

    public userHasEbFactoringStarmoneyViewPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_STARMONEY_VIEW');
    }

    public userHasEbFactoringEbicsPropertiesPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_EBICS_PROPERTIES');
    }

    public userHasEbFactoringOutViewPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_OUT_VIEW');
    }

    public userHasEbFactoringOutTransactionEditPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_OUT_TRANSACTION_EDIT');
    }

    public userHasEbFactoringMySepaViewPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_MY_SEPA_VIEW');
    }

    public userHasEbFactoringMyTransactionPauseTransactionPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_MY_TRANSACTION_PAUSE_TRANSACTION');
    }

    public userHasEbFactoringOutTransactionMarkAsSentToInkassoPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_OUT_TRANSACTION_MARK_AS_SENT_TO_INKASSO');
    }

    public userHasEbFactoringMyTransactionViewPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_MY_TRANSACTION_VIEW');
    }

    public userHasEbFactoringOutTransactionPayoutItemsViewPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_OUT_TRANSACTION_PAYOUT_ITEMS_VIEW');
    }

    public userHasEbFactoringMyTransactionBillingItemsViewPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_MY_TRANSACTION_BILLING_ITEMS_VIEW');
    }

    public userHasEbBankPsd2ReportViewPermission(): boolean {
        return this.userHasPermission('EB_BANK_PSD2_REPORT_VIEW');
    }

    public userHasEbFactoringOutTransactionAdjustmentEditPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_OUT_TRANSACTION_ADJUSTMENT_EDIT');
    }

    public userHasEbFactoringInInkassoExportPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_IN_INKASSO_EXPORT');
    }

    public userHasEbFactoringMyTransactionAdjustmentEditPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_MY_TRANSACTION_ADJUSTMENT_EDIT');
    }

    public userHasEbFactoringMyTransactionUnpauseTransactionPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_MY_TRANSACTION_UNPAUSE_TRANSACTION');
    }

    public userHasEbFactoringOutTransactionPaymentDeletePermission(): boolean {
        return this.userHasPermission('EB_FACTORING_OUT_TRANSACTION_PAYMENT_DELETE');
    }

    public userHasEbFactoringMyTransactionPaymentAddPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_MY_TRANSACTION_PAYMENT_ADD');
    }

    public userHasEbFactoringBalanceSettleWithBalancePermission(): boolean {
        return this.userHasPermission('EB_FACTORING_BALANCE_SETTLE_WITH_BALANCE');
    }

    public userHasEbFactoringMyTransactionAdjustmentAddPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_MY_TRANSACTION_ADJUSTMENT_ADD');
    }

    public userHasEbFactoringMyTransactionRecordPaymentPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_MY_TRANSACTION_RECORD_PAYMENT');
    }

    public userHasEbBankUploadSepaExportFilePermission(): boolean {
        return this.userHasPermission('EB_BANK_UPLOAD_SEPA_EXPORT_FILE');
    }

    public userHasEbFactoringOutTransactionAdjustmentAddPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_OUT_TRANSACTION_ADJUSTMENT_ADD');
    }

    public userHasEbFactoringOutTransactionPaymentAddPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_OUT_TRANSACTION_PAYMENT_ADD');
    }

    public userHasEbFactoringEbicsReportViewPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_EBICS_REPORT_VIEW');
    }

    public userHasEbFactoringOutInkassoExportPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_OUT_INKASSO_EXPORT');
    }

    public userHasEbFactoringOutSepaOutViewPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_OUT_SEPA_OUT_VIEW');
    }

    public userHasEbFactoringMyTransactionAdjustmentDeletePermission(): boolean {
        return this.userHasPermission('EB_FACTORING_MY_TRANSACTION_ADJUSTMENT_DELETE');
    }

    public userHasEbFactoringEbicsPayoutFilesPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_EBICS_PAYOUT_FILES');
    }

    public userHasEbFactoringOutContractViewPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_OUT_CONTRACT_VIEW');
    }

    public userHasEbFactoringMyViewPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_MY_VIEW');
    }

    public userHasEbFactoringMyContractsViewPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_MY_CONTRACTS_VIEW');
    }

    public userHasEbFactoringOutSepaExportPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_OUT_SEPA_EXPORT');
    }

    public userHasEbFactoringMyTransactionAddPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_MY_TRANSACTION_ADD');
    }

    public userHasEbFactoringMyTransactionPaymentDeletePermission(): boolean {
        return this.userHasPermission('EB_FACTORING_MY_TRANSACTION_PAYMENT_DELETE');
    }

    public userHasEbFactoringOutBlacklistViewPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_OUT_BLACKLIST_VIEW');
    }

    public userHasEbFactoringMyTransactionPaymentEditPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_MY_TRANSACTION_PAYMENT_EDIT');
    }

    public userHasEbFactoringMyTransactionPayoutItemsViewPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_MY_TRANSACTION_PAYOUT_ITEMS_VIEW');
    }

    public userHasEbFactoringOutTransactionAddPermission(): boolean {
        return this.userHasPermission('EB_FACTORING_OUT_TRANSACTION_ADD');
    }

    public userHasEbFactoringMyTransactionDeletePermission(): boolean {
        return this.userHasPermission('EB_FACTORING_MY_TRANSACTION_DELETE');
    }

    public userHasPmTransactionStornoAtAdminRequestPermission(): boolean {
        return this.userHasPermission('PM_TRANSACTION_STORNO_AT_ADMIN_REQUEST');
    }

    public userHasCsrConsumerImportPerformPermission(): boolean {
        return this.userHasPermission('CSR_CONSUMER_IMPORT_PERFORM');
    }

    public userHasCsrContractVisibilityEditPermission(): boolean {
        return this.userHasPermission('CSR_CONTRACT_VISIBILITY_EDIT');
    }

    public userHasCsrContractAddPermission(): boolean {
        return this.userHasPermission('CSR_CONTRACT_ADD');
    }

    public userHasCsrConsumerImportViewPermission(): boolean {
        return this.userHasPermission('CSR_CONSUMER_IMPORT_VIEW');
    }

    public userHasCsrConsumerTransactionAddPermission(): boolean {
        return this.userHasPermission('CSR_CONSUMER_TRANSACTION_ADD');
    }

    public userHasCsrConsumerTransactionViewPermission(): boolean {
        return this.userHasPermission('CSR_CONSUMER_TRANSACTION_VIEW');
    }

    public userHasCsrConsumer360Permission(): boolean {
        return this.userHasPermission('CSR_CONSUMER_360');
    }

    public userHasCsrConsumerEditPermission(): boolean {
        return this.userHasPermission('CSR_CONSUMER_EDIT');
    }

    public userHasCsrConsumerInvoicesAddPermission(): boolean {
        return this.userHasPermission('CSR_CONSUMER_INVOICES_ADD');
    }

    public userHasCsrConsumerAddPermission(): boolean {
        return this.userHasPermission('CSR_CONSUMER_ADD');
    }

    public userHasCsrConsumerImportMappingViewPermission(): boolean {
        return this.userHasPermission('CSR_CONSUMER_IMPORT_MAPPING_VIEW');
    }

    public userHasCsrConsumerViewPermission(): boolean {
        return this.userHasPermission('CSR_CONSUMER_VIEW');
    }

    public userHasCsrConsumerActionHistoryViewPermission(): boolean {
        return this.userHasPermission('CSR_CONSUMER_ACTION_HISTORY_VIEW');
    }

    public userHasCsrConsumerInvoicesViewPermission(): boolean {
        return this.userHasPermission('CSR_CONSUMER_INVOICES_VIEW');
    }

    public userHasCsrContractViewPermission(): boolean {
        return this.userHasPermission('CSR_CONTRACT_VIEW');
    }

    public userHasCsrConsumerDeletePermission(): boolean {
        return this.userHasPermission('CSR_CONSUMER_DELETE');
    }

    public userHasCsrConsumerImportMappingEditPermission(): boolean {
        return this.userHasPermission('CSR_CONSUMER_IMPORT_MAPPING_EDIT');
    }

    public userHasCsrConsumerImportMappingSharePermission(): boolean {
        return this.userHasPermission('CSR_CONSUMER_IMPORT_MAPPING_SHARE');
    }

    public userHasCsrConsumerContractCreatePermission(): boolean {
        return this.userHasPermission('CSR_CONSUMER_CONTRACT_CREATE');
    }

    public userHasCsrConsumerHistoryViewPermission(): boolean {
        return this.userHasPermission('CSR_CONSUMER_HISTORY_VIEW');
    }

    public userHasPpProductUpdatePermission(): boolean {
        return this.userHasPermission('PP_PRODUCT_UPDATE');
    }

    public userHasPpProductAddPermission(): boolean {
        return this.userHasPermission('PP_PRODUCT_ADD');
    }

    public userHasPpCategoryAddPermission(): boolean {
        return this.userHasPermission('PP_CATEGORY_ADD');
    }

    public userHasPpProductViewPermission(): boolean {
        return this.userHasPermission('PP_PRODUCT_VIEW');
    }

    public userHasImDataExportViewPermission(): boolean {
        return this.userHasPermission('IM_DATA_EXPORT_VIEW');
    }


}
