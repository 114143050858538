/*BbmTestObj*/
import {CsrContractGENERATED} from '../_generated/csr-contract-generated.model';
import {McHtml} from '../_core/mc-html';
import {McGod} from '../extra/mc-god.service';
import {McDateUtils} from '../common/mc-date-utils';
import {ActionOptions} from '../_core/mc-html-action-options';
import {CsrRecurringDetails} from '../models/csr-recurring-details.model';
import {ICON_STATUS_INACTIVE} from "../svg/icon-status-inactive";
import {ICON_STATUS_WAITING_FOR_APPROVAL} from "../svg/icon-status-waiting-for-approval";
import {ICON_STATUS_ACTIVE} from "../svg/icon-status-active";
import {CsrBankAccount} from "../models/csr-bank-account.model";
import {McBankAccount} from "../models/mc-bank-account.model";
import { CsrContractPreDefined } from './csr-contract-pre-defined.model';

export class CsrContract extends CsrContractGENERATED {
  public static PAYMENT_METHOD_DIRECT_DEBIT = 'Direct debit';
  public static PAYMENT_METHOD_MONEY_TRANSFER = 'Money transfer';
  public static PAYMENT_METHOD_CASH_ONSITE = 'Cash onsite';

  public static CSR_CONTRACT_VIEW = 'CSR_CONTRACT_VIEW';
  public static CSR_CONTRACT_DIRECT_DEBIT_APPROVAL_DOWNLOAD = 'CSR_CONTRACT_DIRECT_DEBIT_APPROVAL_VIEW';

  private _fldCsrContractActions: McHtml | null = null;

  override recurringDetails = new CsrRecurringDetails();

  jsonbPaymentDetails: string;
  jsonbRecurringDetails: string;
  csrBankAccount: CsrBankAccount;
  mcBankAccount: McBankAccount;
  csrContractPreDefined: CsrContractPreDefined;

  override properties: string[] = ['id', 'approvalKey', 'autoRenewal', 'autoRenewalLength', 'cancellationDeadline', 'cancellationReason', 'consumerName', 'consumerStatusCd', 'contractCancellationActiveOnDate', 'contractCancellationDate', 'contractModel', 'contractName', 'contractNumber', 'contractSigningDate', 'contractType', 'description', 'durationInMonths', 'endDate', 'idBeneficiary', 'idCsrConsumer', 'idCsrContractPreDefined', 'csrContractPreDefined', 'idCsrDiscount', 'idMcEntity', 'idWebFile', 'idWebfileDdApproval', 'idWebfileOther', 'idWebfileSignature', 'jsonbNotificationsDetails', 'jsonbPaymentDetails', 'jsonbRecurringDetails', 'nextRecurring', 'notes', 'priceUnit', 'startDate', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'vatUnit', 'discount', 'name', 'notificationDetails', 'paymentDetails', 'products', 'productSnapshots', 'consumer360Flg', 'recurringDetails', 'jsonbPaymentDetails', 'jsonbRecurringDetails', 'csrBankAccount', 'mcBankAccount'];

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new CsrContract();
  }

  // ---------------------------------------------------------------------

  fldCsrContractActions() {
    if (!this._fldCsrContractActions) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      this.idWebfileDdApproval > 0 && actSet.addActionB(`<i class="fas mc-submit-color fa-file-invoice mc-cursor-pointer"></i> ${this.t('cc.consumer.download-direct-debit-approval')}`, CsrContract.CSR_CONTRACT_DIRECT_DEBIT_APPROVAL_DOWNLOAD, this, [McGod.PERM_MC_CONSUMER_VIEW], '', ActionOptions.OPTION_VIEW);
      this._fldCsrContractActions = html;
    }
    return this._fldCsrContractActions.items;
  }

  fldStartDate() {
    return McDateUtils.newFormatDateString(this.startDate);
  }

  fldEndDate(): string {
    return this.endDate ? McDateUtils.newFormatDateString(this.endDate) : '-';
  }

  getPaymentMethod(): string {
    const data = JSON.parse(this.jsonbPaymentDetails);
    const paymentTypeKey = this.findTrueKey(data);

    switch (paymentTypeKey) {
      case 'typeCdDIRECT_DEBIT':
        return CsrContract.PAYMENT_METHOD_DIRECT_DEBIT;
      case 'typeCdTRANSFER':
        return CsrContract.PAYMENT_METHOD_MONEY_TRANSFER;
      default:
        return paymentTypeKey || '-';
    }
  }

  private findTrueKey(data: any): string | null {
    for (const key in data) {
      if (data[key] === true) {
        return key;
      }
    }
    return null;
  }

  getStatusCd() {
    if (this.statusCd === CsrContract.STATUS_CD_PENDING) {
      return `<div class='icon-text-table-cell'>${ICON_STATUS_WAITING_FOR_APPROVAL} Pending</div>`
    }
    if (this.statusCd === CsrContract.STATUS_CD_WAITING_FOR_APPROVAL) {
      return `<div class='icon-text-table-cell'>${ICON_STATUS_WAITING_FOR_APPROVAL} Waiting for approval</div>`
    }
    if (this.statusCd === CsrContract.STATUS_CD_ACTIVE) {
      return `<div class='icon-text-table-cell'>${ICON_STATUS_ACTIVE} Active</div>`
    }
    if (this.statusCd === CsrContract.STATUS_CD_INACTIVE) {
      return `<div class='icon-text-table-cell'>${ICON_STATUS_INACTIVE} Inactive</div>`
    }
    if (this.statusCd === CsrContract.STATUS_CD_CANCELED) {
      return `<div class='icon-text-table-cell'>${ICON_STATUS_INACTIVE} Canceled</div>`
    }
    return '-';
  }
}
