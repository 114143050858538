import {Component, Inject, OnInit} from '@angular/core';
import {McProduct, CurrencyUtil} from '@miticon-ui/mc-core';
import { ICON_CLOSE } from '../../../../assets/media/svg_icons/icon-close';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mc-product-product-profile-part',
  templateUrl: './mc-product-product-profile-part.component.html',
  styleUrls: ['./mc-product-product-profile-part.component.scss']
})
export class McProductProductProfilePartComponent implements OnInit {

  iconClose = ICON_CLOSE;

  constructor(
    @Inject(MAT_DIALOG_DATA) public mcProduct: McProduct,
    public dialogRef: MatDialogRef<McProductProductProfilePartComponent>
    ) {}

  ngOnInit() {}

  format(value: number): string {
    if (value) {
      if(this.mcProduct.pricingTypeCd === McProduct.PRICING_TYPE_CD_VALUE){
        return CurrencyUtil.format(value, this.mcProduct.mcCurrency.code);
      }
      else {
        return value + ' %'
      }
    }
    return CurrencyUtil.format(0, this.mcProduct.mcCurrency.code);
  }
}


