<div class="content-wrapper">

  <div class="content-header">
    <h1>Action History for {{csrConsumer.isTypeCdPERSON() ? csrConsumer.fldFullName() : csrConsumer.companyName}} </h1>
  </div>

  <div class="content-body">

    <lib-mc-loader [showLoader]="mcConsumerActionList.apiLoadingFlg"></lib-mc-loader>

    <div class="row">
      <!--<div class="mc-filter">
        <mcc-fi-textbox label="{{'cc.common.search' | translate}}" name="txbSearchTerm"
                        (eventEnterClicked)="actLoad()"
                        [mcForm]="mcForm"></mcc-fi-textbox>
        &lt;!&ndash;        <mcc-fi-select [mcForm]="mcForm" name="selFlowLevel" label="Flow level" [optionsVll]="mcFlowLevelVll"&ndash;&gt;
        &lt;!&ndash;                       placeholderOption="{{'cc.my-factoring.all' | translate}}"></mcc-fi-select>&ndash;&gt;
        &lt;!&ndash;        <mcc-fi-select [mcForm]="mcForm" name="selStatus" label="Status" [optionsVll]="mcStatusVll"&ndash;&gt;
        &lt;!&ndash;                       placeholderOption="{{'cc.my-factoring.all' | translate}}"></mcc-fi-select>&ndash;&gt;

        <button class="mc-link mc-filter-clear-button filter-clear-top-right"
                (click)="actClearFilter()">{{'cc.common.filter.clear-all' | translate}}</button>
        <button class="mc-button mc-filter-button"
                (click)="actLoad()">{{'cc.common.view.filter' | translate}}</button>
      </div>-->

      <mcc-message [errorMsg]="mcConsumerActionList.apiErrorMessage"></mcc-message>

      <div class="col-md-12" *ngIf="mcConsumerActionList.apiSuccessFlg">
        <mcc-fi-table-3 [objectList]="mcConsumerActionList"
                        [table3Config]="mcConsumerActionHistoryTable3Config"
                        (eventItemAction)="onTableItemAction($event)"></mcc-fi-table-3>
      </div>

    </div>

    <mcc-modal1-wrapper modalSizeCd="small" *ngIf="showActionDetailsMcb.value" [showMcb]="showActionDetailsMcb" (eventCanceled)="actCancelActionDetailsMcb()">
      <app-shared-processes-action-detail-part [mcConsumerAction]="mcConsumerAction" [mcActionCd]="mcActionCd" (eventCanceled)="actCancelActionDetailsMcb()"></app-shared-processes-action-detail-part>
    </mcc-modal1-wrapper>

    <mcc-modal1-wrapper modalSizeCd="full" *ngIf="showActionResendMcb.value" [showMcb]="showActionResendMcb" (eventCanceled)="actCancelActionResendMcb()">
      <app-shared-processes-action-resend-part [mcConsumerAction]="mcConsumerAction" (eventCanceled)="actCancelActionResendMcb()"></app-shared-processes-action-resend-part>
    </mcc-modal1-wrapper>

  </div>


  <div class="content-footer">
    <button class="btn btn-secondary move-right" (click)="actClose()">Close</button>
  </div>

</div>
