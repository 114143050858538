<ng-container class="dialog" xmlns="http://www.w3.org/1999/html">
  <div class="dialog_header">
    <span *ngIf="currentProduct" class="dialog_header_title text-uppercase">
      {{ "cc.product.view.edit-product" | translate }}
    </span>
    <span *ngIf="!currentProduct" class="dialog_header_title text-uppercase">
      {{ "cc.product.view.create-new-product" | translate }}
    </span>
    <span class="dialog_header_icon"
          [innerHTML]="iconClose | safeHtml"
            (click)="dialogRef.close(false)">
    </span>
  </div>

  <mat-dialog-content>
    <form [formGroup]="form" (keyup.enter)="actSave()">

      <div class="radio_buttons">
        <div class="radio_buttons_item">
          <h3>{{ 'cc.common.view.status' | translate }}*</h3>
          <div>
            <mat-radio-group formControlName="status">
              <mat-radio-button value="active">{{'cc.common.view.active' | translate}}</mat-radio-button>
              <mat-radio-button value="inactive">{{'cc.common.view.inactive' | translate}}</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="form_fields">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'cc.common.edit.external-id' | translate }}</mat-label>
          <input type="text"
                 formControlName="externalId"
                 matInput/>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'cc.common.name' | translate }}</mat-label>
          <input type="text"
                 formControlName="name"
                 matInput/>
        </mat-form-field>
      </div>

      <div class="form_description">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'cc.common.view.description' | translate }}</mat-label>
          <input type="text"
                 formControlName="description"
                 matInput/>
        </mat-form-field>
      </div>

      <div class="form_fields" *ngIf="referenceEntitiesVll.items.length > 0">

        <mat-form-field appearance="outline">
          <mat-label>{{ 'cc.common.ref-type' | translate }}</mat-label>
          <mat-select panelClass="dialog-select"
                      formControlName="refType"
                      (selectionChange)="onRefTypeChange($event.value)"
                      disableOptionCentering>
            <mat-option *ngFor="let ref of refTypeVll.items"
                        [value]="ref.value">
              {{ ref.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{ 'cc.common.ref-id' | translate }}</mat-label>
          <mat-select panelClass="dialog-select"
                      formControlName="refId"
                      disableOptionCentering>
            <mat-option *ngFor="let ref of referenceEntitiesVll.items"
                        [value]="ref.value">
              {{ ref.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form_fields">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'cc.product.view.unit-cost' | translate }}</mat-label>
          <input type="number"
                 formControlName="unitCost"
                 matInput/>
        </mat-form-field>
      </div>

      <div class="form_fields">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'pp.product.pricing-type' | translate }}</mat-label>
          <mat-select panelClass="dialog-select"
                      formControlName="pricingType"
                      disableOptionCentering>
            <mat-option *ngFor="let type of pricingTypeVll.items"
                        [value]="type.value">
              {{ type.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{ 'cc.common.currency' | translate }}</mat-label>
          <mat-select panelClass="dialog-select"
                      formControlName="currency"
                      disableOptionCentering>
            <mat-option *ngFor="let currency of currencyVll.items"
                        [value]="currency.value">
              {{ currency.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>


      <div class="form_fields">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'cc.product.view.vat' | translate }}</mat-label>
          <mat-select panelClass="dialog-select"
                      formControlName="VAT"
                      disableOptionCentering>
            <mat-option *ngFor="let vat of vatVll.items"
                        [value]="vat.value">
              {{ vat.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{ 'pp.product.charging-type' | translate }}</mat-label>
          <mat-select panelClass="dialog-select"
                      formControlName="chargingType"
                      disableOptionCentering>
            <mat-option *ngFor="let type of chargingTypeVll.items"
                        [value]="type.value">
              {{ type.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form_fields">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'cc.jobs.view.category' | translate }}</mat-label>
          <mat-select panelClass="dialog-select"
                      formControlName="category"
                      disableOptionCentering>
            <mat-option *ngFor="let category of categoriesVll.items"
                        [value]="category.value">
              {{ category.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="showServiceType">
          <mat-label>{{ 'pp.product.product-type' | translate }}</mat-label>
          <mat-select panelClass="dialog-select"
                      formControlName="serviceType"
                      disableOptionCentering>
            <mat-option *ngFor="let type of productTypeVll.items"
                        [value]="type.value">
              {{ type.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>

  <div class='dialog_footer'>
    <button mat-button
            class="secondary-btn"
            (click)="dialogRef.close(false)"> {{'cc.common.edit.cancel' | translate}}</button>
    <button mat-button
            class="primary-btn"
            type="submit"
            (click)="actSave()"
            [disabled]="form.invalid"> {{'cc.common.view.save' | translate}}</button>
  </div>

</ng-container>

