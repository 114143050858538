import { NgModule } from '@angular/core';
import { McAccountInfoComponent } from './pages/mc-account-info/mc-account-info.component';
import {McChangePasswordModalComponent} from './pages/mc-account-info/components/mc-change-password-modal/mc-change-password-modal.component';
import { McTwoFaAccountAuthenticationModalComponent } from './pages/mc-account-info/components/mc-two-fa-account-authentication-modal/mc-two-fa-account-authentication-modal.component';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {McPersonalInfoComponent} from './pages/mc-personal-info/mc-personal-info.component';
import {McEditPersonalInfoModalComponent} from './pages/mc-personal-info/components/mc-edit-personal-info-modal/mc-edit-personal-info-modal.component';
import {McComponentsModule} from '@miticon-ui/mc-components';
import {McProfileUserRoutingModule} from './mc-profile-user-routing.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
    McAccountInfoComponent,
    McPersonalInfoComponent,
    McChangePasswordModalComponent,
    McTwoFaAccountAuthenticationModalComponent,
    McEditPersonalInfoModalComponent
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    McComponentsModule,
    McProfileUserRoutingModule,
    TranslateModule
  ],
  exports: [McAccountInfoComponent, McPersonalInfoComponent, McProfileUserRoutingModule, McTwoFaAccountAuthenticationModalComponent,
    McChangePasswordModalComponent,
    McEditPersonalInfoModalComponent]
})
export class McProfileUserModule { }
