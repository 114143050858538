import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  BaseDirective,
  ChargingTypeCds,
  CsrConsumerService,
  McCategoryService,
  McCountry,
  McCurrencyService,
  McEntity2,
  McGod,
  McProduct,
  McProductService,
  McValueLabelList,
  McVatList,
  ServiceTypeCds,
  SortCriteriaList
} from '@miticon-ui/mc-core';
import {takeUntil} from "rxjs/operators";
import { ICON_CLOSE } from '../../../../assets/media/svg_icons/icon-close';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mc-product-add-edit-product',
  templateUrl: './mc-product-add-edit-product.component.html',
  styleUrls: ['./mc-product-add-edit-product.component.scss']
})


export class McProductAddEditProductComponent extends BaseDirective implements OnInit, OnDestroy {

  statusVll = new McValueLabelList();
  categoriesVll = new McValueLabelList();
  vatVll = new McValueLabelList();
  currencyVll = new McValueLabelList();
  refTypeVll = new McValueLabelList();
  chargingTypeVll = new McValueLabelList();
  productTypeVll = new McValueLabelList();
  refTypeCd: string;
  categories: any;
  loggedEntity = new McEntity2();
  country = new McCountry();
  vatList = new McVatList();
  product = new McProduct();
  currency!: string;
  pricingTypeVll = new McValueLabelList();
  referenceEntitiesVll = new McValueLabelList();
  mcProductService = new McProductService();
  iconClose= ICON_CLOSE;
  form!: FormGroup;
  currentProduct!: McProduct;
  showServiceType= false;

  constructor(@Inject(MAT_DIALOG_DATA)
              private data: {currentProduct: McProduct},
              public dialogRef: MatDialogRef<McProductAddEditProductComponent>,
              private mcCurrencyService: McCurrencyService,
              private mcCategoryService: McCategoryService,
              private csrConsumerService: CsrConsumerService,
              private fb: FormBuilder,
              private toastr: ToastrService,
              private tS: TranslateService) {
    super();
  }



  ngOnInit() {
    this.loadLists();
    this.createForm();
    this.currentProduct = this.data.currentProduct;
    if (this.currentProduct) {
      this.loadProduct();
    }
    this.form.get('chargingType')?.valueChanges.subscribe(value => {
      this.checkServiceType(value);
    });
  }

  createForm(): void {
    this.form = this.fb.group({
      status: ['', Validators.required],
      name: ['', Validators.required],
      externalId: [''],
      description: ['', Validators.required],
      unitCost: ['', Validators.required],
      pricingType: ['', Validators.required],
      currency: ['', Validators.required],
      VAT: ['', Validators.required],
      category: ['', Validators.required],
      chargingType: ['', Validators.required],
      serviceType: [''],
      refType: [''],
      refId: ['']
    });
  }

  loadProduct(){
    this.form.patchValue({
      status: this.currentProduct.state,
      name: this.currentProduct.name,
      externalId: this.currentProduct?.externalId,
      description: this.currentProduct?.description,
      unitCost: this.currentProduct?.unitPrice,
      pricingType: this.currentProduct?.pricingTypeCd,
      VAT: this.currentProduct?.mcVat?.id,
      category: this.currentProduct?.ppCategory?.id,
      currency: this.currentProduct?.mcCurrency.id,
      chargingType: this.currentProduct?.chargingTypeCd,
      serviceType: this.currentProduct?.serviceTypeCd,
      refType: this.currentProduct?.refTypeCd,
      refId: this.currentProduct?.refId
    });
    if (this.currentProduct.serviceTypeCd) {
      this.showServiceType = true;
    }
  }

  checkServiceType(chargingTypeValue: string) {
    const serviceTypeControl = this.form.get('serviceType');
    serviceTypeControl?.reset();
    if (chargingTypeValue === McProduct.CHARGING_TYPE_CD_CONTINUOUS) {
      serviceTypeControl?.setValidators([Validators.required]);
      this.showServiceType = true;
    } else {
      serviceTypeControl?.clearValidators();
      this.showServiceType = false;
    }
    serviceTypeControl?.updateValueAndValidity();
  }

  loadLists(){
    this.chargingTypeVll.items = ChargingTypeCds;
    this.productTypeVll.items =  ServiceTypeCds;
    this.statusVll.add('ACTIVE', this.tS.instant('cc.common.view.active'));
    this.statusVll.add('INACTIVE', this.tS.instant('cc.common.view.inactive'));
    this.pricingTypeVll = McProduct.getPricingTypeCdVll();
    this.refTypeVll = McProduct.getRefTypeCdVll();
    this.referenceEntitiesVll = this.getReferenceEntitiesVll(this.product.refTypeCd);
    this.getCurrencyList();
    this.getVatList();
    this.getCategoryList();
  }

  getCurrencyList(){
    this.mcCurrencyService.getAll(0, 50, new SortCriteriaList())
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        response.content.forEach((item: any) => {
          this.currencyVll.add(item.id , item.code);
        });
      });
  }

  getCategoryList(){
    this.mcCategoryService.getAll(0, 100, new SortCriteriaList()).subscribe(
      (res) => {
        res.content.forEach((category: any) => this.categoriesVll.add(category.id, category.name));
      }
    );
  }

  getVatList() {
    this.loggedEntity.loadById(McGod.getLoggedEntityIdFromToken(), () => {
      this.country.loadById(this.loggedEntity.countryId);
      this.vatList.loadByCountryId(this.loggedEntity.countryId, () => {
        const activeVatsList = new McVatList();
        activeVatsList.items = this.vatList.items;
        this.vatVll = activeVatsList.toVll('id', 'getVatLabelForVll()');
      }, () => {
        this.toastr.error(this.vatList.apiErrorMessage);
      });
    });
  }

  actSave() {
      if(this.form.valid){
        this.product.id = this.currentProduct ? this.currentProduct.id : 0;
        this.product.externalId = this.form.get('externalId')?.value || '';
        this.product.state = this.form.get('status')?.value || null;
        this.product.description = this.form.get('description')?.value || null;
        this.product.idCurrency = this.form.get('currency')?.value || null;
        this.product.idMcEntity = McGod.getLoggedEntityIdFromToken();
        this.product.idPpCategory = this.form.get('category')?.value || null;
        this.product.idVat = Number(this.form.get('VAT')?.value) || 0;
        this.product.name = this.form.get('name')?.value || null;
        this.product.unitPrice = Number(this.form.get('unitCost')?.value) || 0;
        this.product.version = 0;
        this.product.chargingTypeCd = this.form.get('chargingType')?.value || null;
        this.product.pricingTypeCd = this.form.get('pricingType')?.value || null;
        this.product.refId = this.form.get('refId')?.value || null;
        this.product.refTypeCd = this.form.get('refType')?.value || null;
        this.product.serviceTypeCd = this.form.get('serviceType')?.value || null;

        if(this.currentProduct){
          this.mcProductService.update(this.product).subscribe(
            (res) => {
              if (res){
                this.dialogRef.close(true);
                this.toastr.success(this.tS.instant('cc.product.the-product-changes-saved-successfully'));
              }
            }, (error) => {
              this.toastr.error(error.error.message);
            }
          );
        }
        else{
          this.mcProductService.insert(this.product).subscribe((res) => {
              if(res){
                this.dialogRef.close(true);
                this.toastr.success(this.tS.instant('cc.product.the-product-saved-successfully'));
              }
            }, (error) => {
              this.toastr.error(error.error.message);
            }
          );
        }
    }
  }

  onRefTypeChange(value: string){
    this.referenceEntitiesVll = this.getReferenceEntitiesVll(value)
    this.product.refTypeCd = value;
  }

  getReferenceEntitiesVll(value: string): McValueLabelList {
    let entities =  this.csrConsumerService.getReferenceEntities(McGod.getLoggedEntityIdFromToken(), value === McProduct.REF_TYPE_CD_CSR_CONSUMER);
    let referenceEntitiesVll = new McValueLabelList();
    entities.subscribe(
      (res) => {
        res.forEach((item: any) => {
          referenceEntitiesVll.add(item.idRef, item.entityName);
        });
      }
    )
    return referenceEntitiesVll;
  }

}
