<ng-container class="dialog">
  <div class="dialog_header">
     <span class="dialog_header_title text-uppercase">
      {{ "cc.consumer.product" | translate}}: {{mcProduct.name}}
    </span>
    <span class="dialog_header_icon"
          [innerHTML]="iconClose | safeHtml"
          (click)="dialogRef.close()"
          ></span>
  </div>

  <mat-dialog-content>
    <div class='card'>
      <div class="items info">
        <div class="items_info">
          <div class='card_title'>{{ "cc.consumer.overview.status-information" | translate }}</div>
        </div>
      </div>
      <div class="dialog-row-wrapper">
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.common.status" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ mcProduct.state | titlecase }}</span>
        </div>
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.common.edit.external-id" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ mcProduct.externalId ? mcProduct.externalId : '-'}}</span>
        </div>
      </div>
    </div>

    <div class='card'>
      <div class="items info">
        <div class="items_info">
          <div class='card_title'>{{ "cc.common.basic-information" | translate }}</div>
        </div>
      </div>
      <div class="dialog-row-wrapper">
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.common.name" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ mcProduct.name | titlecase }}</span>
        </div>
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.common.view.description" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ mcProduct.description ? mcProduct.description : '-' }}</span>
        </div>
      </div>
      <div class="dialog-row-wrapper">
      <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.product.view.unit-cost" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ format(mcProduct.unitPrice) }}</span>
        </div>
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.countries.vat-rate" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ mcProduct.mcVat.vatRate.toFixed(2).replace('.', ',') + '%' }}</span>
        </div>
      </div>
    </div>

    <div class='card'>
      <div class="items info">
        <div class="items_info">
          <div class='card_title'>{{ "cc.consumer.overview.additional-information" | translate }}</div>
        </div>
      </div>
      <div class="dialog-row-wrapper">

        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "pp.product.charging-type" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ mcProduct.getChargingType() }}</span>
        </div>
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "pp.product.product-type" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ mcProduct.getProductType() }}</span>
        </div>
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.jobs.view.category" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ mcProduct.fldCategory() }}</span>
        </div>
      </div>
    </div>

  </mat-dialog-content>

  <div class='dialog_footer'>
    <button mat-button
            class="secondary-btn"
            (click)="dialogRef.close()"
            >
      {{ "cc.common.close" | translate }}
    </button>
  </div>
</ng-container>

