<div class='dialog-wrapper'>
  <div class="dialog">
    <div class="dialog_header">
      <div class='dialog-icon'>
        <span [innerHTML]="iconInfo | safeHtml"></span>
      </div>
      <span class="title-medium-dark">
        {{ data.header | translate }}
      </span>
      <span class="dialog_header_icon"
            [innerHTML]="iconClose | safeHtml"
            (click)="dialogRef.close(false)">
      </span>
    </div>

    <div class="dialog_content">{{ data.description | translate }}</div>

    <div class='dialog-footer'>
      <button mat-button class="secondary-btn"
              (click)="dialogRef.close(false)">
        {{ data.cancelBtnLabel | translate }}
      </button>
      <button mat-button class="primary-btn"
              (click)="dialogRef.close(true)">
        {{ data.confirmBtnLabel | translate }}
      </button>
    </div>
  </div>
</div>
