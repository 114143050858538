import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  McEntityService,
  McUtilityService,
  EbFactoringService,
  McBoolean,
  McEntity2, McGod, McCountryList, McForm, McEntity2Service,
} from "@miticon-ui/mc-core";
import {ActivatedRoute, Router} from '@angular/router';
import {NgbNav} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'mc-entity-profile',
  templateUrl: './mc-entity-profile.component.html',
})
export class McEntityProfileComponent implements OnInit, OnDestroy {
  selectedEntityPath!: any;
  selectedEntity!: any;
  entityObject!: any;
  passwordPolicyObject!: any;
  passPolicyMessage!: string;
  passPolicyType!: string;
  entityMessage!: string;
  entityType!: string;
  customAttributeList!: any;
  inheritedAttributeList: any[] = [];
  customAttributesMessage!: string;
  customAttributesType!: string;
  parentEntityId!: any;
  currentEntityInheritedAttributes!: any;
  showLoader!: boolean;
  currentEntityPath!: any;
  attributesIcons;
  attributesInfoIcon;
  attributesWhiteInfoIcon;
  hoveredIcon!: any;
  bankAccount!: any;
  impressum!: any;

  entityId: any;
  editEntityId: any;
  entityName: any;
  isLoggedEntity: any;
  userId: any;
  addedUserStatus!: string;
  fixedConsumerHistoryStatus!: string;
  fixedTransactionsStatus!: string;
  paramSubscription!: Subscription;
  pageTabSubscription!: Subscription;
  mcForm = new McForm();

  selectedTab!: string;
  @ViewChild('nav') nav!: NgbNav;

  active = 1;


  imgUrl: any;
  message!: string;
  type!: string;
  responseMessage!: boolean;
  showPauseDaysMcb = new McBoolean();
  showTransactionDescriptionMcb = new McBoolean();
  maximalPauseDays!: number;
  mcEntity2 = new McEntity2(); // Old api does not return all values, to avoid refactoring now
  mcGod = McGod.getInstance();
  mcCountryList = new McCountryList();
  countryName: string | undefined;
  isCreate: boolean;
  transactionDescription: string;
  entityApiKey: string;

  /*Constructor*/
  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private utilityService: McUtilityService,
              private entityService: McEntityService,
              private mcEntityService: McEntity2Service,
              private factoringService: EbFactoringService,
              private toastr: ToastrService,
              @Inject('designConfig') design: any) {

    this.getSelectedEntityPath();

    this.utilityService.isLoggedEntity();
    // import attribute icons
    this.attributesIcons = design.attributesIcons;
    this.attributesInfoIcon = this.attributesIcons.info.path;
    this.attributesWhiteInfoIcon = this.attributesIcons.whiteInfo.path;
    this.userId = this.utilityService.getPropertyFromToken('user_id');
  }

  /*On init class*/
  ngOnInit() {
    /*Set unique Id for current entity*/

    this.paramSubscription = this.activatedRoute.paramMap.subscribe(data => {
      if (data.get('id')) {
        this.entityId = data.get('id');
        this.editEntityId = data.get('id');
        // disable payment info for logged entity

        this.isLoggedEntity = data.get('id') !== this.utilityService.getPropertyFromToken('entity_id').toString();
      } else {
        this.entityId = this.utilityService.getPropertyFromToken('entity_id');
        this.isLoggedEntity = false;
      }
    });
    // set active tab
    this.pageTabSubscription = this.activatedRoute.queryParams.subscribe(params => {
      this.selectedTab = params['activeTab'];
    });

    this.getEntityFromDb().then(() => this.getCustomAttributes());
    this.getPasswordPolicyFromDb();
    this.getParentEntityData();
    this.mcEntity2.loadById(this.entityId, () => {
      this.maximalPauseDays = this.mcEntity2.maxTransactionPauseDays;
      this.transactionDescription = this.mcEntity2.transactionDescriptionPrefix
      this.parentEntityId = this.mcEntity2.id;
      this.transactionDescription ? this.isCreate = false : this.isCreate = true;
    });

    this.getEntityKey();

  }

  getEntityKey() {
    this.mcEntityService.getApiKey()
      .subscribe((res) => {
        this.entityApiKey = res.api_key;
        this.mcForm.getControl('ctrlEntityKey')?.get('value')?.setValue(res.api_key);
      });
  }

  /*Get selected entity path*/
  private getSelectedEntityPath() {
    this.showLoader = true;
    this.entityService._selectedEntityPath.subscribe(response => {
      this.selectedEntityPath = response;
      this.showLoader = false;
    }, error => {
      this.showLoader = false;
      if (error.status >= 500) {
        this.entityMessage = 'Server is not available at the moment, please try again later.';
        this.entityType = 'error';
        return;
      }
      this.entityMessage = error.error ? error.error.message : 'There is no basic info defined';
      this.entityType = 'error';
    });
  }

  openSpecificTab(tab: string) {
    this.nav.select(tab);
  }

  /*Get entity from DB*/
  private getEntityFromDb() {
    this.showLoader = true;
    return new Promise((resolve, reject) => {
      if (this.entityId) {
        this.entityService.getMcEntityById(this.entityId).subscribe(
          (response: any) => {
            this.entityObject = response;

            // COUNTRY NAME
            this.mcCountryList.loadAll(() => {
              if (this.entityObject.countryId) {
                this.countryName = this.mcCountryList.items?.find(item => item.id === this.entityObject.countryId)?.name;
              }
            });

            this.entityName = response.name;
            this.bankAccount = this.entityObject.bankAccount ? this.entityObject.bankAccount.toString().replace(/\n/g, '<br>') : null;
            this.impressum = this.entityObject.impressum ? this.entityObject.impressum.toString().replace(/\n/g, '<br>') : null;
            this.parentEntityId = response.parentMcEntityId;
            this.imgUrl = response.logo;

            if (this.selectedTab) {
              this.openSpecificTab(this.selectedTab);
            }

            resolve(''); // Resolving promise after the data is processed
            this.showLoader = false;
          },
          (error) => {
            this.showLoader = false;
            if (error.status >= 500) {
              this.entityMessage = 'Server is not available at the moment, please try again later.';
              this.entityType = 'error';
            } else {
              this.entityMessage = error.error ? error.error.message : 'There is no basic info defined';
              this.entityType = 'error';
            }
            reject(error); // Rejecting promise with error
          }
        );
      } else {
        reject(new Error('No entityId provided')); // Rejecting if entityId is not provided
      }
    });
  }


  /*Get custom attributes*/
  public getCustomAttributes() {
    this.showLoader = true;
    if (this.editEntityId) {
      // get attributes for current entity
      this.entityService.getEntityAttributesById(this.editEntityId).subscribe(responseCurrent => {
        this.inheritedAttributeList = [];
        this.customAttributeList = responseCurrent.filter((item: any) => item.inherited === false);
        this.currentEntityInheritedAttributes = responseCurrent.filter((item: any) => item.inherited === true).map((attribute: any) => attribute.name);
        // check if parentEntityId is exist
        if (this.parentEntityId) {
          let entityName;
          // get parent attributes for current entity
          this.entityService.getEntityAttributesById(this.parentEntityId).subscribe(response => {
            if (this.currentEntityInheritedAttributes && this.currentEntityInheritedAttributes.length > 0) {
              // eslint-disable-next-line @typescript-eslint/prefer-for-of
              for (let i = 0; i < response.length; i += 1) {
                entityName = response[i].name;
                if (this.currentEntityInheritedAttributes.includes(entityName)) {
                  response[i].select = true;
                  this.inheritedAttributeList.push(response[i]);
                } else {
                  response[i].select = false;
                  this.inheritedAttributeList.push(response[i]);
                }
              }
            } else {
              response.filter((item: any) => item.inheritable === true).filter((attribute: any) => {
                attribute.select = false;
                this.inheritedAttributeList.push(attribute);
              });
            }
            this.showLoader = false;
          }, error => {
            this.showLoader = false;
            if (error.status >= 500) {
              this.customAttributesMessage = 'Server is not available at the moment, please try again later.';
              this.customAttributesType = 'error';
              return;
            }
            this.customAttributesMessage = error.error ? error.error.message : 'There is no custom attributes defined';
            this.customAttributesType = 'error';
          });
        }
        this.showLoader = false;
      }, error => {
        this.showLoader = false;
        if (error.status >= 500) {
          this.customAttributesMessage = 'Server is not available at the moment, please try again later.';
          this.customAttributesType = 'error';
          return;
        }
        this.customAttributesMessage = error.error ? error.error.message : 'There is no custom attributes defined';
        this.customAttributesType = 'error';
      });
    } else {
      // get attributes for current entity
      this.entityService.getEntityAttributes().subscribe(responseCurrent => {
        this.inheritedAttributeList = [];
        this.customAttributeList = responseCurrent.filter((item: any) => item.inherited === false);
        this.currentEntityInheritedAttributes = responseCurrent.filter((item: any) => item.inherited === true).map((attribute: any) => attribute.name);
        // check if parentEntityId is exist
        if (this.parentEntityId) {
          let entityName;
          // get parent attributes
          this.entityService.getParentAttributes().subscribe(response => {
            if (this.currentEntityInheritedAttributes && this.currentEntityInheritedAttributes.length > 0) {
              // eslint-disable-next-line @typescript-eslint/prefer-for-of
              for (let i = 0; i < response.length; i += 1) {
                entityName = response[i].name;
                if (this.currentEntityInheritedAttributes.includes(entityName)) {
                  response[i].select = true;
                  this.inheritedAttributeList.push(response[i]);
                } else {
                  response[i].select = false;
                  this.inheritedAttributeList.push(response[i]);
                }
              }
            } else {
              response.filter((item: any) => item.inheritable === true).filter((attribute:any) => {
                attribute.select = false;
                this.inheritedAttributeList.push(attribute);
              });
            }
            this.showLoader = false;
          }, error => {
            this.showLoader = false;
            if (error.status >= 500) {
              this.customAttributesMessage = 'Server is not available at the moment, please try again later.';
              this.customAttributesType = 'error';
              return;
            }
            this.customAttributesMessage = error.error ? error.error.message : 'There is no custom attributes defined';
            this.customAttributesType = 'error';
          });
        }
        this.showLoader = false;
      }, error => {
        this.showLoader = false;
        if (error.status >= 500) {
          this.customAttributesMessage = 'Server is not available at the moment, please try again later.';
          this.customAttributesType = 'error';
          return;
        }
        this.customAttributesMessage = error.error ? error.error.message : 'There is no custom attributes defined';
        this.customAttributesType = 'error';
      });
    }
  }

  /*Get password policy*/
  private getPasswordPolicyFromDb() {
    this.showLoader = true;
    if (this.editEntityId) {
      this.entityService.getEntityPasswordPolicyData(this.editEntityId)
        .subscribe(response => {
          this.passwordPolicyObject = response;
          this.showLoader = false;
        }, error => {
          this.showLoader = false;
          if (error.status >= 500) {
            this.passPolicyMessage = 'Server is not available at the moment, please try again later.';
            this.passPolicyType = 'error';
            return;
          }
          this.passPolicyMessage = error.error ? error.error.message : 'There is no password policy defined';
          this.passPolicyType = 'error';
        });
    } else {
      this.entityService.getPasswordPolicyData()
        .subscribe(response => {
          this.passwordPolicyObject = response;
          this.showLoader = false;
        }, error => {
          this.showLoader = false;
          if (error.status >= 500) {
            this.passPolicyMessage = 'Server is not available at the moment, please try again later.';
            this.passPolicyType = 'error';
            return;
          }
          this.passPolicyMessage = error.error ? error.error.message : 'There is no password policy defined';
          this.passPolicyType = 'error';
        });
    }
  }

  // Get parent for current entity
  public getParentEntityData() {
    this.showLoader = true;
    if (this.editEntityId) {
      this.entityService.getParentEntity(this.editEntityId)
        .subscribe(response => {
          this.currentEntityPath = response.reverse();
          this.showLoader = false;
        }, error => {
          this.showLoader = false;
          if (error.status >= 500) {
            this.passPolicyMessage = 'Server is not available at the moment, please try again later.';
            this.passPolicyType = 'error';
            return;
          }
          this.passPolicyMessage = error.error ? error.error.message : 'There is no password policy defined';
          this.passPolicyType = 'error';
        });
    }
  }

  /*Set CSS class for entity path element*/
  public cssClassForEntityPath(index: any) {
    if (this.editEntityId) {
      return index < this.currentEntityPath.length - 1 ? 'h6' : 'h5';
    } else {
      return index < this.selectedEntityPath.length - 1 ? 'h6' : 'h5';
    }
  }

  /*Update entityPage after edit entityBasicInfo*/
  onSuccessEditBasicInfo(event: boolean) {
    if (event === true) {
      this.inheritedAttributeList = [];
      this.getEntityFromDb().then(() => this.getCustomAttributes()).then(() => {
        this.mcEntity2.loadById(this.entityId, () => {
        });
      });

    }
  }

  /*Update entityPage after edit entityAttributes*/
  onSuccessEditAttributes(event: boolean) {
    if (event === true) {
      this.inheritedAttributeList = [];
      this.getEntityFromDb().then(() => this.getCustomAttributes());
    }
  }

  /*Update entityPage after edit entityPasswordPolicy*/
  onSuccessEditPasswordPolicy(event: boolean) {
    if (event) {
      this.passwordPolicyObject = event;
    }
  }

  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }

  public onAddSuperUserWithAllPermissionsInAllEntities() {
    if (this.userId === 1 && !this.isLoggedEntity) {
      this.entityService.addSuperUserWithAllPermissionsInAllEntities().subscribe(data => {
        this.setNotificationMessage('Success', 'success');
        this.addedUserStatus = 'successfully added users.';
      }, error => {
        console.log('-- Error while adding user to all entities -->>', error);
        this.setNotificationMessage('-- Error while adding user to all entities --', 'error');
        this.addedUserStatus = 'Error while adding user to all entities.';
      });
    }
  }

  public onFixConsumerHistory() {
    if (this.userId === 1 && !this.isLoggedEntity) {
      this.entityService.fixConsumerHistory().subscribe(data => {
        this.setNotificationMessage('Success', 'success');
        this.fixedConsumerHistoryStatus = 'successfully fixed consumer history.';
      }, error => {
        console.log('-- Error while adding user to all entities -->>', error);
        this.setNotificationMessage('-- Error while adding user to all entities --', 'error');
        this.fixedConsumerHistoryStatus = 'Error while fixing consumer history.';
      });
    }
  }

  public onFixTransactions() {
    if (this.userId === 1 && !this.isLoggedEntity) {
      this.entityService.fixTransactions().subscribe(data => {
        this.setNotificationMessage('Success', 'success');
        this.fixedTransactionsStatus = 'successfully fixed transactions.';
      }, error => {
        console.log('-- Error while adding user to all entities -->>', error);
        this.setNotificationMessage('-- Error while adding user to all entities --', 'error');
        this.fixedTransactionsStatus = 'Error while fixing transactions.';
      });
    }
  }

  /*On destroy*/
  ngOnDestroy() {
    this.paramSubscription.unsubscribe();
    this.pageTabSubscription.unsubscribe();
  }

  actPauseDays() {
    this.showPauseDaysMcb.setTrue();
  }

  onPauseDaysSaved(event: number) {
    this.maximalPauseDays = event;
    this.showPauseDaysMcb.setFalse();
  }

  onPauseDaysCanceled($event: any) {
    this.showPauseDaysMcb.setFalse();
  }

  actTransactionDescription() {
    this.showTransactionDescriptionMcb.setTrue();
  }

  onTransactionDescriptionSaved(event: string) {
    this.transactionDescription = event;
    this.transactionDescription ? this.isCreate = false : this.isCreate = true;
    this.showTransactionDescriptionMcb.setFalse();
  }

  onTransactionDescriptionCanceled($event: any) {
    this.showTransactionDescriptionMcb.setFalse();
  }

  getLinkForServicesAndPricelist(entityId: number) {
    return `entity/${entityId}/pricelists/services-and-pricelist`;
  }

  generateEntityKey() {
    this.mcEntityService.generateApiKey().subscribe((res) => {
      if (this.mcForm.getControl('ctrlEntityKey')) {
        this.mcForm.getControl('ctrlEntityKey')?.get('value')?.setValue(res.api_key);
        this.entityApiKey = res.api_key;
      }
      this.toastr.success(McGod.t('cc.entity.entity-key-has-been-successfully-generated'));
    }, () => {
      this.toastr.error(McGod.t('cc.entity.entity-key-has-not-been-successfully-generated'));
    });
  }
}
