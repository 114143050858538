import {Component, Inject, OnInit} from '@angular/core';
import {
  CurrencyUtil,
  EbFactoringService,
  IEbFactoring,
  McBillingBatchAssignedToStatementList,
  McBillingItem,
  McBillingStatement,
  McBillingStatementItem,
  McBillingStatementItemList,
  McBillingStatementService,
  McBoolean,
  McDateUtils,
  McEntity2,
  McGod,
  WebFile
} from '@miticon-ui/mc-core';
import {ActivatedRoute, Router} from '@angular/router';
import * as fileSaver from 'file-saver';
import {ToastrService} from 'ngx-toastr';
import {take} from 'rxjs/operators';
import {MkFilterOutput, MkTableConfig} from "@miticon-ui/mc-components";

@Component({
  selector: 'mc-out-billing-statement-profile-page',
  templateUrl: './mc-out-billing-statement-profile-page.component.html',
})
export class McOutBillingStatementProfilePageComponent implements OnInit {

  mcBillingStatementId!: number;
  mcBillingStatement!: McBillingStatement;
  mcBillingStatementItemList = new McBillingStatementItemList();
  selectedMcBillingStatementItemList = new McBillingStatementItemList();
  mcBillingStatementItem = new McBillingStatementItem();
  mcBillingItem = new McBillingItem();
  showAggregatePartMcb = new McBoolean();
  showAggregateDeaggreagateMcb = new McBoolean();
  showRemoveFromStatementMcb = new McBoolean();
  showAddItemsMcb = new McBoolean();
  numberOfSelectedItems!: number;
  successMsg = '';
  errorMsg = '';
  amountWithVatSum = '';
  amountWitVatTotalNumber = 0;
  childEntity = new McEntity2(); // Needed only for entity address
  entityAddress = '';
  showAddCorrectionMcb = new McBoolean();
  mcBillingBatchSnapshotList = new McBillingBatchAssignedToStatementList();
  showFinalizeStatementMcb = new McBoolean();
  mcGod = McGod.getInstance();
  snapshotListFinalSum = 0;
  mcStatementDateFormatted!: string;
  shotDeleteCorrectionMcb = new McBoolean();
  buttonsDeleteCorrectionDialog = new Map<string, string>();
  webFile = new WebFile();
  webFileName!: string;
  showRenameStatementMcb = new McBoolean();
  factoring!: IEbFactoring | undefined;
  autoImportDisabled = false;

  isLoading = false;
  tableConfig = new MkTableConfig();
  mkMatMenuActionItems = [
   /* {
      title: 'Aggregate/Deaggregate',
      matIcon: 'insert_drive_file',
      actionCd: McBillingStatementItem.ACTION_AGGREGATE,
      permission: McGod.PERM_MC_BILLING_OUT_STATEMENT_ITEM_AGGREGATE
    },*/
    {
      title: McGod.t('cc.billing.remove-from-statement'),
      matIcon: 'delete',
      actionCd: McBillingStatementItem.ACTION_REMOVE_FROM_STATEMENT,
      permission: McGod.PERM_MC_BILLING_OUT_STATEMENT_ITEM_REMOVE_FROM_STATEMENT
    },
    {
      title: 'Edit correction',
      matIcon: 'edit',
      actionCd: McBillingStatementItem.ACTION_EDIT_CORRECTION,
      permission: McGod.PERM_MC_BILLING_OUT_STATEMENT_EDIT_CORRECTION
    },
    {
      title: 'Remove correction',
      matIcon: 'delete',
      actionCd: McBillingStatementItem.ACTION_DELETE_CORRECTION,
      permission: McGod.PERM_MC_BILLING_OUT_STATEMENT_DELETE_CORRECTION
    }
  ];

  // ---------------------------------------------------------------------
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private factoringService: EbFactoringService,
    private statementService: McBillingStatementService,
    private toastr: ToastrService,
    @Inject('designConfig') design: any) {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {
    // this.mcSlideActionSet.addItem('Aggregate', McBillingStatementItem.ACTION_AGGREGATE,
    //  [McGod.PERM_MC_BILLING_OUT_STATEMENT_ITEM_AGGREGATE]);
    this.route.paramMap.subscribe(params => {
      // @ts-ignore
      this.mcBillingStatementId = +params.get('id');
      this.mcBillingStatement = new McBillingStatement();
      this.actLoad(0, 100);
    });
    this.initTableConfig();

    this.buttonsDeleteCorrectionDialog.set('b btn-primary', 'Yes');
    this.buttonsDeleteCorrectionDialog.set('a btn-secondary', this.mcGod.t('cc.common.edit.cancel'));
  }

  actLoad(pageNumber: number, pageSize: number) {
    this.isLoading = true;
    this.mcBillingStatement.loadById(this.mcBillingStatementId, () => {
      this.factoring = this.factoringService
        .getParentFactoringForDateAndChildEntityId(this.mcBillingStatement.statementDate, this.mcBillingStatement.childEntityId);

      this.mcStatementDateFormatted = McDateUtils.newFormatDateString(this.mcBillingStatement.statementDate);
      let tempAmountSum = 0;
      let tempVatSum = 0;
      let tempAmountWithVatSum = 0;
      this.mcBillingBatchSnapshotList.loadByIdMcBillingStatement(this.mcBillingStatement.id, () => {
      }, () => {
        this.errorMsg = this.mcBillingBatchSnapshotList.apiErrorMessage;
      });
      this.mcBillingStatementItemList.pageNum = pageNumber;
      this.mcBillingStatementItemList.pageItemsPerPageCount = pageSize;
      this.mcBillingStatementItemList.loadAllByMcBillingStatementId(this.mcBillingStatementId, () => {
        this.isLoading = false;
        this.mcBillingStatementItemList.items.forEach(item => {
          item.currencyCode = this.mcBillingStatement.currencyCode;
          if (item.correctionTypeCd === McBillingStatementItem.CORRECTION_TYPE_CD_CREDIT) {
            tempAmountSum -= item.amount;
            tempVatSum -= item.vatForBillingStatementItem;
            tempAmountWithVatSum += -item.amount - item.vatForBillingStatementItem;
          } else {
            tempAmountSum += item.amount;
            tempVatSum += item.vatForBillingStatementItem;
            tempAmountWithVatSum += item.amount + item.vatForBillingStatementItem;
          }
        });
        this.amountWithVatSum = CurrencyUtil.format(tempAmountWithVatSum, this.factoring?.mcCurrency.code);
        this.amountWitVatTotalNumber = tempAmountWithVatSum;
      }, () => {
        this.errorMsg = this.mcBillingStatementItemList.apiErrorMessage;
      });

      if (this.mcBillingStatement.payoutTableItems) {
        this.mcBillingStatement.payoutTableItems.items.forEach(item => {
          this.snapshotListFinalSum += item.finalSum;
        });
      }

      this.childEntity.loadById(this.mcBillingStatement.childEntityId, () => {
        this.entityAddress = ` <div>${this.childEntity.studioOwner} <br> ${this.childEntity.studioOwnerAddress} <br>${this.childEntity.studioOwnerPostalCode} ${this.childEntity.studioOwnerCity} </div>`;
      }, () => {
        this.errorMsg = this.childEntity.apiErrorMessage;
      });

      if (this.mcBillingStatement.pdfWebfileId > 0) {
        this.webFile.getFileByIdAndType(this.mcBillingStatement.pdfWebfileId, WebFile.TYPE_CD_MC_BILLING_STATEMENT_PAYOUT_REPORT_PDF, () => {
          this.webFileName = this.webFile.filename;
        }, () => {
        });
      }

    }, () => {
      this.errorMsg = this.mcBillingStatement.apiErrorMessage;
    });
  }


  initTableConfig() {
    this.tableConfig.addColumnStandard(McGod.t('cc.jobs.edit.type'), 'getTypeCdLabel()', 250, 'typeCd');
    this.tableConfig.addColumnStandard(McGod.t('cc.billing.item'), 'title', 250, 'title');
    this.tableConfig.addColumnStandard(McGod.t('cc.billing.number-of-items'), 'fldNoOfItems()', 250, 'title');
    this.tableConfig.addColumnStandard(McGod.t('cc.billing.batch'), 'mcBillingBatchName', 250, '');
    this.tableConfig.addColumnStandard(McGod.t('cc.billing.period'), 'period', 250, '');
    this.tableConfig.addColumnStandard(McGod.t('cc.billing.price'), 'getPriceDescForStatementItem()', 250, '');
    this.tableConfig.addColumnStandard(McGod.t('cc.factoring.transactions.amount'), 'getAmount()', 250, 'amount');
    this.tableConfig.addColumnInnerHtml(McGod.t('cc.product.view.vat'), 'getVATString()', '');
    this.tableConfig.addColumnStandard(McGod.t('cc.billing.total-amount'), 'getTotalFormat()', 250, '');
  }

  loadBillingItemAndShowAggregatePart() {
    this.showAggregateDeaggreagateMcb.setTrue();
  }

  onAggregationCancelEmit() {
    this.showAggregatePartMcb.setFalse();
  }

  onAggregationSavedEmit() {
    this.showAggregatePartMcb.setFalse();
    if (this.mcBillingStatementItem.isTypeCdSINGLE()) {
      this.mcBillingStatementItem.delete(() => {
        this.actLoad(0, 100);
      });
    }
  }

/*  onTableSelectionChange(selectedList: McBillingStatementItemList) {
    const lastItemIsSingle = selectedList.getLastItem().isTypeCdSINGLE();
    const correctionInListFlg = selectedList.items.find(item => item.isTypeCdCORRECTION());

    if (selectedList.getCount() > 1) {
      const everyItemIsSameType = selectedList.items.every(item => lastItemIsSingle ? item.isTypeCdSINGLE() : item.isTypeCdAGGREGATE());
      this.mcSlideActionSet = new McSlideActionSet();
      if (everyItemIsSameType && lastItemIsSingle) {
        this.mcSlideActionSet.addItem('Aggregate', McBillingStatementItem.ACTION_AGGREGATE, [McGod.PERM_MC_BILLING_OUT_STATEMENT_ITEM_AGGREGATE]);
      } else if (everyItemIsSameType && !lastItemIsSingle) {
        this.mcSlideActionSet.addItem('Deaggregate', McBillingStatementItem.ACTION_DEAGGREGATE, [McGod.PERM_MC_BILLING_OUT_STATEMENT_ITEM_DEAGGREGATE]);
      } else if (correctionInListFlg) {
        this.mcSlideActionSet.addItem('You can not edit corrections.', 'ERROR', [McGod.PERM_MC_BILLING_OUT_STATEMENT_ITEM_DEAGGREGATE, McGod.PERM_MC_BILLING_OUT_STATEMENT_ITEM_AGGREGATE]);
      } else {
        this.mcSlideActionSet.addItem('You can not aggregate/deaggregate statement items of different types. ', 'ERROR', [McGod.PERM_MC_BILLING_OUT_STATEMENT_ITEM_DEAGGREGATE, McGod.PERM_MC_BILLING_OUT_STATEMENT_ITEM_AGGREGATE]);
      }
    }
    this.numberOfSelectedItems = selectedList.getCount();
    this.selectedMcBillingStatementItemList = selectedList;
    this.mcBillingStatementItem = new McBillingStatementItem();
  }*/

  onAggregateDeaggregateCancelEmit() {
    this.showAggregateDeaggreagateMcb.setFalse();
    this.mcBillingStatementItem = new McBillingStatementItem();
  }

  actShowAddItems() {
    this.showAddItemsMcb.setTrue();
  }

  goToPrevious() {
    this.router.navigate([`/entity/${McGod.getLoggedEntityIdFromToken()}/out-billing/statements`]);
  }

  onAddItemsCancelEmit() {
    this.showAddItemsMcb.setFalse();
  }

  onAddItemsSaveEmit() {
    this.actLoad(0, 100);
    this.showAddItemsMcb.setFalse();
  }

  onAggregateDeaggregateSaveEmit(aggregationFlg: boolean) {
    this.actLoad(0, 100);
    this.showAggregateDeaggreagateMcb.setFalse();
    this.successMsg = aggregationFlg ? 'Aggregation successfully done.' : 'Deaggregation successfully done.';
    this.selectedMcBillingStatementItemList = new McBillingStatementItemList();
    this.numberOfSelectedItems = 0;
    this.mcBillingStatementItem = new McBillingStatementItem();
  }

  onRemoveItemsCancelEmit() {
    this.showRemoveFromStatementMcb.setFalse();
  }

  onRemoveItemsSaveEmit() {
    this.actLoad(0, 100);
    this.showRemoveFromStatementMcb.setFalse();
    this.successMsg = 'Item successfully removed from statement.';
  }

  actShowAddCorrection() {
    this.showAddCorrectionMcb.setTrue();
  }

  onAddCorrectionCancelEmit() {
    this.showAddCorrectionMcb.setFalse();
    this.mcBillingStatementItem = new McBillingStatementItem();
  }

  onAddCorrectionSaveEmit() {
    this.actLoad(0, 100);
    this.successMsg = 'Correction successfully added.';
    this.showAddCorrectionMcb.setFalse();
    this.mcBillingStatementItem = new McBillingStatementItem();
  }

  onFinalizeStatementCancelEmit() {
    this.showFinalizeStatementMcb.setFalse();
  }

  onActionsDropdownClick(action: string) { // TODO: Fix hardcoded actions. GlobalActionsEnum.
    if (action === 'FINALIZE') {
      this.toastr.clear();
      if (!(this.mcBillingStatement.isStatusCdCOMPLETED() || this.mcBillingStatement.isStatusCdFINALIZED()) && Math.sign(this.mcBillingStatement.totalPayout) > 0) {
        this.showFinalizeStatementMcb.setTrue();
      } else {
        this.toastr.error(`${McGod.t('cc.billing.it-is-not-allowed-to-finalize-statements-that-are-negative-or-have-value-of-0')}.`, '', {timeOut: 4000, extendedTimeOut: 10});
      }
    }
    if (action === 'AUTO_IMPORT') {
      this.autoImportDisabled = true;
      this.mcBillingStatement.callCollectBillingItems(this.mcBillingStatement.id, () => {
        this.successMsg = 'Auto import successfully finished.';
        this.autoImportDisabled = false;
        this.actLoad(0, 100);
      }, () => {
        this.errorMsg = this.mcBillingStatement.apiErrorMessage;
        this.autoImportDisabled = false;
      });
    }
    if (action === 'PREVIEW_PDF') {
      if (this.mcBillingStatement.isStatusCdCOMPLETED() || this.mcBillingStatement.isStatusCdFINALIZED()) {
        this.webFile.getFileByIdAndType(this.mcBillingStatement.pdfWebfileId, WebFile.TYPE_CD_MC_BILLING_STATEMENT_PAYOUT_REPORT_PDF, () => {
          this.webFile.downloadFile().subscribe(response => {
            const blob = new Blob([response], {type: 'application/pdf'});
            const url = URL.createObjectURL(blob);
            const newWindow = window.open(url, '_blank');
            newWindow?.document.write(`<html><head><title>${this.webFile.filename}</title></head><body>
             <embed width="100%" height="100%" name="plugin" src="${url}"
            type="application/pdf" internalinstanceid="21"></body></html>`);
          });
        });
      }
    }
    if (action === 'DOWNLOAD_PDF') {
      if (this.mcBillingStatement.isStatusCdCOMPLETED() || this.mcBillingStatement.isStatusCdFINALIZED()) {
        this.webFile.getFileByIdAndType(this.mcBillingStatement.pdfWebfileId, WebFile.TYPE_CD_MC_BILLING_STATEMENT_PAYOUT_REPORT_PDF, () => {
          this.webFile.downloadFile().subscribe(response => {
            fileSaver.saveAs(response, `${this.webFile.filename}`);
          });
        });
      }
    }
    if (action === 'PREVIEW_XLS') {
      if (this.mcBillingStatement.isStatusCdCOMPLETED() || this.mcBillingStatement.isStatusCdFINALIZED()) {
        this.webFile.getFileByIdAndType(this.mcBillingStatement.xlsWebfileId, WebFile.TYPE_CD_MC_BILLING_STATEMENT_CONSUMERS_REPORT_CSV, () => {
          this.webFile.downloadFile().subscribe(response => {
            const blob = new Blob([response], {type: 'application/pdf'});
            const url = URL.createObjectURL(blob);
            const newWindow = window.open(url, '_blank');
            newWindow?.document.write(`<html><head><title>${this.webFile.filename}</title></head><body>
             <embed width="100%" height="100%" name="plugin" src="${url}"
            type="application/pdf" internalinstanceid="21"></body></html>`);
          });
        });
      }
    }
    if (action === 'DOWNLOAD_XLS') {
      if (this.mcBillingStatement.isStatusCdCOMPLETED() || this.mcBillingStatement.isStatusCdFINALIZED()) {
        this.webFile.getFileByIdAndType(this.mcBillingStatement.xlsWebfileId, WebFile.TYPE_CD_MC_BILLING_STATEMENT_CONSUMERS_REPORT_CSV, () => {
          this.webFile.downloadFile().subscribe(response => {
            fileSaver.saveAs(response, `${this.webFile.filename}`);
          });
        });
      }
    }
    if (action === 'REGENERATE_PDF_AND_XLS') {
      if (!this.webFileName) {
        this.statementService.regenerateXLSandPDF(this.mcBillingStatement.id).pipe(take(1)).subscribe(() => {
              this.webFileName = this.webFile.filename;
              this.toastr.success(McGod.t('cc.billing.pdf-and-xls-regenerated') + '.', '', {timeOut: 4000, extendedTimeOut: 4000});
              this.actLoad(0, 100);
          }, (err) => {
            this.toastr.error(err.message, '', {timeOut: 4000, extendedTimeOut: 4000});
          });
      } else {
        this.toastr.error(McGod.t('cc.billing.pdf-and-xls-files-have-already-been-created') + '.', '', {timeOut: 4000, extendedTimeOut: 4000});
      }
    }
    if (action === 'HIDE') {
      if (this.mcBillingStatement.flgHidden) {
        this.mcBillingStatement.flgHidden = false;
      } else {
        this.mcBillingStatement.flgHidden = true;
      }
      this.mcBillingStatement.save();

    }
    if (action === 'RENAME') {
      this.showRenameStatementMcb.setTrue();
    }
  }

  onMcDialogBoxDeleteCorrectionActionButtonClicked(value: string) {
    if (value === 'b btn-primary') {
      this.mcBillingStatementItem.delete(() => {
        this.successMsg = 'Correction successfully deleted.';
        this.actLoad(0, 100);
        this.shotDeleteCorrectionMcb.setFalse();
        this.mcBillingStatementItem = new McBillingStatementItem();
      }, () => {
        this.errorMsg = this.mcBillingStatementItem.apiErrorMessage;
      });
    } else {
      this.shotDeleteCorrectionMcb.setFalse();
      this.mcBillingStatementItem = new McBillingStatementItem();
    }
  }

  onFinalizeStatementSaveEmit() {
    this.showFinalizeStatementMcb.setFalse();
    this.actLoad(0, 100);
  }

  onRenameStatementCancelEmit() {
    this.showRenameStatementMcb.setFalse();
  }

  onRenameStatementSaveEmit() {
    this.successMsg = 'Statement renamed successfully.';
    this.showRenameStatementMcb.setFalse();
    this.actLoad(0, 100);
  }

  format(value: number): string {
    if (!value) {
      value = 0;
    }
    return CurrencyUtil.format(value, this.mcBillingStatement.currencyCode);
  }

  onFilterChanged(filterOutput: MkFilterOutput) {
    this.actLoad(filterOutput.pageEvent.pageIndex, filterOutput.pageEvent.pageSize);
  }

  onMkMatMenuItemsAction(event: any) {
    this.selectedMcBillingStatementItemList = new McBillingStatementItemList();
    this.mcBillingStatementItem = event.item;
    if (this.mcBillingStatement.isStatusCdCOMPLETED() || this.mcBillingStatement.isStatusCdFINALIZED()) {
      this.toastr.error('You cannot aggregate/deaggregate item, remove item from statement or delete/edit correction because statement is finalized.');
    } else {
      switch (event.action.actionCd) {
        /*case McBillingStatementItem.ACTION_AGGREGATE:
          this.loadBillingItemAndShowAggregatePart();
          break;*/
        case McBillingStatementItem.ACTION_REMOVE_FROM_STATEMENT:
          if (!event.item.isTypeCdCORRECTION()) {
            this.showRemoveFromStatementMcb.setTrue();
          } else {
            this.toastr.error('You cannot remove item from statement because it is type Correction.');
          }
          break;
        case McBillingStatementItem.ACTION_DELETE_CORRECTION:
          if (event.item.isTypeCdCORRECTION()) {
            this.shotDeleteCorrectionMcb.setTrue();
          } else {
            this.toastr.error('You cannot delete correction because Statement Item is not Type Correction');
          }
          break;
        case McBillingStatementItem.ACTION_EDIT_CORRECTION:
          if (event.item.isTypeCdCORRECTION()) {
            this.actShowAddCorrection();
          } else {
            this.toastr.error('You cannot edit correction because Statement Item is not Type Correction');
          }
      }
    }
  }

  isActionButtonDisabled(actionButton: string): boolean {
    switch (actionButton) {
      case 'AUTO_IMPORT': {
        return this.mcBillingStatement.isStatusCdCOMPLETED() || this.mcBillingStatement.isStatusCdFINALIZED() || this.autoImportDisabled;
      }
      case 'FINALIZE':
      case 'RENAME': {
        return this.mcBillingStatement.isStatusCdCOMPLETED() || this.mcBillingStatement.isStatusCdFINALIZED();
      }
      case 'PREVIEW_PDF':
      case 'DOWNLOAD_PDF':
      case 'DOWNLOAD_XLS': {
        return !(this.mcBillingStatement.isStatusCdCOMPLETED() || this.mcBillingStatement.isStatusCdFINALIZED());
      }
      default: return false;
    }
  }
}
