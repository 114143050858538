import {Component, EventEmitter, Inject, Input, OnInit, Output} from "@angular/core";
import {
  McBillingServiceCatalog, McBillingServiceCatalogList, McBillingServiceUnitType, McDateUtils,
  McEntity2List,
  McEntityService,
  McForm,
  McGod,
  McPriceList,
  McValueLabelList
} from "@miticon-ui/mc-core";

@Component({
  selector: "mc-pricelist-pricelist-prices-and-entities-part",
  templateUrl: "./mc-pricelist-pricelist-prices-and-entities-part.component.html"
})
export class McPricelistPricelistPricesAndEntitiesPartComponent implements OnInit {

  mcChildEntityList = new McEntity2List();
  loadingFlg = false;
  mcChildEntityVll!: McValueLabelList;
  entitiesLoadedFlg = false;
  loadingWebDomains = false;
  webDomains!: [];
  errorMsg = '';
  mcServiceCatalogList = new McBillingServiceCatalogList();
  validFromValue!: number;
  mcGod = McGod.getInstance();
  tomorrow: any;


  @Input() mcForm!: McForm;
  @Input() templatePriceList!: McPriceList;
  @Input() defaultTemplatePriceList!: McPriceList;
  @Input() entitiesValue!: McValueLabelList;
  @Output() eventNext = new EventEmitter();
  @Output() eventPrev = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Input() isLastStepCanceled!: boolean;
  @Output() eventSelectedEntities = new EventEmitter<McEntity2List>();
  @Input() currencyCode!: string;

  // Constructor
  constructor(private mcEntityService: McEntityService,
              @Inject("designConfig") design: any) {
  }

  /*On init*/


  ngOnInit() {
    if (this.mcForm.getControl('ctrlValidFrom')) {
      this.validFromValue = this.mcForm.getValue('ctrlValidFrom');
    }
    this.mcChildEntityVll = new McValueLabelList();
    this.mcChildEntityList.loadAllWhereParentEntityIsLoggedInEntity(() => {
      this.eventSelectedEntities.emit(this.mcChildEntityList);
      this.mcChildEntityList.items.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
      });
      this.mcChildEntityVll = this.mcChildEntityList.toVll("id", "name");
      this.entitiesLoadedFlg = true;
    });

    this.mcEntityService.getWebDomainsForEntity(McGod.getLoggedEntityIdFromToken()).subscribe(response => {
      console.log(response)
      this.webDomains = response;
      this.loadingWebDomains = true;
    }, () => {
      this.errorMsg = "API Error loading entity web domains";
    });

    this.mcServiceCatalogList.loadAll(() => {
      console.log(this.mcServiceCatalogList.items)
      this.mcServiceCatalogList.items.forEach(item => {
        if (item.flgPriceFromContract) {
        }
      });
    });
    const today = new Date();
    this.tomorrow = new Date(today);
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);

    }

  actCancel() {
    this.mcForm.formGroup.reset();
    this.eventCanceled.emit();
  }

  actNext() {
    if (this.mcForm.isValid()) {
      this.eventNext.emit();
    }
  }

  actPrev() {
    this.eventPrev.emit();
  }

  fldServiceCatalog(serviceId: number) {
    // @ts-ignore
    const catalog: McBillingServiceCatalog = this.mcServiceCatalogList.getItemById(serviceId);
    return catalog;
  }

  fldUnitType(activityUnitTypeCd: string) {
    return McBillingServiceUnitType.getTypeCdLabel(activityUnitTypeCd);
  }
}
