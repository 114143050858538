/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McBillingServiceCatalog} from '../models/mc-billing-service-catalog.model';
import {McBillingServiceCatalogService} from '../services/mc-billing-service-catalog.service';
import {IMcBillingServiceCatalog} from '../_generated/mc-billing-service-catalog.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McBillingServiceCatalogGENERATED extends BaseObject {


    public static readonly TYPE_CD_INITIAL_ENTITY_SETUP: string = 'INITIAL_ENTITY_SETUP';
    public static readonly TYPE_CD_CONTRACT_TYPE_CHANGE: string = 'CONTRACT_TYPE_CHANGE';
    public static readonly TYPE_CD_USER_ACCOUNT_FEE: string = 'USER_ACCOUNT_FEE';
    public static readonly TYPE_CD_BATCH_TRANSACTION_CREATION_FEE: string = 'BATCH_TRANSACTION_CREATION_FEE';
    public static readonly TYPE_CD_IBAN_VALIDITY_CHECK: string = 'IBAN_VALIDITY_CHECK';
    public static readonly TYPE_CD_BLACKLIST_MATCHING: string = 'BLACKLIST_MATCHING';
    public static readonly TYPE_CD_TRANSACTION_WITHDRAWAL_ATTEMPT: string = 'TRANSACTION_WITHDRAWAL_ATTEMPT';
    public static readonly TYPE_CD_MANUAL_PAYMENT_MATCHING: string = 'MANUAL_PAYMENT_MATCHING';
    public static readonly TYPE_CD_AUTOMATIC_PAYMENT_MATCHING: string = 'AUTOMATIC_PAYMENT_MATCHING';
    public static readonly TYPE_CD_TRANSACTION_RETURN_TO_CLIENT_FEE: string = 'TRANSACTION_RETURN_TO_CLIENT_FEE';
    public static readonly TYPE_CD_PDF_CREATION: string = 'PDF_CREATION';
    public static readonly TYPE_CD_SENT_EMAIL: string = 'SENT_EMAIL';
    public static readonly TYPE_CD_RESENT_EMAIL: string = 'RESENT_EMAIL';
    public static readonly TYPE_CD_PAYOUT_ITEM_AMOUNT_RETURN: string = 'PAYOUT_ITEM_AMOUNT_RETURN';
    public static readonly TYPE_CD_FRANCHISE_FEE_PER_MEMBER: string = 'FRANCHISE_FEE_PER_MEMBER';
    public static readonly TYPE_CD_FRANCHISE_FEE_PER_TRANSACTION_TURNOVER: string = 'FRANCHISE_FEE_PER_TRANSACTION_TURNOVER';
    public static readonly TYPE_CD_FRANCHISE_ADVERTISING_FEE: string = 'FRANCHISE_ADVERTISING_FEE';
    public static readonly TYPE_CD_DEBITOREN_MANAGEMENT: string = 'DEBITOREN_MANAGEMENT';
  public static readonly TYPE_CD_LIST__ALL = [
    McBillingServiceCatalogGENERATED.TYPE_CD_INITIAL_ENTITY_SETUP,
    McBillingServiceCatalogGENERATED.TYPE_CD_CONTRACT_TYPE_CHANGE,
    McBillingServiceCatalogGENERATED.TYPE_CD_USER_ACCOUNT_FEE,
    McBillingServiceCatalogGENERATED.TYPE_CD_BATCH_TRANSACTION_CREATION_FEE,
    McBillingServiceCatalogGENERATED.TYPE_CD_IBAN_VALIDITY_CHECK,
    McBillingServiceCatalogGENERATED.TYPE_CD_BLACKLIST_MATCHING,
    McBillingServiceCatalogGENERATED.TYPE_CD_TRANSACTION_WITHDRAWAL_ATTEMPT,
    McBillingServiceCatalogGENERATED.TYPE_CD_MANUAL_PAYMENT_MATCHING,
    McBillingServiceCatalogGENERATED.TYPE_CD_AUTOMATIC_PAYMENT_MATCHING,
    McBillingServiceCatalogGENERATED.TYPE_CD_TRANSACTION_RETURN_TO_CLIENT_FEE,
    McBillingServiceCatalogGENERATED.TYPE_CD_PDF_CREATION,
    McBillingServiceCatalogGENERATED.TYPE_CD_SENT_EMAIL,
    McBillingServiceCatalogGENERATED.TYPE_CD_RESENT_EMAIL,
    McBillingServiceCatalogGENERATED.TYPE_CD_PAYOUT_ITEM_AMOUNT_RETURN,
    McBillingServiceCatalogGENERATED.TYPE_CD_FRANCHISE_FEE_PER_MEMBER,
    McBillingServiceCatalogGENERATED.TYPE_CD_FRANCHISE_FEE_PER_TRANSACTION_TURNOVER,
    McBillingServiceCatalogGENERATED.TYPE_CD_FRANCHISE_ADVERTISING_FEE,
    McBillingServiceCatalogGENERATED.TYPE_CD_DEBITOREN_MANAGEMENT
];

  public apiService: McBillingServiceCatalogService;
  public _rawJson: IMcBillingServiceCatalog;
    id: number = 0;
  description = '';
  flgPriceFromContract: boolean;
  title = '';
  typeCd: string;
  activityUnitTypes: any;

  public properties: string[] = ['id', 'description', 'flgPriceFromContract', 'title', 'typeCd', 'activityUnitTypes'];
  public propertiesRegular: string[] = ['id', 'description', 'flgPriceFromContract', 'title', 'typeCd', 'activityUnitTypes'];
  public propertiesSpecial: string[] = [];



  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McBillingServiceCatalogGENERATED.TYPE_CD_INITIAL_ENTITY_SETUP, McGod.t('Initial entity setup'));
    list.add(McBillingServiceCatalogGENERATED.TYPE_CD_CONTRACT_TYPE_CHANGE, McGod.t('Contract type change'));
    list.add(McBillingServiceCatalogGENERATED.TYPE_CD_USER_ACCOUNT_FEE, McGod.t('User account fee'));
    list.add(McBillingServiceCatalogGENERATED.TYPE_CD_BATCH_TRANSACTION_CREATION_FEE, McGod.t('Batch transaction creation fee'));
    list.add(McBillingServiceCatalogGENERATED.TYPE_CD_IBAN_VALIDITY_CHECK, McGod.t('IBAN validity check'));
    list.add(McBillingServiceCatalogGENERATED.TYPE_CD_BLACKLIST_MATCHING, McGod.t('Blacklist matching'));
    list.add(McBillingServiceCatalogGENERATED.TYPE_CD_TRANSACTION_WITHDRAWAL_ATTEMPT, McGod.t('Transaction withdrawal attempt'));
    list.add(McBillingServiceCatalogGENERATED.TYPE_CD_MANUAL_PAYMENT_MATCHING, McGod.t('Manual payment matching'));
    list.add(McBillingServiceCatalogGENERATED.TYPE_CD_AUTOMATIC_PAYMENT_MATCHING, McGod.t('Automatic payment matching'));
    list.add(McBillingServiceCatalogGENERATED.TYPE_CD_TRANSACTION_RETURN_TO_CLIENT_FEE, McGod.t('Transaction return to client fee'));
    list.add(McBillingServiceCatalogGENERATED.TYPE_CD_PDF_CREATION, McGod.t('PDF creation'));
    list.add(McBillingServiceCatalogGENERATED.TYPE_CD_SENT_EMAIL, McGod.t('Sent email'));
    list.add(McBillingServiceCatalogGENERATED.TYPE_CD_RESENT_EMAIL, McGod.t('Resent email'));
    list.add(McBillingServiceCatalogGENERATED.TYPE_CD_PAYOUT_ITEM_AMOUNT_RETURN, McGod.t('Payout item amount return'));
    list.add(McBillingServiceCatalogGENERATED.TYPE_CD_FRANCHISE_FEE_PER_MEMBER, McGod.t('Monthly x EUR per member'));
    list.add(McBillingServiceCatalogGENERATED.TYPE_CD_FRANCHISE_FEE_PER_TRANSACTION_TURNOVER, McGod.t('Monthly X % per transaction turnover'));
    list.add(McBillingServiceCatalogGENERATED.TYPE_CD_FRANCHISE_ADVERTISING_FEE, McGod.t('Monthly X % per transaction turnover'));
    list.add(McBillingServiceCatalogGENERATED.TYPE_CD_DEBITOREN_MANAGEMENT, McGod.t('Monthly X € per debitoren - management'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = McBillingServiceCatalogGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McBillingServiceCatalog {
    console.error('MTCN-ERROR: Not Implemented: McBillingServiceCatalog::createNewInstance(). Add this method to final class and return new McBillingServiceCatalog();');
    throw new Error('Cannot McBillingServiceCatalog::createNewInstance(). Add this method to final class and return new McBillingServiceCatalog();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcBillingServiceCatalog): McBillingServiceCatalog {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McBillingServiceCatalogService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McBillingServiceCatalog AABB()');
    
  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getTypeCdLabel(): string {
    return McBillingServiceCatalogGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdINITIAL_ENTITY_SETUP(): boolean {
    const result = (this.typeCd === McBillingServiceCatalogGENERATED.TYPE_CD_INITIAL_ENTITY_SETUP);

    return result;
  }

 public  isTypeCdCONTRACT_TYPE_CHANGE(): boolean {
    const result = (this.typeCd === McBillingServiceCatalogGENERATED.TYPE_CD_CONTRACT_TYPE_CHANGE);

    return result;
  }

 public  isTypeCdUSER_ACCOUNT_FEE(): boolean {
    const result = (this.typeCd === McBillingServiceCatalogGENERATED.TYPE_CD_USER_ACCOUNT_FEE);

    return result;
  }

 public  isTypeCdBATCH_TRANSACTION_CREATION_FEE(): boolean {
    const result = (this.typeCd === McBillingServiceCatalogGENERATED.TYPE_CD_BATCH_TRANSACTION_CREATION_FEE);

    return result;
  }

 public  isTypeCdIBAN_VALIDITY_CHECK(): boolean {
    const result = (this.typeCd === McBillingServiceCatalogGENERATED.TYPE_CD_IBAN_VALIDITY_CHECK);

    return result;
  }

 public  isTypeCdBLACKLIST_MATCHING(): boolean {
    const result = (this.typeCd === McBillingServiceCatalogGENERATED.TYPE_CD_BLACKLIST_MATCHING);

    return result;
  }

 public  isTypeCdTRANSACTION_WITHDRAWAL_ATTEMPT(): boolean {
    const result = (this.typeCd === McBillingServiceCatalogGENERATED.TYPE_CD_TRANSACTION_WITHDRAWAL_ATTEMPT);

    return result;
  }

 public  isTypeCdMANUAL_PAYMENT_MATCHING(): boolean {
    const result = (this.typeCd === McBillingServiceCatalogGENERATED.TYPE_CD_MANUAL_PAYMENT_MATCHING);

    return result;
  }

 public  isTypeCdAUTOMATIC_PAYMENT_MATCHING(): boolean {
    const result = (this.typeCd === McBillingServiceCatalogGENERATED.TYPE_CD_AUTOMATIC_PAYMENT_MATCHING);

    return result;
  }

 public  isTypeCdTRANSACTION_RETURN_TO_CLIENT_FEE(): boolean {
    const result = (this.typeCd === McBillingServiceCatalogGENERATED.TYPE_CD_TRANSACTION_RETURN_TO_CLIENT_FEE);

    return result;
  }

 public  isTypeCdPDF_CREATION(): boolean {
    const result = (this.typeCd === McBillingServiceCatalogGENERATED.TYPE_CD_PDF_CREATION);

    return result;
  }

 public  isTypeCdSENT_EMAIL(): boolean {
    const result = (this.typeCd === McBillingServiceCatalogGENERATED.TYPE_CD_SENT_EMAIL);

    return result;
  }

 public  isTypeCdRESENT_EMAIL(): boolean {
    const result = (this.typeCd === McBillingServiceCatalogGENERATED.TYPE_CD_RESENT_EMAIL);

    return result;
  }

 public  isTypeCdPAYOUT_ITEM_AMOUNT_RETURN(): boolean {
    const result = (this.typeCd === McBillingServiceCatalogGENERATED.TYPE_CD_PAYOUT_ITEM_AMOUNT_RETURN);

    return result;
  }

 public  isTypeCdFRANCHISE_FEE_PER_MEMBER(): boolean {
    const result = (this.typeCd === McBillingServiceCatalogGENERATED.TYPE_CD_FRANCHISE_FEE_PER_MEMBER);

    return result;
  }

 public  isTypeCdFRANCHISE_FEE_PER_TRANSACTION_TURNOVER(): boolean {
    const result = (this.typeCd === McBillingServiceCatalogGENERATED.TYPE_CD_FRANCHISE_FEE_PER_TRANSACTION_TURNOVER);

    return result;
  }

 public  isTypeCdFRANCHISE_ADVERTISING_FEE(): boolean {
    const result = (this.typeCd === McBillingServiceCatalogGENERATED.TYPE_CD_FRANCHISE_ADVERTISING_FEE);

    return result;
  }

 public  isTypeCdDEBITOREN_MANAGEMENT(): boolean {
    const result = (this.typeCd === McBillingServiceCatalogGENERATED.TYPE_CD_DEBITOREN_MANAGEMENT);

    return result;
  }


  
}
