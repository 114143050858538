import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GlobalActionEnums,
  McBillingPayoutFileFeCtd,
  McBillingStatementList,
  McBillingPayoutFileItemFeCtdTable3Config,
  McBillingPayoutFileItemFeCtdList,
  McBillingPayoutTableItemFilter,
  McDateUtils,
  McGod,
  WebFile, CurrencyUtil,
} from '@miticon-ui/mc-core';
import * as fileSaver from 'file-saver';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@miticon-ui/mc-components';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'mc-out-billing-payout-file-profile',
  templateUrl: './mc-out-billing-payout-file-profile.component.html',
})
export class McOutBillingPayoutFileProfileComponent implements OnInit {
  mcBillingPayoutFileFeCtdId!: number;
  mcBillingPayoutFileFeCtd!: McBillingPayoutFileFeCtd;
  billingStatementList = new McBillingStatementList();
  mcBillingPayoutFileItemTable3Config = new McBillingPayoutFileItemFeCtdTable3Config();
  mcBillingPayoutFileItemFeCtdList = new McBillingPayoutFileItemFeCtdList();
  mcBillingPayoutTableItemFilter = new McBillingPayoutTableItemFilter();
  loadingFlg = false;
  webFile = new WebFile();
  globalActions = GlobalActionEnums;
  mcGod = McGod.getInstance();
  payoutDateString = '';
  createdDAteTimeString = '';

  // ---------------------------------------------------------------------
  constructor(private route: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog,
              private toastr: ToastrService,
              @Inject('designConfig') design: any) {}

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      // @ts-ignore
      this.mcBillingPayoutFileFeCtdId = +params.get('id');
      this.mcBillingPayoutFileFeCtd = new McBillingPayoutFileFeCtd();
      this.actLoad();
    });
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.loadingFlg = true;
    this.mcBillingPayoutFileFeCtd.loadById(
      this.mcBillingPayoutFileFeCtdId,
      () => {
        this.payoutDateString = McDateUtils.newFormatDateString(this.mcBillingPayoutFileFeCtd.payoutDate);
        this.createdDAteTimeString = McDateUtils.newFormatDateTimeString(this.mcBillingPayoutFileFeCtd.sysCreatedDatetime);
        this.loadMcBillingPayoutFileItemList();
        this.loadingFlg = false;
      },
      () => {
        this.loadingFlg = false;
        this.toastr.error(this.mcBillingPayoutFileFeCtd.apiErrorMessage);
      }
    );
  }

  loadMcBillingPayoutFileItemList() {
    this.mcBillingPayoutTableItemFilter.idMcBillingPayoutFile = this.mcBillingPayoutFileFeCtdId;
    this.mcBillingPayoutFileItemFeCtdList.loadByFilter(
      this.mcBillingPayoutTableItemFilter,
      () => {
        this.mcBillingPayoutFileItemFeCtdList.items.forEach((item) => {
          item.totalAmountFormatted = CurrencyUtil.format(item.totalPayoutAmount, this.mcBillingPayoutFileFeCtd.mcCurrency.code);
        });
      },
      () => {
        this.toastr.error(this.billingStatementList.apiErrorMessage);
      }
    );
  }

  goToPayoutFileListPage(message?: string) {
    this.router.navigateByUrl(`entity/${McGod.getLoggedEntityIdFromToken()}/out-billing/payout-files`, { state: { payoutFileDeletedMessage: message } });
  }

  onActionsDropdownClick(action: string) {
    if (action === this.globalActions.Download) {
      if (this.mcBillingPayoutFileFeCtd.statusCd !== McBillingPayoutFileFeCtd.STATUS_CD_FAILED) {
        this.webFile.getFileByIdAndType(
          this.mcBillingPayoutFileFeCtd.xlsWebfileId, WebFile.TYPE_CD_MC_BILLING_PAYOUT_EXOPORT,
          () => {
            this.webFile.downloadFile().subscribe((response) => {
              fileSaver.saveAs(response, `${this.webFile.filename}`);
            });
          },
          () => {
            this.toastr.error(this.mcBillingPayoutFileFeCtd.apiErrorMessage);
          }
        );
      }
    } else if (action === this.globalActions.Delete) {
      this.openDeletePayoutFileDialog();
    }
  }

  onDeletePayoutFileAction() {
      this.mcBillingPayoutFileFeCtd.delete(
        () => {
          this.goToPayoutFileListPage(`${this.mcBillingPayoutFileFeCtd.fileName} ${this.mcGod.t('cc.common.deleted-successfully')}.`);
        },
        () => {
          this.toastr.error('cc.common.error');
        }
      );
  }

  openDeletePayoutFileDialog(){
    this.dialog.open(ConfirmationDialogComponent, {
      height: '250px',
      width: '630px',
      autoFocus: false,
      restoreFocus: false,
      data: {
        header: 'cc.payout.delete-payout-file',
        description:  `${this.mcGod.t('cc.common.are-you-sure-you-want-to-delete')} ${this.mcBillingPayoutFileFeCtd.fileName} ? ${this.mcGod.t('cc.common.this-action-cannot-be-undone')}`,
        cancelBtnLabel: 'cc.common.edit.cancel',
        confirmBtnLabel: 'cc.common.delete'
      }
    }).afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.onDeletePayoutFileAction();
      }
    });
  }
}
