import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {EbSepaFileTransaction} from '../models/eb-sepa-file-transaction.model';
import {EbSepaFileTransactionList} from '../models/eb-sepa-file-transaction-list.model';
import {EbSepaFileTransactionServiceGENERATED} from '../_generated/eb-sepa-file-transaction-generated.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {McReportingCalculatingModel} from '../models/mc-reporting-calculating-model';
import {SortCriteriaList} from '../_core/sort-criteria-list.model';
import {IEbSepaFileTransaction} from '../_generated/eb-sepa-file-transaction.interface';


@Injectable({
  providedIn: 'root'
})
export class EbSepaFileTransactionService extends EbSepaFileTransactionServiceGENERATED {

  override getByFilter(aFilter: any, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.post<any>(this.getApiServiceRootUrl().replace('v2', 'v3') + `/filter?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, aFilter.toDto(), {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }

  override insert(aObject: any): Observable<any> {
    // @ts-ignore
    return this.httpClient.post<IEbSepaFileTransaction>(this.getApiServiceRootUrl().replace('v2', 'v3') , aObject.toDto());
  }

  // Get report for imported sepa file
  getReportingForImportedSepaFile(sepaFileId: any): Observable<McReportingCalculatingModel> {
    return this.httpClient.get<McReportingCalculatingModel>(`${this.apiUrl}/core/EbSepaFile/${sepaFileId}/report`)
      .pipe(map((response: McReportingCalculatingModel) => {
        return response;
      }));
  }

//
//   //*Get report for imported sepa file*!/
  public downloadTransactionsReportingFile(sepaFileId: number) {
    return this.httpClient.get(`${this.apiUrl}/core/EbSepaFile/${sepaFileId}/report/pdf-preview`, {responseType: 'blob'});
  }

//
//   //*Get CSV sepa file*!/
  public downloadCsvReportingFile(sepaFileId: number) {
    return this.httpClient.get(`${this.apiUrl}/ebfactoring/v2/ebsepafilereport/${sepaFileId}/csv`, {responseType: 'blob'});
  }

  public payTransaction(ebSepaFileTransactionId: number, body: any ): Observable<any> {
    return this.httpClient.post<any>(this.getApiServiceRootUrl() + '/' + ebSepaFileTransactionId + `/pay`, body, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }


  //   //*Translate report for imported sepa file*!/
  public translatePreReport(sepaFileId: any, aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.
    get<EbSepaFileTransactionList>
    (`${this.apiUrl}/ebfactoring/v2/ebsepafiletransaction/translatePreReport/${sepaFileId}`, {
      headers: new HttpHeaders(({
        'Content-Type': 'application/json',
      }))
    });
  }

//

  markAsReturnedFromInkasso(ebSepaFileTransaction: EbSepaFileTransaction):
    Observable<any> {
    // @ts-ignore
    return this.httpClient.put(`${this.apiUrl}/ebfactoring/v2/ebsepafiletransaction/returned-from-inkasso`, ebSepaFileTransaction.toDto());
  }

  updateStatus(ids: number [], status: string): Observable<any> {
    return this.httpClient.put<any>(`${this.apiUrl}/ebfactoring/v3/ebsepafiletransaction/status/${status}`, ids, {
      headers: new HttpHeaders(({
        'Content-Type': 'application/json',
      }))
    });
  }
}
