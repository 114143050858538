import {Component, Input, OnInit} from '@angular/core';
import {
  PmTransactionList,
  PmTransaction,
  McBillingItemList,
  McBillingItemService,
  McBillingItemTable3ConfigWithoutFldActionsModel,
  McBillingItemFilter,
  McBoolean, McNotifyMessageModel,
  McPayoutItemTable3Model,
  PmAdjustmentList,
  PmAdjustmentTable3Config, BaseDirective, McBillingItem, McEntity2, McGod, EbSepaTransactionService,
} from '@miticon-ui/mc-core';
import { ActivatedRoute } from '@angular/router';
import {take, takeUntil} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@miticon-ui/mc-components';


@Component({
  selector: 'pm-transactions-page',
  templateUrl: './pm-transactions-page.component.html',
  styleUrls: ['./pm-transactions-page.component.scss']
})
export class PmTransactionsPageComponent extends BaseDirective implements OnInit {

  isExpanded = false;
  pmTransactionId!: number;
  pmTransaction!: PmTransaction;
  billingItemList = new McBillingItemList();
  billingItemForTableList = new McBillingItemList();
  billingItemTable3Config = new McBillingItemTable3ConfigWithoutFldActionsModel();
  mcBillingItemFilter = new McBillingItemFilter();
  payoutItemList = new McBillingItemList();
  payoutItemTable3Config = new McPayoutItemTable3Model();
  pmAdjustmentList = new PmAdjustmentList();
  pmAdjustmentTable3Config = new PmAdjustmentTable3Config();
  addEditTransactionAdjustmentType!: 'payment' | 'adjustment';
  addEditTransactionAdjustmentId!: number;
  showTransactionAddPaymentMcb = new McBoolean();
  mcGod = McGod.getInstance();

  currency!: string;
  @Input() type!: 'payment' | 'adjustment';
  @Input() pmTransactionAdjustmentId!: number;

  notifyMessage!: McNotifyMessageModel;
  isTransactionHistoryExpanded = false;
  isBillingItemsExpanded = false;
  isPayoutDetailsExpanded = false;
  stornoIds!: any [];
  selectedTransactionList = new PmTransactionList();
  showMarkAsSentToInkassoMcb = new McBoolean();
  status!: string;
  showPauseTransactionMcb = new McBoolean();
  checkedTransactionList = new PmTransactionList();
  mcEntity = new McEntity2();
  transactionIds: any [];
  showStornoMcb = new McBoolean();
  showCollectionTypeChangeMcb = new McBoolean();
  contractNumber: string;

  constructor(
    private ebSepaTransactionService: EbSepaTransactionService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private billingItemService: McBillingItemService,
    private dialog: MatDialog,) {
    super();
  }

  ngOnInit() {

    this.route.paramMap.subscribe(params => {
      // @ts-ignore
      this.pmTransactionId = +params.get('id');
      this.pmTransaction = new PmTransaction();
      this.actLoad();
    });
    this.mcEntity.loadById(McGod.getLoggedEntityIdFromToken());
  }

  loadBillingItems() {
    this.mcBillingItemFilter.idTransaction = this.pmTransactionId;
    this.billingItemService.getAllByTransactionId(this.pmTransactionId, this.billingItemList.pagination.pageNumber, this.billingItemList.pagination.numberOfElements,
      this.billingItemList.sortCriteria)
      .pipe(takeUntil(this.destroyed$))
      .subscribe( data => {
        this.billingItemList.populateFromJsonPageable(data);
        this.billingItemList.items.map(item => {
          item.currencyCode = this.pmTransaction.mcFactoring.mcCurrency.code;
        });
        this.billingItemForTableList.items = this.billingItemList.items.filter(item => item.entryTypeCd === McBillingItem.ENTRY_TYPE_CD_DEBIT);
        this.payoutItemList.items = this.billingItemList.items.filter(item => item.entryTypeCd === McBillingItem.ENTRY_TYPE_CD_CREDIT);
        this.billingItemForTableList.items.reverse();
        this.payoutItemList.items.reverse();
      }, (err) => {});
  }

  loadPmTransactionsAndAdjustments() {
    this.pmTransaction.loadById(this.pmTransactionId, () => {
      this.pmAdjustmentList = this.pmTransaction.pmAdjustments;
      this.pmAdjustmentList.items.map(item => {
        item.currencyCode = this.pmTransaction.mcFactoring.mcCurrency.code;
      })
      this.pmAdjustmentList.items.reverse();
     this.loadBillingItems();
     this.loadContract();
    });
  }

  actLoad(): void {
    this.loadPmTransactionsAndAdjustments();
    // this.loadBillingItems();
  }

  actShowAddTransactionPayment() {
      this.addEditTransactionAdjustmentType = 'payment';
      this.addEditTransactionAdjustmentId = 0;
      this.showTransactionAddPaymentMcb.setTrue();
  }

  getMaxUsedTotalPausedDays() {
    return this.pmTransaction.pausedDaysCount + '/' + this.mcEntity.maxTransactionPauseDays;
  }

  actOnTransactionAddPaymentClose() {
    this.toastr.success(McGod.t('The payment for transaction was recorded successfully') + '.', '', {timeOut: 4000, extendedTimeOut: 4000});
    this.loadTransaction();
    this.actLoad();
    this.showTransactionAddPaymentMcb.setFalse();
  }

  openMarkAsSentToInkassoDialog() {
    this.dialog.open(ConfirmationDialogComponent,{
      height: '230px',
      autoFocus: false,
      restoreFocus: false,
      data: {
        header: 'cc.common.mark-as-sent-to-inkasso',
        description:'cc.inkasso-are-you-sure-you-want-to-mark-transaction',
        cancelBtnLabel: 'cc.common.edit.no',
        confirmBtnLabel: 'cc.common.edit.yes'
      }
    }).afterClosed().subscribe((res: boolean) => {
      if(res){
        this.onMarkAsSentToInkasso();
      }
    });
  }

  onMarkAsSentToInkasso() {
    const transactionIds = [];
    transactionIds.push(this.pmTransaction.id);
      this.ebSepaTransactionService.sentToInkasso(transactionIds).pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.showMarkAsSentToInkassoMcb.setFalse();
        this.loadTransaction();
        this.toastr.success(McGod.t('cc.common.success-inkasso-message') + '.', '', {timeOut: 4000, extendedTimeOut: 4000});
      }, error => {
        this.showMarkAsSentToInkassoMcb.setFalse();
        this.toastr.error(McGod.t('cc.common.error-inkasso-message') +'.', '', {timeOut: 4000, extendedTimeOut: 4000});
      });
  }

  actStorno() {
    this.selectedTransactionList = new PmTransactionList();
    this.showStornoMcb.setTrue();
    this.selectedTransactionList.items.push(this.pmTransaction);
    this.stornoIds = [];
    this.stornoIds.push(this.pmTransactionId);
  }

  actChangeCollectionType(){
    this.showCollectionTypeChangeMcb.setTrue();
  }

  pauseUnpauseTransaction() {
    this.showPauseTransactionMcb.setTrue();
    this.checkedTransactionList = new PmTransactionList();
    this.checkedTransactionList.items.push(this.pmTransaction);
  }

  onUnpauseTransaction() {
    this.showPauseTransactionMcb.setFalse();
    this.loadTransaction();
    this.toastr.success(`${McGod.t('cc.transactions.transaction-s-was-unpaused-successfully')}.`, '', {timeOut: 4000, extendedTimeOut: 4000});
  }

  onPauseTransactionCanceled() {
    this.showPauseTransactionMcb.setFalse();
  }

  onPauseTransaction() {
    this.showPauseTransactionMcb.setFalse();
    this.loadTransaction();
    this.toastr.success(`${McGod.t('cc.transactions.transaction-s-was-paused-successfully')}.`, '', {timeOut: 4000, extendedTimeOut: 4000});
  }

  loadTransaction() {
    this.pmTransaction = new PmTransaction();
    this.pmTransaction.loadById(this.pmTransactionId, () => {
    });
  }

  onSaveStorno() {
    this.showStornoMcb.setFalse();
    this.toastr.success(`${McGod.t('cc.common.the-transaction-was-successfully-canceled')}.`, '', {timeOut: 4000, extendedTimeOut: 4000});
    this.loadTransaction();
    this.actLoad();
  }

  onCancelStorno() {
    this.showStornoMcb.setFalse();
  }

  onUpdateCollectionType(){
    this.showCollectionTypeChangeMcb.setFalse();
    this.loadTransaction();
    this.actLoad();
  }

  onCancelUpdateCollectionType(){
    this.showCollectionTypeChangeMcb.setFalse();
  }

  actReturnFromInkasso() {
    const transactionIds = [];
    transactionIds.push(this.pmTransaction.id);
    this.ebSepaTransactionService.returnFromInkasso(transactionIds).pipe(take(1)).subscribe( () => {
      this.pmTransaction.statusCd = 'RETURNED_FROM_INKASSO';
      this.toastr.success(`${McGod.t('cc.transactions.returned-from-inkasso')}.`, '', {timeOut: 4000, extendedTimeOut: 4000});
    }, error => {

      this.toastr.error(`${error.message}.`, '', {timeOut: 4000, extendedTimeOut: 4000});
    });
  }

  loadContract(){
    this.contractNumber = this.pmTransaction.csrConsumer.activeContracts && this.pmTransaction.csrConsumer.activeContracts.items
      && this.pmTransaction.csrConsumer.activeContracts.items.length > 0 ?
      this.pmTransaction.csrConsumer.activeContracts.items[0].contractNumber : '';
  }
}
