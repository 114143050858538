import {Injectable} from '@angular/core';
import {McUtilityService, McInvoiceService} from '@miticon-ui/mc-core';
import {Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class McTableService {

  filename!: string;
  pdf: any;
  resendUserMailSubject = new Subject();

  /*Constructor*/
  constructor(private utilityService: McUtilityService,
              private invoiceService: McInvoiceService,
              private domSanitizer: DomSanitizer,
              private router: Router) {
  }

  /*Example method*/
  public exampleMethod(content: any) {
    /***********CONTENT is row object from table**************/
    console.info('example method => ', content);
  }

  /*Example for go to paymentInfoTab in consumers - change name and logic if it's need*/
  public goToEntityInvoice(content: { id: any; }) {
    const entityId = this.utilityService.getPropertyFromToken('entity_id');
    if (entityId && content.id) {
      this.router.navigate([`/admin/entity/${entityId}/management/consumers/edit/${content.id}`], {queryParams: {activeTab: 'paymentInfoTab'}});
    }
  }

  public goToEntityProfileInvoice(content: { id: any; }) {
    const entityId = this.utilityService.getPropertyFromToken('entity_id');
    if (entityId && content.id) {
      this.router.navigate([`/admin/entity/${entityId}/management/entity/edit/${content.id}/invoice`]);
    }
  }

  goToInvoiceEdit(content: { statusCode: string; id: any; consumerId: any; entityId: any; }) {
    if (content.statusCode === 'DRAFT' || content.statusCode === 'SCHEDULED') {
      const entityId = this.utilityService.getPropertyFromToken('entity_id');
      if (entityId && content.id) {
        if (content.consumerId) {
          this.router.navigate([`/admin/entity/${entityId}/management/consumers/edit/${content.consumerId}/invoice/edit/${content.id}`]);
        } else if (content.entityId) {
          this.router.navigate([`/admin/entity/${entityId}/management/entity/edit/${content.entityId}/invoice/edit/${content.id}`]);
        }
      }
    }
  }

  goToEntityDetails(content: { id: any; }) {
    const entityId = this.utilityService.getPropertyFromToken('entity_id');
    if (entityId && content.id) {
      this.router.navigate([`/admin/entity/${entityId}/management/entity/edit/${content.id}`]);
    }
  }

  goToProductDetails(content: { id: any; }) {
    const entityId = this.utilityService.getPropertyFromToken('entity_id');
    if (entityId && content.id) {
      this.router.navigate([`/admin/entity/${entityId}/management/product/edit/${content.id}`]);
    }
  }

  goToRoleDetails(content: { id: any; }) {
    const entityId = this.utilityService.getPropertyFromToken('entity_id');
    if (entityId && content.id) {
      this.router.navigate([`/admin/entity/${entityId}/management/role/edit/${content.id}`]);
    }
  }

  goToConsumerDetails(content: { id: any; }) {
    const entityId = this.utilityService.getPropertyFromToken('entity_id');
    if (entityId && content.id) {
      this.router.navigate([`/admin/entity/${entityId}/management/consumers/edit/${content.id}`]);
    }
  }

  public goToJobDetail(content: { id: any; }) {
    const entityId = this.utilityService.getPropertyFromToken('entity_id');

    if (content && content.id && entityId) {
      this.router.navigate([ `/admin/entity/${entityId}/scheduler/jobs/edit/${content.id}`]);
    }
  }

  goToUserEdit(content: { id: any; }) {
    const entityId = this.utilityService.getPropertyFromToken('entity_id');
    if (entityId && content.id) {
      this.router.navigate([`/admin/entity/${entityId}/management/user/edit/${content.id}`]);
    }
  }

  editInvoice(content: { id: any }) {
    const entityId = this.utilityService.getPropertyFromToken('entity_id');

    if (entityId && content.id) {
      this.router.navigate([`/admin/entity/${entityId}/management/invoices/consumers/edit/${content.id}`]);
    }
  }

  resendUserMail(content: any) {
    if (content.id) {
      this.resendUserMailSubject.next(content);
    }
  }

  public round(aNumber: number, aDecimals: string | number | undefined): any {
    // @ts-ignore
    return Number(Math.round(Number(String(aNumber) + 'e' + aDecimals)) + 'e-' + aDecimals).toFixed(aDecimals);
  }


  public toFixedTrunc(x: number, n: number) {
    const v = (typeof x === 'string' ? x : x.toString()).split('.');
    if (n <= 0) { return v[0]; }
    let f = v[1] || '';
    if (f.length > n) { return `${v[0]}.${f.substr(0, n)}`; }
    while (f.length < n) { f += '0'; }
    return `${v[0]}.${f}`;
  }
}
