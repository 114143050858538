import { Component,Inject,OnInit } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICON_INFO } from '../svg_icons/icon-info';
import { ICON_CLOSE } from '../svg_icons/icon-close';

@Component({
  selector: 'confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  iconInfo = ICON_INFO;
  iconClose = ICON_CLOSE;
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    header: string,
    description: string,
    cancelBtnLabel: string,
    confirmBtnLabel: string
  }, public dialogRef: MatDialogRef<ConfirmationDialogComponent>) {}

  ngOnInit() {}
}
