import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationDialogComponent, MkFilterConfig, MkFilterItemType, MkFilterOutput, MkFilterValue, MkMatTableMenuAction, MkTableConfig } from '@miticon-ui/mc-components';
import {
  McBillingStatement,
  McBillingStatementFilter,
  McBillingStatementList,
  McBoolean,
  McEntity2,
  McEntityService,
  McForm,
  McGod,
  SortCriteriaList,
  McBankAccountService
} from '@miticon-ui/mc-core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AppSharedMatBottomSheetComponent } from '../../../app-shared/components/app-shared-mat-bottom-sheet/app-shared-mat-bottom-sheet.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'mc-out-billing-statement-list-page',
  templateUrl: './mc-out-billing-statement-list-page.component.html',
})
export class McOutBillingStatementListPageComponent implements OnInit, OnDestroy {
  isLoading = false;
  mcBillingStatementList = new McBillingStatementList();
  mcBillingStatementFilter = new McBillingStatementFilter();
  selectedMcStatementList = new McBillingStatementList();
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  mcForm = new McForm();
  mcGod = McGod.getInstance();
  showAddEditStatementMcb = new McBoolean();
  successMsg = '';
  errorMsg = '';
  mcStatement = new McBillingStatement();
  infoMsg = '';
  showRenameStatementMcb = new McBoolean();
  showCreatePayoutFileMcb = new McBoolean();
  destroyed$ = new Subject();
  statementToBeCanceledId!: number;
  interval: any;
  mcBillingStatement: any;
  loggedEntity: number;
  selectedMcStatementIds: number[] = [];
  searchTooltip = McGod.t('cc.statement.search-by-entity-title');
  mkMatMenuActionItems = [
    {
      title: McGod.t('cc.out-billing.hide-unhide'),
      matIcon: 'remove_red_eye',
      actionCd: McBillingStatement.ACTION_HIDE,
      permission: McGod.PERM_MC_BILLING_OUT_BILLING_HIDE_UNHIDE_STATEMENT
    },
    {
      title: McGod.t('cc.out-billing.rename-statement'),
      matIcon: 'edit',
      actionCd: McBillingStatement.ACTION_RENAME_STATEMENT,
      permission: McGod.PERM_MC_BILLING_OUT_STATEMENT_EDIT
    },
    {
      title: McGod.t('cc.billing.auto-import-billing-item'),
      matIcon: 'import_export',
      actionCd: McBillingStatement.ACTION_AUTO_IMPORT,
      permission: McGod.PERM_MC_BILLING_OUT_STATEMENT_AUTO_IMPORT_BILLING_ITEMS
    },
    {
      title: McGod.t('cc.billing.finalize-statement'),
      matIcon: 'check_box',
      actionCd: McBillingStatement.ACTION_FINALIZE,
      permission: McGod.PERM_MC_BILLING_OUT_STATEMENT_FINALIZE
    },
    {
      title: McGod.t('cc.payout.create-payout-file'),
      matIcon: 'add_circle_outline',
      actionCd: McBillingStatement.ACTION_CREATE_PAYOUT_FILE,
      permission: McGod.PERM_MC_BILLING_OUT_PAYOUT_FILES_CREATE
    },
    {
      title: McGod.t('cc.billing.cancel-statement'),
      matIcon: 'cancel',
      actionCd: McBillingStatement.ACTION_CANCEL,
      permission: McGod.PERM_MC_BILLING_OUT_STATEMENT_EDIT
    }
  ];

  // ---------------------------------------------------------------------
  constructor(private dialog: MatDialog,
              private toastr: ToastrService,
              private matBottomSheet: MatBottomSheet,
              private entitiesService: McEntityService,
              private bankAccountService: McBankAccountService) {}

  // ---------------------------------------------------------------------
  ngOnInit() {
    this.initTableConfig();
    this.initFilterConfig();
    this.actLoad(0, 100, this.mcBillingStatementFilter);
    this.loggedEntity = McGod.getLoggedEntityIdFromToken();
  }

  initTableConfig() {
    this.tableConfig.addColumnId([`/entity/${McGod.getLoggedEntityIdFromToken()}/out-billing/statements`], 'id');
    this.tableConfig.addColumnStandard(McGod.t('cc.common.view.entity'), 'childEntityName', 250, 'childEntityName');
    this.tableConfig.addColumnStandard(McGod.t('cc.common.edit.title'), 'title', 250, 'title');
    this.tableConfig.addColumnStandard(McGod.t('cc.billing.total-amount'), 'getTotalPayoutLoc()');
    this.tableConfig.addColumnStandard(McGod.t('cc.billing.no-of-items'), 'fldNumberOfItems()');
    this.tableConfig.addColumnStandard(McGod.t('cc.billing.statement-date'), 'getStatementDate()');
    this.tableConfig.addColumnInnerHtml(McGod.t('cc.common.status'), 'fldStatusWithIconsOutPage()', 'statusCd');
  }

  onFilterChanged(filters: MkFilterOutput) {
    this.mcBillingStatementList.setSortB(filters.sort.sortProperty, filters.sort.sortType);
    this.mcBillingStatementFilter.searchTerm = filters.search;
    this.mcBillingStatementFilter.flgOutBilling = true;
    this.mcBillingStatementFilter.statusCds = MkFilterOutput.convertFilterSelectionToArray(filters.selections[McGod.t(McBillingStatement.FILTER_STATUS)]);
    this.mcBillingStatementFilter.idsOfChildrenEntities = MkFilterOutput.convertFilterSelectionToArray(filters.selections[McGod.t(McBillingStatement.FILTER_ENTITES)]);
    this.mcBillingStatementFilter.flgHidden = filters.selections[McGod.t(McBillingStatement.FILTER_VISIBILITY)];
    this.mcBillingStatementFilter.statementDate = null;
    this.mcBillingStatementFilter.flgExported = filters.selections[McGod.t(McBillingStatement.FILTER_EXPORTED)];
    this.actLoad(filters.pageEvent.pageIndex, filters.pageEvent.pageSize, this.mcBillingStatementFilter);
  }

  initFilterConfig() {
    const visibilityValues = [new MkFilterValue('Hidden', true), new MkFilterValue('Unhidden', false)];
    const exportedValues = [new MkFilterValue('Yes', true), new MkFilterValue('No', false)];
    this.entitiesService.getAllWhereParentEntityIsLoggedInEntity(0, 10000, new SortCriteriaList()).subscribe((res) => {
      res.content.sort((a: McEntity2, b: McEntity2) => {
        return a.name.localeCompare(b.name);
      });
      const entityValues = res.content.map((item: McEntity2) => new MkFilterValue(item.name, item.id));
      this.filterConfig.addItem(MkFilterItemType.MULTISELECT, McGod.t(McBillingStatement.FILTER_ENTITES), entityValues);
    });
    const statusCds = McBillingStatement.getStatusCdVll().items.map((item) => new MkFilterValue(item.label, item.value));
    this.filterConfig.addItem(MkFilterItemType.MULTISELECT, McGod.t(McBillingStatement.FILTER_STATUS), statusCds);
    this.filterConfig.addItem(MkFilterItemType.SELECT, McGod.t(McBillingStatement.FILTER_VISIBILITY), visibilityValues);
    this.filterConfig.addItem(MkFilterItemType.SELECT, McGod.t(McBillingStatement.FILTER_EXPORTED), exportedValues);
  }

  actLoad(pageNumber: number, pageSize: number, filters: McBillingStatementFilter) {
    this.isLoading = true;
    this.mcBillingStatementList.pageNum = pageNumber;
    this.mcBillingStatementList.pageItemsPerPageCount = pageSize;
    filters.flgOutBilling = true;
    this.mcBillingStatementList.loadByFilter(filters, () => {
      this.selectedMcStatementList = new McBillingStatementList();
      this.mcBillingStatementList.items.map((item) => (item.navigation = `/entity/${McGod.getLoggedEntityIdFromToken()}/out-billing/statement/${item.id}`));
      this.isLoading = false;
    });
  }

  onItemsSelected(items: McBillingStatement[]) {
    if (items.length === 0) {
      this.matBottomSheet.dismiss();
    }
    this.selectedMcStatementList.items = items;
    const bulkContainerLength = document.getElementsByClassName('bulk-container').length;
    if (items.length > 0 && !bulkContainerLength) {
      const bulkDialog = this.matBottomSheet.open(AppSharedMatBottomSheetComponent, {
        closeOnNavigation: true,
        hasBackdrop: false,
        data: [
          { icon: 'remove_red_eye', title: McGod.t('cc.out-billing.hide-unhide'), action: McBillingStatement.ACTION_HIDE },
          { icon: 'check_box', title: McGod.t('cc.billing.finalize-statement'), action: McBillingStatement.ACTION_FINALIZE },
          { icon: 'add_circle_outline', title: McGod.t('cc.payout.create-payout-file'), action: McBillingStatement.ACTION_CREATE_PAYOUT_FILE },
          { icon: 'cancel', title: McGod.t('cc.billing.cancel-statement'), action: McBillingStatement.ACTION_CANCEL },
        ],
      });

      bulkDialog.afterDismissed().subscribe((bulkAction) => {
        switch (bulkAction) {
          case McBillingStatement.ACTION_HIDE:
            const everyItemIsHidden = this.selectedMcStatementList.items.every((item) => !item.flgHidden);
            const everyItemIsNotHidden = this.selectedMcStatementList.items.every((item) => item.flgHidden);
            if (everyItemIsHidden) {
              this.mcBillingStatementList.callChangeStatusHidden(this.selectedMcStatementList.getIds(),() => {
                  this.toastr.success(`${this.selectedMcStatementList.items.length} ${McGod.t('cc.statement.statements-successfully-hidden')}`);
                  this.actLoad(0, 100, this.mcBillingStatementFilter);
                },
                () => {
                  this.toastr.error(this.mcBillingStatementList.apiErrorMessage);
                }
              );
            } else if (everyItemIsNotHidden) {
              this.mcBillingStatementList.callChangeStatusHidden(this.selectedMcStatementList.getIds(),() => {
                  this.toastr.success(`${this.selectedMcStatementList.items.length} ${McGod.t('cc.statement.statements-successfully-unhidden')}`);
                  this.actLoad(0, 100, this.mcBillingStatementFilter);
                },
                () => {
                  this.toastr.error(this.mcBillingStatementList.apiErrorMessage);
                }
              );
            } else {
              this.toastr.error(`${McGod.t('cc.statement.selected-statements-does-not-have-same-hidden-status')}`);
            }
            break;
          case McBillingStatement.ACTION_CREATE_PAYOUT_FILE:
            this.bankAccountService.checkIfAllEntitiesHaveBankAccount(Array.from(new Set(this.selectedMcStatementList.items.map(item => item.childEntityId)))).subscribe(
            response => {
              if (this.selectedMcStatementList.items.some((item) => item.statusCd !== McBillingStatement.STATUS_CD_FINALIZED)) {
                this.toastr.error(`${McGod.t('cc.statement.only-unexported-statements-with-the-status-finalized-can-be-included-in-the-payout-file')}`);
              } else if (!response) {
                this.toastr.error(`${McGod.t('cc.billing.statement.some-entity-doesnt-have-bank-account')}`);
              } else {
                this.showCreatePayoutFileMcb.setTrue();
                this.selectedMcStatementIds = this.selectedMcStatementList.items.map((item) => item.id);
              }
            });
            break;
          case McBillingStatement.ACTION_FINALIZE:
            this.selectedMcStatementIds = [];
            const areAllStatementsTotalPayoutsPositive = this.selectedMcStatementList.items.some((item) => item.totalPayout <= 0);
            const areAllStatementsActive = this.selectedMcStatementList.items.some((item) => item.statusCd !== McBillingStatement.STATUS_CD_ACTIVE);
            if (!areAllStatementsActive && !areAllStatementsTotalPayoutsPositive) {
              this.selectedMcStatementIds = this.selectedMcStatementList.items.map((item) => item.id);
              this.openFinalizeStatementDialog();
            } else {
              this.toastr.error(`${McGod.t('cc.billing.it-is-not-allowed-to-finalize-statements-that-are-negative-or-have-value-of-0')}.`, '', { timeOut: 4000, extendedTimeOut: 10 });
            }
            break;
          case McBillingStatement.ACTION_CANCEL:
            this.selectedMcStatementIds = [];
            this.selectedMcStatementIds = this.selectedMcStatementList.items.map((item) => item.id);
            this.openCancelStatementDialog();
            break;
        }
      });
    }
  }

  onMkMatMenuItemsAction(event: MkMatTableMenuAction) {
    this.mcBillingStatement = event.item;
    switch (event.action.actionCd) {
      case McBillingStatement.ACTION_AUTO_IMPORT:
        if (this.areActionsEnabled(event.item) && !event.item.isStatusCdCOMPLETED()) {
          this.mcStatement.callCollectBillingItems(
            this.mcStatement.id,
            () => {
              this.toastr.success(McGod.t('cc.payout.auto-import-successfully-finished'));
              this.actLoad(0, 100, new McBillingStatementFilter());
            },
            () => {
              this.toastr.error(this.mcStatement.apiErrorMessage);
            }
          );
        } else {
          this.toastr.error(McGod.t('cc.out-billing.auto-import-error-msg'));
        }
        break;
      case McBillingStatement.ACTION_FINALIZE:
        if (this.mcBillingStatement.totalPayout > 0) {
          this.selectedMcStatementIds = [];
          this.mcStatement = this.mcBillingStatement;
          this.selectedMcStatementIds.push(this.mcStatement.id);
          this.openFinalizeStatementDialog();
        } else {
          this.toastr.error(`${McGod.t('cc.billing.it-is-not-allowed-to-finalize-statements-that-are-negative-or-have-value-of-0')}.`, '', { timeOut: 4000, extendedTimeOut: 10 });
        }
        break;
      case McBillingStatement.ACTION_CANCEL:
        this.selectedMcStatementIds = [];
        this.statementToBeCanceledId = this.mcBillingStatement.id;
        this.selectedMcStatementIds.push(this.statementToBeCanceledId);
        this.openCancelStatementDialog();
        break;
      case McBillingStatement.ACTION_RECALCULATE:
        this.mcStatement.recalculate(
          this.mcStatement.id,
          () => {
            this.successMsg = `${McGod.t('cc.statement.recalculate-successfully-finished')}.`;
            this.actLoad(0, 100, this.mcBillingStatementFilter);
          },
          () => {
            this.errorMsg = this.mcStatement.apiErrorMessage;
          }
        );
        break;
      case McBillingStatement.ACTION_HIDE:
        if (this.areActionsEnabled(event.item)) {
          if (event.item.flgHidden) {
            this.mcStatement.flgHidden = false;
            this.mcStatement.callChangeStatusHidden(this.mcBillingStatement.id,() => {
                this.toastr.success(`${this.mcBillingStatement.title} ${McGod.t('cc.statement.statements-successfully-unhidden')}`);
                this.actLoad(0, 100, new McBillingStatementFilter());
              },
              () => {
                this.toastr.error(this.mcStatement.apiErrorMessage);
              }
            );
          } else {
            this.mcStatement.flgHidden = true;
            this.mcStatement.callChangeStatusHidden(this.mcBillingStatement.id,() => {
                this.toastr.success(`${this.mcBillingStatement.title} ${McGod.t('cc.statement.statements-successfully-hidden')}`);
                this.actLoad(0, 100, new McBillingStatementFilter());
              },
              () => {
                this.toastr.error(this.mcStatement.apiErrorMessage);
              }
            );
          }
        } else {
          this.toastr.error(McGod.t('cc.out-billing.hide-unhide-error-msg'));
        }
        break;
      case McBillingStatement.ACTION_RENAME_STATEMENT:
        this.selectedMcStatementIds = [];
        this.mcStatement = this.mcBillingStatement;
        if (this.areActionsEnabled(event.item) && !event.item.isStatusCdCOMPLETED()) {
          this.showRenameStatementMcb.setTrue();
        } else {
          this.toastr.error(McGod.t('cc.out-billing.rename-error-msg'));
        }
        break;
      case McBillingStatement.ACTION_CREATE_PAYOUT_FILE:
        this.selectedMcStatementIds = [];
        const entityIds: number[] = Array.of(event.item.childEntityId);
        this.bankAccountService.checkIfAllEntitiesHaveBankAccount(entityIds).subscribe(
          response => {
            if (this.areActionsEnabled(event.item) && event.item.statusCd === McBillingStatement.STATUS_CD_FINALIZED && response) {
              this.selectedMcStatementIds.push(this.mcBillingStatement.id);
              this.showCreatePayoutFileMcb.setTrue();
            } else if(!response) {
              this.toastr.error(`${McGod.t('cc.billing.statement.some-entity-doesnt-have-bank-account')}`);
            } else {
              this.toastr.error(McGod.t('cc.statement.only-unexported-statements-with-the-status-finalized-can-be-included-in-the-payout-file'));
            }
          });
        break;
    }
  }

  actAddEditStatementSaved(mcBillingStatement: McBillingStatement) {
    this.showAddEditStatementMcb.setFalse();
    this.successMsg = `${McGod.t('cc.statement.statement')} "${mcBillingStatement.title}" ${McGod.t('cc.statement.created-successfully')}.`;
    this.actLoad(0, 100, new McBillingStatementFilter());
  }

  openFinalizeStatementDialog(){
    this.dialog.open(ConfirmationDialogComponent,{
      height: '240px',
      autoFocus: false,
      restoreFocus: false,
      data: {
        header: 'cc.billing.finalize-statement',
        description: 'cc.billing.are-you-sure-you-want-to-finalize-the-selected-statement-(s)-this-action-cannot-be-undone',
        cancelBtnLabel: 'cc.common.edit.cancel',
        confirmBtnLabel: 'cc.common.finalize'
      }
    }).afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.onFinalizeStatementAction();
      }
    })
  }

  onFinalizeStatementAction() {
      this.mcStatement.apiService
        .finalizeStatement(this.selectedMcStatementIds)
        .pipe(
          take(1),
          tap({
            next: () => {
              this.actLoad(0, 100, this.mcBillingStatementFilter);
              this.toastr.success('cc.finalized-successfully');
            },
            error: () => {
              this.toastr.error(McGod.t('cc.billing.it-is-not-allowed-to-finalize-statements-that-are-negative-or-have-value-of-0'));
            }
          })
        ).subscribe();
  }

  openCancelStatementDialog(){
    this.dialog.open(ConfirmationDialogComponent,{
      height: '250px',
      width: '650px',
      autoFocus: false,
      restoreFocus: false,
      data: {
        header: 'cc.billing.cancel-statement',
        description: 'cc.cancel-statement.are-you-sure-you-want-to-cancel-this-statement-this-action-can-not-be-undone-note-billing-items-will-be-removed-from-the-statement',
        cancelBtnLabel: 'cc.common.edit.no',
        confirmBtnLabel: 'cc.common.edit.yes'
      }
    }).afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.onCancelStatementAction();
      }
    });
  }

  onCancelStatementAction() {
      this.mcStatement.apiService
        .cancelStatement(this.selectedMcStatementIds)
        .pipe(take(1))
        .subscribe(
          (res) => {
            this.toastr.success(`${McGod.t('cc.billing.statements-canceled')}`, '', { timeOut: 4000, extendedTimeOut: 4000 });
            this.actLoad(0, 100, this.mcBillingStatementFilter);
          },
          (error) => {
            this.toastr.error(error.error.message, '', {timeOut: 4000, extendedTimeOut: 4000});
          });
  }

  onRenameStatementCancelEmit() {
    this.showRenameStatementMcb.setFalse();
  }

  onRenameStatementSaveEmit() {
    this.showRenameStatementMcb.setFalse();
    this.toastr.success(`${McGod.t('cc.statement.statement-renamed-successfully')}`, '', { timeOut: 4000, extendedTimeOut: 4000 });
    this.actLoad(0, 100, this.mcBillingStatementFilter);
  }

  onCreatePayoutFileCancelEmit() {
    this.showCreatePayoutFileMcb.setFalse();
    this.onItemsSelected(this.selectedMcStatementList.items);
  }

  ngOnDestroy() {
    this.matBottomSheet.dismiss();
  }

  onCreatePayoutFile() {
    this.toastr.toastrConfig.enableHtml = true;
    this.toastr.success(
      `${McGod.t('cc.payout.payout-files-are-scheduled-for-creation')} <a class='mc-link' href="/#/entity/${this.loggedEntity}/out-billing/payout-files">${McGod.t('cc.payout.here')}.</a>`,
      '',
      { timeOut: 6000, extendedTimeOut: 6000 }
    );
    this.showCreatePayoutFileMcb.setFalse();
    this.actLoad(0, 100, new McBillingStatementFilter());
  }

  areActionsEnabled(item: McBillingStatement) {
    return !item.isStatusCdCANCELED() && !item.isStatusCdIN_PROGRESS_AUTO_IMPORT() && !item.isStatusCdPENDING_AUTO_IMPORT() &&
      !item.isStatusCdIN_PROGRESS_FINALIZATION() && !item.isStatusCdPENDING_FINALIZATION();
  }
}
