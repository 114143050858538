/*BbmTestObj*/
import {CsrContractPreDefinedGENERATED} from '../_generated/csr-contract-pre-defined-generated.model';
import {ICON_STATUS_ACTIVE} from "../svg/icon-status-active";
import {ICON_STATUS_INACTIVE} from "../svg/icon-status-inactive";
import _moment from "moment/moment";
import {McGod} from "../extra/mc-god.service";

export class CsrContractPreDefined extends CsrContractPreDefinedGENERATED {
  populatedProducts: [] = [];
  availableOptions: [] = [];

  public static ACTION_VIEW = "View";
  public static ACTION_EDIT = "Edit";

  public static durationTypeCds = [
    { label: '1m', value: '1' },
    { label: '3m', value: '3' },
    { label: '6m', value: '6' },
    { label: '12m', value: '12' },
    { label: '24m', value: '24' },
    { label: '36m', value: '36' }
  ];

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new CsrContractPreDefined();
  }

  // ---------------------------------------------------------------------

  getName() {
    return this.name;
  }

  getServices() {
    return this.populatedProducts.length;
  }

  getDateFrom() {
    return _moment(this.startDate).format("DD.MM.YYYY");
  }

  getDateTo() {
    return this.endDate ? _moment(this.endDate).format("DD.MM.YYYY") : '-';
  }

  getStatus() {
    if (this.statusCd === CsrContractPreDefined.STATUS_CD_ACTIVE) {
      return `<div class='icon-text-table-cell'>${ICON_STATUS_ACTIVE} <span>Active</span></div>`
    }
    if (this.statusCd === CsrContractPreDefined.STATUS_CD_INACTIVE) {
      return `<div class='icon-text-table-cell'>${ICON_STATUS_INACTIVE} <span>Inactive</div>`
    }
    return '';
  }

  getPrice() {
    return this.price ? this.price.toFixed(2) + "€" : '-';
  }

  getOneTimePrice() {
    return this.priceOneTime ? this.priceOneTime.toFixed(2) + "€" : '-';
  }

  getTypeLabel() {
    return McGod.t(CsrContractPreDefined.getTypeCdLabel(this.typeCd));
  }

  public override populateFromJson(aSourceJson: any) {
    super.populateFromJson(aSourceJson);
    this.populatedProducts = aSourceJson.products;
    this.availableOptions = aSourceJson.reccuringOptions;
  }

  public static getLabel(selectedType: string) {
    let label = 'cc.billing.price';
    let number = 0;
    let division = false;
    switch (selectedType) {
      case CsrContractPreDefined.TYPE_CD_DAILY:
        label = 'mem.common.price-per-day';
        number = 30;
        division = false;
        break;
      case CsrContractPreDefined.TYPE_CD_WEEKLY:
        label = 'mem.common.price-per-week';
        number = 4.333333333333;
        division = false;
        break;
      case CsrContractPreDefined.TYPE_CD_MONTHLY:
        label = 'mem.common.price-per-month';
        number = 1;
        division = false;
        break;
      case CsrContractPreDefined.TYPE_CD_QUARTERLY:
        label = 'mem.common.price-per-quarter';
        number = 3;
        division = true;
        break;
      case CsrContractPreDefined.TYPE_CD_HALF_YEARLY:
        label = 'mem.common.price-per-half-year';
        number = 6;
        division = true;
        break;
      case CsrContractPreDefined.TYPE_CD_YEARLY:
        label = 'mem.common.price-per-year';
        number = 12;
        division = true;
        break;
    }
    return { label: label, number: number, division: division }
  }

  public static getVat(template: CsrContractPreDefined): string {
    let vatRate = 0;
    if(template.totalAmount && template.totalNetto){
      vatRate = ((template.totalAmount - template.totalNetto) / template.totalNetto) * 100;
    }
    return vatRate.toFixed(2).replace('.', ',');
  }

  public static calculateFullPrice(typeCd: string, priceUnit: number, durationInMonths: number, isOneTime: boolean): number {
    if(durationInMonths == null || isOneTime){
      return priceUnit;
    }

    let properties = CsrContractPreDefined.getLabel(typeCd)

    if(properties.division){
      return priceUnit * (durationInMonths / properties.number);
    }
    else{
      return priceUnit * durationInMonths * properties.number;
    }
  }
}
