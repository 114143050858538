<h3 class="page-title">{{ "cc.common.products" | translate }}</h3>

<div class="actions-container">
  <span>{{ "cc.products.products-list" | translate }}</span>
  <div class="actions-wrapper">
    <button (click)="openCreateCategoryDialog()" class="system-action-btn mr-2">
      <i class="fas fa-plus-circle mc-add-new-user-icon"></i>
      {{ "cc.product.edit.create-new-category" | translate }}
    </button>
    <button (click)="openCreateProductDialog()" class="system-action-btn mr-2">
      <i class="fas fa-plus-circle mc-add-new-user-icon"></i>
      {{ "cc.product.view.create-new-product" | translate }}
    </button>
  </div>
</div>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="items"
  [config]="tableConfig"
  [itemsPerPage]="pageSize"
  [totalItemsCount]="totalItemsCount"
  [filterConfig]="filterConfig"
  (filterChanged)="onFilterChanged($event)"
  [mkMatMenuActionItems]="tableActionItems"
  (actionMkMatMenuItems)="onTableAction($event)"
  [searchTooltip]="searchTooltip"
></mk-mat-table>

