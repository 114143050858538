<ng-container class="dialog">
    <div class="dialog_header">
      <span class="dialog_header_title text-uppercase">
        {{'cc.product.edit.create-new-category' | translate}}
      </span>
      <span class="dialog_header_icon"
            [innerHTML]="iconClose | safeHtml"
            (click)="dialogRef.close(false)">
      </span>
    </div>

  <mat-dialog-content>
    <form [formGroup]="form">
      <div class="form_fields">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'cc.common.name' | translate }}</mat-label>
          <input type="text"
                 formControlName="name"
                 matInput/>
        </mat-form-field>

      </div>
      <div  class="form_description">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'cc.common.view.description' | translate }}</mat-label>
          <input type="text"
                 formControlName="description"
                 matInput/>
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>

    <div class="dialog_footer">
      <button mat-button
              class="secondary-btn"
              (click)="dialogRef.close()">
        {{'cc.common.edit.cancel' | translate}}
      </button>
      <button mat-button class="primary-btn" type="submit" (click)="onSave()" [disabled]="form.invalid">
        {{'cc.common.view.create' | translate}}</button>
    </div>
</ng-container>

