import {PageEvent} from '@angular/material/paginator';
import { SortCriteria } from '@miticon-ui/mc-core';

export class MkFilterValue {
  selected = false;
  constructor(public label: string,
              public value: any) {
  }

  addValueLabelItem(label: any, value: any) {
    return new MkFilterValue(label, value);
  }
}

export class MkFilterItem {
  constructor(public type: MkFilterItemType,
              public name: string,
              public values?: MkFilterValue[],
              filterCd?: string) {

  }
}

export enum MkFilterItemType {
  SELECT , MULTISELECT , DATEPICKER , RANGE_DATEPICKER
}

export class MkFilterConfig {
  items: MkFilterItem[] = [];

  addItem(type: MkFilterItemType, name: string, values?: MkFilterValue[], filterCd?: string) {
    this.items.push(new MkFilterItem(type, name, values, filterCd));
  }

  private removeItem(value: string) {
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].name === value) {
        this.items.splice(i, 1);
        return;
      }
    }
  }
}

export class MkFilterOutput {
  selections: any = {};
  search: any;
  constructor(public pageEvent: PageEvent,
                public sort: SortCriteria) {
  }

  public static convertFilterSelectionToArray(selections: any[]): any[] {
    if (selections === undefined) {
      return selections;
    }
    let result: any[] = [];
    selections.forEach((item: any)  => {
      result = [...result, item.value];
    });
    return result;
  }
}
