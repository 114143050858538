import { Component, OnInit } from '@angular/core';
import {BaseDirective, McForm, McManagementService} from '@miticon-ui/mc-core';
import {takeUntil} from "rxjs/operators";
import { ICON_CLOSE } from '../../../../assets/media/svg_icons/icon-close';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mc-product-add-category',
  templateUrl: './mc-product-add-category.component.html',
  styleUrls: ['./mc-product-add-category.component.scss']
})
export class McProductAddCategoryComponent extends BaseDirective implements OnInit{
  mcForm = new McForm();
  form!: FormGroup;
  errorMsg!: string;
  iconClose = ICON_CLOSE;

  constructor(public dialogRef: MatDialogRef<McProductAddCategoryComponent>,
              private mcManagamentService: McManagementService,
              private fb: FormBuilder,
              private toastr: ToastrService,
              private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    this.createForm();
  }

  createForm(){
    this.form = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  onSave() {
    if (this.form.valid) {
      const category = {
        name: this.form.controls['name'].value,
        description: this.form.controls['description'].value
      };
      this.mcManagamentService.createCategory(category)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.dialogRef.close();
          this.toastr.success(this.translate.instant('mem.services.category-has-been-successfully-added'));
        }, (error) => {
          this.toastr.error(error.error.message);
        });
    }
  }

}
