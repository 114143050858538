import {Inject, Injectable} from '@angular/core';
import {McBillingService2VatServiceGENERATED} from '../_generated/mc-billing-service-2-vat-generated.service';



@Injectable({
  providedIn: 'root'
})
export class McBillingService2VatService extends McBillingService2VatServiceGENERATED {
  constructor() {
    super();
  }

  public deleteByVatId(id: number) {
    // @ts-ignore
    return this.httpClient.delete<any>(this.getApiServiceRootUrl() + `/vat/${id}`);
  }

}
